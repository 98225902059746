import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import BASE_URL from "../config";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ManifestDetails from './manifest/Manifest_details';
import ContainerDetails from './manifest/Container_details';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReusableButtonComponent from '../CommonComponents/ReusableButtonComponent';

const Manifest = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const userid = Cookies.get("user_id");
    const [user_id, setUserId] = useState(userid || "");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [manifest_container_data, setManifest_Container_Data] = useState(undefined);
    const [feildValidate, setFeildValidate] = useState(true);
    const [newManifestMode, setNewManifestMode] = useState(true);
    const [manifestData, setManifestData] = useState({});
    const PageinitialValues = {
        billing_number_id: "",
        port_of_loading_id: "",
        port_of_delivery_id: "",
        manifest_date: "",
        place_of_delivery: "",
        place_of_receipt: "",
        serial_number: "",
        shipper_1: "",
        shipper_2: "",
        shipper_3: "",
        shipper_4: "",
        consignee_1: "",
        consignee_2: "",
        consignee_3: "",
        consignee_4: "",
        notify_party_1: "",
        notify_party_2: "",
        notify_party_3: "",
        notify_party_4: "",
        marks_numbers_1: "",
        marks_numbers_2: "",
        marks_numbers_3: "",
        marks_numbers_4: "",
        gross_weight: "",
        measurement: "",
        good_description_1: "",
        good_description_2: "",
        good_description_3: "",
        good_description_4: "",
        remark: "",
        notify_party_11: "",
        notify_party_12: "",
        notify_party_13: "",
        notify_party_14: "",
        total_number_of_packages: "",
        kind_of_packages: "",
        _20ft: "",
        _40ft: "",
        over_40ft: "",
        service_origin: "",
        service_destination: "",
        xml_notation_for_packages: "",
        measurements: "",
        place_of_loading_code: "",
        place_of_destination_code: "",
        career_identifier: "",
        slpa_reference_num_for_vessel: "",
        user_id: user_id,
    }

    const manifestWiseContainerNumbersDataHadle = (data) => {
        setpageValuesData({ ...pageValuesData, manifest_wise_container_numbers_data: data.nodes });
    }

    const newItemMode = () => {
        setNewManifestMode(true);
        setpageValuesData(PageinitialValues);
    }

    const [pageValuesData, setpageValuesData] = useState(PageinitialValues);

    const pageValueHadle = (e) => {
        const { name, value } = e.target;
        setpageValuesData({ ...pageValuesData, [name]: value });
    };

    const dropdownValueHandle = (key_Name, value) => {
        setpageValuesData((prevState) => ({
            ...prevState,
            [key_Name]: value,
        }));
    };

    const selectBLDataSet = (selectedData) => {
        setpageValuesData({ ...PageinitialValues, ...selectedData });
    }

    const selectedDableData = (tableData) => {
        if (tableData !== undefined) {
            setManifest_Container_Data(tableData);
        } else {
            setManifest_Container_Data(undefined);
        }
    }

    const setServiceOrgin = (key_Name, value) => {
        setpageValuesData({ ...pageValuesData, [key_Name]: value })
    }

    const setServiceDestination = (key_Name, value) => {
        setpageValuesData({ ...pageValuesData, [key_Name]: value })
    }

    const validateField = (fieldValue, fieldName) => {
        if (typeof fieldValue === 'string' && !fieldValue.trim()) {
            setFeildValidate(false);
            toast.error(`${fieldName} can not be empty`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        } else {
            setFeildValidate(true);
        }
        return true;
    };

    const handleContainerCUS = async () => {
        if (pageValuesData.billing_number_id !== "") {
            const isEmpty = (value) => value === "" || value === null || value === undefined;
            let emptySheperLineCount = 0;
            let ConsizeLineCount = 0;
            for (const key in pageValuesData) {
                if (pageValuesData.hasOwnProperty(key)) {
                    const value = pageValuesData[key];
                    if (isEmpty(value)) {
                        console.log(key);
                        if (key.startsWith("shipper_")) {
                            emptySheperLineCount++;
                        }
                        if (key === "consignee_1") {
                            validateField(value, "Consignee");
                            console.log("this is " + key);
                        }
                        if (key === "notify_party_1") {
                            validateField(value, "Notify Party");
                        }
                        if (key === "marks_numbers_1") {
                            validateField(value, "Mark & No");
                        }
                        if (key === "serial_number") {
                            validateField(value, "Serial No");
                        }
                        if (key === "measurement") {
                            validateField(value, "Measurement(M3)");
                        }
                        if (key === "total_number_of_packages") {
                            validateField(value, "Total No Of Pkgs");
                        }
                        if (key === "total_number_of_m3") {
                            validateField(value, "Total No Of M3");
                        }
                        if (key === "_20ft") {
                            ConsizeLineCount++;
                        }
                        if (key === "_40ft") {
                            ConsizeLineCount++;
                        }
                        if (key === "over_40ft") {
                            ConsizeLineCount++;
                        }
                    } else {
                        setFeildValidate(true);
                    }
                }
            }
            if (emptySheperLineCount === 4) {
                validateField(PageinitialValues.shipper_1, "Shipper");
            }

            if (ConsizeLineCount === 3) {
                validateField(PageinitialValues._20ft, "No Of Container");
            }

            // Use manifest_wise_container_numbers_data if available, otherwise use manifest_container_data
            const containerDataToUse = pageValuesData.manifest_wise_container_numbers_data || manifest_container_data;

            if (containerDataToUse !== undefined) {
                const updatedData = {
                    manifest_wise_container_numbers_data: { ...containerDataToUse },
                    ...pageValuesData
                };
                setpageValuesData({ ...pageValuesData, ...updatedData });
            }

            if (feildValidate === true) {
                postDataToSave();
            }
        } else {
            validateField(pageValuesData.billing_number_id, "BL Number");
        }
    };

    const postDataToSave = () => {
        if (feildValidate === true) {
            const successCallback = () => {
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            };

            if (pageValuesData.is_maifest_record) {
                axios.post(
                    `${BASE_URL}manifest/update/${pageValuesData.billing_number_id}`, pageValuesData,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${authtoken}`,
                        },
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success("Successfully Updated Data ! ", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } successCallback();

                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error);
                        console.log(pageValuesData);
                    })
            } else {
                axios.post(
                    `${BASE_URL}manifest/store`, pageValuesData,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${authtoken}`,
                        },
                    }
                )
                    .then((response) => {
                        if (response.status === 200) {
                            toast.success("Successfully Save Data ! ", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        } successCallback();

                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error);
                        console.log(pageValuesData);
                    })
            }
        }
    }

    const printManifest = (e) => {
        if (pageValuesData.is_maifest_record) {
            if (pageValuesData.is_maifest_record === 1) {
                if (pageValuesData.billing_number_id !== "") {
                    const queryParams = {
                        master_billing_number_id: pageValuesData.billing_number_id,
                    };

                    const queryString = Object.keys(queryParams)
                        .map((key) => `${key}=${queryParams[key]}`)
                        .join("&");

                    const url = `${BASE_URL}manifest/print?${queryString}`;

                    axios
                        .get(url, {
                            method: "GET",
                            headers: {
                                Authorization: `Bearer ${authtoken}`,
                            },
                            responseType: "blob", // Set the response type to 'blob' to handle binary data
                        })
                        .then((res) => {
                            // Create a blob URL from the response data
                            const blob = new Blob([res.data], { type: "application/pdf" });
                            const pdfUrl = window.URL.createObjectURL(blob);

                            // Open the PDF URL in a new tab
                            window.open(pdfUrl);

                            // Release the object URL when done to free up resources
                            window.URL.revokeObjectURL(pdfUrl);

                            // toast.suc[cess("Downloading file", {
                            //   position: toast.POSITION.TOP_RIGHT,
                            // });
                        })
                        .catch((error) => {
                            console.error("Error fetching PDF:", error);
                        });
                } else {
                    toast.error("Please select an Invoice", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } else if (pageValuesData.is_maifest_record === 0) {
                alert("menifest new mode " + newManifestMode);
            } else {

            }
        } else {
            toast.error("Manifest Record Not Loading", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    useEffect(() => {
        try {
            const parsedAuthUser = JSON.parse(authUser);
            // console.log(parsedAuthUser);
            setUserId(parsedAuthUser.id);
            setServiceOrgin('user_id', parsedAuthUser.id)

        } catch (error) {
            console.log('Error passing authoUser', error)
        }
        console.log(user_id);
    }, [])
    const datashow = () => {
        console.log(user_id);
        console.log(pageValuesData);
    }

    return (
        <Container fluid>

            <ToastContainer autoClose={2000} />
            <Tabs
                defaultActiveKey="home"
                id="justify-tab-example"
                className="mb-1 mt-3 pt-2"
                justify
            >
                <Tab eventKey="home" title="Manifest Details">
                    <ManifestDetails
                        pageValueHadle={pageValueHadle}
                        dropdownValueHandle={dropdownValueHandle}
                        pageValuesData={pageValuesData}
                        selectBLDataSet={selectBLDataSet}
                        setServiceOrgin={setServiceOrgin}
                        setServiceDestination={setServiceDestination}
                        selectedDableData={selectedDableData}
                        setNewManifestMode={setNewManifestMode}
                        manifest_date={pageValuesData.manifest_date}
                    />
                </Tab>
                <Tab eventKey="profile" title="Container Details">
                    <ContainerDetails
                        pageValueHadle={pageValueHadle}
                        pageValuesData={pageValuesData}
                        manifest_date={pageValuesData.manifest_date}
                        manifest_container_data={manifest_container_data}
                        manifestWiseContainerNumbersDataHadle={manifestWiseContainerNumbersDataHadle}
                    />
                </Tab>

            </Tabs>
            <Row>

                <Col className='text-end mt-2 mb-2'>
                    <ReusableButtonComponent
                        action="new"
                        label="New"
                        onClick={newItemMode}
                        selectedAuthority={props.selectedAuthority}
                        className="ms-3"
                        variant="primary"
                    />

                    <ReusableButtonComponent
                        action="update"
                        label="Update"
                        onClick={handleContainerCUS}
                        disabled={props.selectedAuthority && props.selectedAuthority.newManifestMode}
                        selectedAuthority={props.selectedAuthority}
                        className="ms-3"
                        variant="primary"
                    />

                    <ReusableButtonComponent
                        action="save"
                        label="Save"
                        onClick={handleContainerCUS}
                        disabled={props.selectedAuthority && props.selectedAuthority.newManifestMode}
                        selectedAuthority={props.selectedAuthority}
                        className="ms-3"
                        variant="primary"
                    />

                    <ReusableButtonComponent
                        action="delete"
                        label="Delete"
                        disabled={props.selectedAuthority && props.selectedAuthority.newManifestMode}
                        selectedAuthority={props.selectedAuthority}
                        className="ms-3"
                        variant="primary"
                    />

                    <ReusableButtonComponent
                        action="print"
                        label="Print"
                        onClick={printManifest}
                        disabled={props.selectedAuthority && props.selectedAuthority.newManifestMode}
                        selectedAuthority={props.selectedAuthority}
                        className="ms-3"
                        variant="primary"
                    />


                    <Button className="ms-3" variant="primary" onClick={datashow}>
                        Cancel
                    </Button>
                </Col>

            </Row>
        </Container>

    );
}

export default Manifest;