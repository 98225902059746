import React, { useEffect, useState } from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { debounce as lodashDebounce } from 'lodash';

const EditableTable = (props) => {
  const [data, setData] = useState({ nodes: props.data || [] });

  useEffect(() => {
    setData({ nodes: props.data || [] });
  }, [props.data]);

  const theme = useTheme(getTheme());

  const handleUpdate = (value, id, property) => {
    setData((state) => ({
      ...state,
      nodes: state.nodes.map((node) => {
        if (node.id === id) {
          return { ...node, [property]: value };
        }
        return node;
      }),
    }));

    debouncedPassData();
  };

  const passData = () => {
    props.manifestWiseContainerNumbersDataHadle(data)
  }
  const debouncedPassData = lodashDebounce(passData, 1000);

  const addNewRow = () => {
    console.log(data);
    setData((state) => ({
      nodes: [
        ...state.nodes,
        {
          id: state.nodes.length + 1, // Example: Ensure id is unique
          container_number: "",
          number_of_packages: "",
          kind: "",
          weight: "",
          measurements: "",
          container_type: "",
          container_size_code: "",
          container_size_type_code: "",
          place_of_origin: "",
          original_port_of_loading: "",
          full_empty: "",
        },
      ],
    }));
  };

  const COLUMNS = [
    {
      label: "Container No",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Container Numbers</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "160px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.container_number}
          onChange={(event) => handleUpdate(event.target.value, item.id, "container_number")}
        />
      ),
    },
    {
      label: "No of PKGs",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>No PKG</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.number_of_packages}
          onChange={(event) => handleUpdate(event.target.value, item.id, "number_of_packages")}
        />
      ),
    },
    {
      label: "Kind",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Kind</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.kind}
          onChange={(event) => handleUpdate(event.target.value, item.id, "kind")}
        />
      ),
    },
    {
      label: "Weight",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Weight</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.weight}
          onChange={(event) => handleUpdate(event.target.value, item.id, "weight")}
        />
      ),
    },
    {
      label: "M3",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>M3</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.measurements}
          onChange={(event) => handleUpdate(event.target.value, item.id, "measurements")}
        />
      ),
    },
    {
      label: "ContainerType",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Container Type</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.container_type}
          onChange={(event) => handleUpdate(event.target.value, item.id, "container_type")}
        />
      ),
    },
    {
      label: "ContSizeCode",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>ContSizeCode</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.container_size_code}
          onChange={(event) => handleUpdate(event.target.value, item.id, "container_size_code")}
        />
      ),
    },
    {
      label: "ContSizeTypeCode",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>ContSizeTypeCode</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.container_size_type_code}
          onChange={(event) => handleUpdate(event.target.value, item.id, "container_size_type_code")}
        />
      ),
    },
    {
      label: "Place of Origin",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Please of Origin</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.place_of_origin}
          onChange={(event) => handleUpdate(event.target.value, item.id, "place_of_origin")}
        />
      ),
    },
    {
      label: "Origin Port of Loading",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Origin Port of Loading</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.original_port_of_loading}
          onChange={(event) => handleUpdate(event.target.value, item.id, "original_port_of_loading")}
        />
      ),
    },
    {
      label: "Full/Empty",
      renderHeader: () => <span style={{ fontWeight: "bold" }}>Full/Empty</span>,
      renderCell: (item) => (
        <input
          type="text"
          style={{ width: "100px", border: "solid 2px #219EBC !important", fontSize: "1rem", padding: 0, margin: 0 }}
          value={item.full_empty}
          onChange={(event) => handleUpdate(event.target.value, item.id, "full_empty")}
        />
      ),
    },
  ];

  return (
    <div style={{ width: "150%" }} className="edditable-table-colum-input">
      <CompactTable columns={COLUMNS} data={data} theme={theme} />
      <button className="ms-3 btn btn-primary" onClick={addNewRow}>Add New Row</button>
    </div>
  );
};

export default EditableTable;
