import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Select from 'react-select';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SelectDropdown from "../SelectDropdown";
import { ToastContainer, toast } from "react-toastify";
import TableView from "../TableView";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const UserAuthority = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState("user_authority");
    const [usersFormDataList, setusersFormDataList] = useState(undefined);
    const [formDataList, setFormDataList] = useState(undefined);
    const [userAccessformData, setUserAccessformData] = useState(undefined);
    const [pageValuesData, setpageValuesData] = useState({ user_id: "", form_id: "", select: 0, edit: 0, new: 0, delete: 0, print: 0, approve: 0, });
    const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["user_id", "form_id"]);
    const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
    const [feildValidate, setFeildValidate] = useState(true);
    const [isChecked, setIsChecked] = useState(false);


    useEffect(() => {
        fetch(`${BASE_URL}forms/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setFormDataList(data.forms);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authtoken]);



    useEffect(() => {
        fetch(`${BASE_URL}users/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setusersFormDataList(data.users)
            })
            .catch((error) => {
                console.error(error);
            });
    }, [authtoken]);

    const usersOptions = usersFormDataList ?
        usersFormDataList.map((userOptionItem) => ({
            value: userOptionItem.id,
            label: userOptionItem.user_code,
        })) : [];

    const formOptions = formDataList ?
        formDataList.map((formOptionItem) => ({
            value: formOptionItem.id,
            label: formOptionItem.form_name,
        })) : [];



    const checkdata = () => {
        console.log(pageValuesData);
        console.log(formOptions);
    }

    const tTitle = [
        "Form Name",
        "select",
        "edit",
        "new",
        "delete",
        "print"
    ];
    const columns = [
        "form_name",
        "select",
        "edit",
        "new",
        "delete",
        "print"
    ];

    function setSelectedRow(rowid) {
        const foundItem = userAccessformData.find(Item => Item.id === rowid);
        console.log(foundItem);
        if (foundItem) {
            setpageValuesData({ ...pageValuesData, ...foundItem });
        }
    }

    const handleCheckView = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, select: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, select: 0 });
        }

    };

    const handleCheckNew = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, new: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, new: 0 });
        }

    };

    const handleCheckEdit = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, edit: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, edit: 0 });
        }

    };

    const handleCheckDelete = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, delete: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, delete: 0 });
        }

    };

    const handleCheckPrint = (event) => {
        if (event.target.checked) {
            setIsChecked(true);
            setpageValuesData({ ...pageValuesData, print: 1 });
        } else {
            setIsChecked(false);
            setpageValuesData({ ...pageValuesData, print: 0 });
        }

    };

    const dropdownValueHandle = (value, key_Name) => {
        console.log(value);
        setpageValuesData((prevState) => ({
            ...prevState,
            [key_Name.name]: value.value,
        }));

        if (key_Name.name === "user_id") {

            fetch(`${BASE_URL}user_authority/get_Specific_user_authority_data/${value.value}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    const updatedList = data.user_authority.map((item) => {
                        const { user_authority_id: value, ...reset } = item;
                        return { ...reset, id: value };
                    });
                    console.log(updatedList);

                    setUserAccessformData(updatedList);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

    };

    const validateEmptyField = (fieldValue, fieldName) => {
        if (typeof fieldValue === 'string' && !fieldValue.trim()) {
            setFeildValidate(false);
            toast.error(`${fieldName} can not be empty`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        } else {
            setFeildValidate(true);
        }
        return true;
    };

    const handleSave = async (event) => {
        event.preventDefault();
        const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
        const newvalidateFailedInputsList = [];
        for (const key in pageValuesData) {
            const value = pageValuesData[key];
            if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
                newvalidateFailedInputsList.push(key);
                const keyWord = key.split('_');
                const capitalizeKeyWords = keyWord.map(word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                });
                const labelText = capitalizeKeyWords.join(' ');
                validateEmptyField(value, labelText);
            }
        }

        setValidateFailedInputsList(newvalidateFailedInputsList);
        if (newvalidateFailedInputsList.length === 0) {
            setFeildValidate(true);
            datapassStage();
        } else {
            console.log("Canot be Empty felids" + newvalidateFailedInputsList.length);
        }
    }

    const datapassStage = () => {
        const queryParams = {
            form_id: pageValuesData.form_id,
            select: pageValuesData.select,
            new: pageValuesData.new,
            edit: pageValuesData.edit,
            delete: pageValuesData.delete,
            print: pageValuesData.print,
            user_id: props.selectedAuthority.user_id,
            user_ids: [pageValuesData.user_id],
            id: pageValuesData.user_id,
        };
        const selectedformPriv = {
            "updated_user_authority_data": 
               [ {form_id: pageValuesData.form_id,
                select: pageValuesData.select,
                new: pageValuesData.new,
                edit: pageValuesData.edit,
                delete: pageValuesData.delete,
                print: pageValuesData.print,
                approve: pageValuesData.select,
                user_id: props.selectedAuthority.user_id,
                id: pageValuesData.user_id,}]
            
        };

        if (feildValidate === true) {
            
            if (userAccessformData ? userAccessformData.find(item => item.form_id === pageValuesData.form_id) : '') {


                axios
                    .post(
                        `${BASE_URL}${pageComponent}/update`, selectedformPriv,
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${authtoken}`,
                            },
                        })
                    .then((response) => {
                        toast.success("Successfully Updated Data ! ", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            window.location.reload(); // Reload the page after deletion
                        }, 2000);
                    })
                    .catch((error) => {
                        toast.error("Something Went Wrong", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            } else {


                axios
                    .post(`${BASE_URL}${pageComponent}/store`, queryParams, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${authtoken}`,
                        },
                    })
                    .then((response) => {
                        toast.success("Successfully Save Data ! ", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        setTimeout(() => {
                            window.location.reload(); // Reload the page after deletion
                        }, 2000);
                    })
                    .catch((error) => {
                        console.error(error.response.data.message);
                        toast.error("Something Went Wrong new privilages", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    });
            }


        }
    }
    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className="mt-3 bottom-border pb-3">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>User Name </Form.Label>
                                    <SelectDropdown
                                        options={usersOptions}
                                        name="user_id"
                                        onChange={dropdownValueHandle}
                                        value={pageValuesData !== undefined ? usersOptions.find(option => option.label === pageValuesData.user_id) : null}
                                        className={validateFailedInputsList.includes("user_id") ? "border-red2" : ""}

                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Operational Module</Form.Label>
                                    <SelectDropdown
                                        options={formOptions}
                                        name="form_id"
                                        onChange={dropdownValueHandle}
                                        value={pageValuesData !== undefined ? formOptions.find(option => option.value === pageValuesData.form_id) : null}
                                        className={validateFailedInputsList.includes('form_id') ? "border-red2" : ""}

                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="View"
                                        checked={pageValuesData.select === 1 ? true : false}
                                        onChange={handleCheckView}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="New"
                                        checked={pageValuesData.new === 1 ? true : false}
                                        onChange={handleCheckNew}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Edit"
                                        checked={pageValuesData.edit === 1 ? true : false}
                                        onChange={handleCheckEdit}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Delete"
                                        checked={pageValuesData.delete === 1 ? true : false}
                                        onChange={handleCheckDelete}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 " controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Print"
                                        checked={pageValuesData.print === 1 ? true : false}
                                        onChange={handleCheckPrint}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col className="text-end">
                                {/* <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="checkData"
                                    label="Check Data"
                                    onClick={checkdata}
                                    className="ms-3"
                                    variant="primary"
                                /> */}

                                <ReusableButtonComponent
                                    selectedAuthority={props.selectedAuthority}
                                    action="save"
                                    label="Save"
                                    onClick={handleSave}
                                    className="ms-3"
                                    variant="primary"
                                />


                                <Button
                                    className="ms-3"
                                    variant="primary"
                                >
                                    Remove
                                </Button>

                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-4">
                {userAccessformData !== undefined ?
                    <Col>
                        <TableView
                            data={userAccessformData}
                            columns={columns}
                            tTitle={tTitle}
                            setSelectedRow={setSelectedRow}
                        />
                    </Col>
                    : ""}
            </Row>
        </Container>
    );

}
export default UserAuthority;