import logo from "./logo.svg";
import bootstrap from "bootstrap";
import { BrowserRouter, Route, Router, Routes, Switch } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./Dashboard/Dashboard";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/footer";

// =========== File =========== //
import Vessel from "./components/File/Vessel";
import Voyage from "./components/File/Voyage";
import SalesPerson from "./components/File/SalesPerson";
import Country from "./components/File/Country";
import Port from "./components/File/Ports";
import Currency from "./components/File/Currency";
import Incoterms from "./components/File/Incoterms";
import Containers from "./components/File/Container";
import Tax from "./components/File/Tax";
import Rates from "./components/File/Rates";
import Customer from "./components/File/Customer";
import Supplier from "./components/File/Supplier";
import TransactionsClass from "./components/File/TransactionClass";
import TransactionsType from "./components/File/TransactionType";
import PaymentMode from "./components/File/PaymentMode";

import Pagination from "./components/File/PaginationTest";

// =========== Transactions =========== //

import BookingCosting from "./components/Transactions/BookingCosting";
import BookingMSCDetails from "./components/Transactions/BookingMSCDetails";
import ExportBL from "./components/Transactions/ExportBL";
import AirwayBill from "./components/Transactions/AirwayBill";
import Invoice from "./components/Transactions/Invoice";
import Manifest from "./components/Transactions/Manifest";
import SupplierInvoice from "./components/Transactions/SupplierInvoive";

// =========== Approvals =========== //

import BookingApproval from "./components/Approvals/BookingApproval";
import InvoiceApproval from "./components/Approvals/InvoiceApproval";

// =========== Reports =========== //
import BookingDetails from "./components/Reports/BookingReports/BookingDetails";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Navigationbar /> */}
        <Routes>
          <Route exact path="/" Component={Login} />
          <Route exact path="/dashboard" Component={Dashboard} />
          <Route exact path="/about" Component={About} />
          <Route exact path="/contact" Component={Contact} />
          {/* <Route exact path="/vessel" Component={Vessel} /> */}
          <Route exact path="/voyage" Component={Voyage} />
          <Route exact path="/salesperson" Component={SalesPerson} />
          <Route exact path="/country" Component={Country} />
          <Route exact path="/port" Component={Port} />
          <Route exact path="/currency" Component={Currency} />
          <Route exact path="/incoterms" Component={Incoterms} />
          <Route exact path="/container" Component={Containers} />
          <Route exact path="/tax" Component={Tax} />
          <Route exact path="/rates" Component={Rates} />
          <Route exact path="/customer" Component={Customer} />
          <Route exact path="/supplier" Component={Supplier} />
          <Route exact path="/pagination" Component={Pagination} />
          <Route
            exact
            path="/transactionsclass"
            Component={TransactionsClass}
          />
          <Route exact path="/transactionstype" Component={TransactionsType} />
          <Route exact path="/paymentmode" Component={PaymentMode} />

          <Route exact path="/bookingcosting" Component={BookingCosting} />
          <Route
            exact
            path="/Bookingmscdetails"
            Component={BookingMSCDetails}
          />
          <Route exact path="/ExportBL" Component={ExportBL} />
          <Route exact path="/airexport" Component={AirwayBill} />
          <Route exact path="/forminvoice" Component={Invoice} />
          <Route exact path="/supinvoice" Component={SupplierInvoice} />
          <Route exact path="/manifest" Component={Manifest} />

          <Route exact path="/bookingapproval" Component={BookingApproval} />
          <Route exact path="/invoiceapproval" Component={InvoiceApproval} />

          <Route
            exact
            path="/bookingreportdetails"
            Component={BookingDetails}
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
