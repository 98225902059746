import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../TableView";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Tax = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("taxes");
  const [data, setData] = useState(undefined);
  const [tax, setTax] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [Active, setActive] = useState("");
  const [btnActive, setBtnActive] = useState(true);
  const [selectRowData, setSelectRowData] = useState("");
  const [emptyTaxCode, setEmptyTaxCode] = useState(false);
  const [emptyTaxvalue, setEmptyTaxvalue] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const createNewRow = () => {
    setBtnActive(false);
    setSelectRowData("");
    setTax("");
    setTaxRate("");
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.taxes);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  function setSelectedRow(rowid) {
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data;
        console.log(selectRowData);
        setSelectRowData(selectRowData);
        setTax(selectRowData.tax.tax ? selectRowData.tax.tax : "");
        setTaxRate(
          selectRowData.tax.tax_rate ? selectRowData.tax.tax_rate : ""
        );
        if (
          selectRowData.tax.active_status
            ? selectRowData.tax.active_status === 1
            : ""
        ) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
        setBtnActive(false);
      });
  }

  const handleSave = (event) => {
    event.preventDefault();

    if (tax.length === 0) {
      toast.error("Tax Code can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyTaxCode(true);
      setEmptyInput(true);
    } else {
      setEmptyTaxCode(false);
    }

    if (taxRate.length === 0) {
      toast.error("Tax Name can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyTaxvalue(true);
      setEmptyInput(true);
    } else {
      setEmptyTaxvalue(false);
    }

    if (emptyInput !== true) {
      const inputData = {
        tax: tax,
        tax_rate: taxRate,
        active_status: isChecked ? 1 : 0,
      };
      axios
        .post(`${BASE_URL}${pageComponent}/store`, inputData, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((response) => {
          toast.success("Successfully Save Data ! ", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(window.location.reload.bind(window.location), 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  const edditRow = (event) => {
    event.preventDefault();
    if (selectRowData !== "") {
      const inputData = {
        tax: tax,
        tax_rate: taxRate,
        active_status: isChecked ? 1 : 0,
      };
      axios
        .post(
          `${BASE_URL}${pageComponent}/update/${selectRowData.tax.id}`,
          inputData,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          window.location.reload(false);
          alert("Successfully Save Data");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please Select data Row", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteRow = (event) => {
    event.preventDefault();
    axios.delete(`${BASE_URL}${pageComponent}/destroy/${selectRowData.tax.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    });
    toast.warning("Successfully Deleted Data Row", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(window.location.reload.bind(window.location), 2000);
  };

  const tTitle = ["Tax Name", "Tax Rate", "Active Status"];
  const columns = ["tax", "tax_rate", "active_status"];

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 30 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Tax_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-4 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Tax Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="NBT"
                    value={tax}
                    className={emptyTaxCode === true ? "border-red2" : ""}
                    onChange={(e) => setTax(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="0.0000"
                    value={taxRate}
                    className={emptyTaxvalue === true ? "border-red2" : ""}
                    onChange={(e) => setTaxRate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Row className="text-center">
              <Col className="text-end">
                {props.selectedAuthority && props.selectedAuthority.new === 1 ? (
                  <>
                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={selectRowData !== "" ? edditRow : handleSave}
                      disabled={btnActive}
                    >
                      Save
                    </Button>


                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={createNewRow}
                    >
                      New
                    </Button>
                  </>
                ) : null}
                {props.selectedAuthority && props.selectedAuthority.edit === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={edditRow}
                    disabled={btnActive}
                  >
                    Update
                  </Button>
                ) : null}
                {props.selectedAuthority && props.selectedAuthority.delete === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={deleteRow}
                    disabled={btnActive}
                  >
                    Delete
                  </Button>
                ) : null}
                {props.selectedAuthority && props.selectedAuthority.print === 1 ? (
                  <Button className="ms-3" variant="primary" onClick={handleButtonClickPDF}>
                    Print
                  </Button>
                ) : null}

                {/* <Button className="ms-3" variant="primary" type="submit">
                  Cancel
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TableView
            data={data}
            columns={columns}
            tTitle={tTitle}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Tax;
