import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "react-toastify/dist/ReactToastify.css";

const ContainerInfo = (props) => {
  const buttonRef = useRef(null);
  const [inputFields, setInputFields] = useState([{ name: "indexis0" }]);
  const [indexCount, setIndexCount] = useState(0);
  const [con_NumList, setCon_NumList] = useState([]);

  const handleFormChange = (index, event, value) => {
    const appendValue = async (value) => {
      setCon_NumList({ ...con_NumList, [index]: value });
      props.handleChangeConNUMList({ ...con_NumList, [index]: value });
    };

    appendValue(value);

    if (indexCount === index && event.target.value !== "") {
      let newfield = { name: `indexis${index}` };
      setInputFields([...inputFields, newfield]);
      setIndexCount(indexCount + 1);
    }
  };


  const handleContainer = (containerType, value) => {
    props.handleConSizeVal((prevState) => {
      const updatedContainerData = [...(props.pageValuesData?.booking_wise_containers_table_data || [])];
      let found = false;

      // Check if container_type_id already exists, then update
      for (let i = 0; i < updatedContainerData.length; i++) {
        if (updatedContainerData[i].container_type_id === containerType) {
          updatedContainerData[i] = { container_type_id: containerType, volume: value };
          found = true;
          break;
        }
      }

      // If container_type_id does not exist, add a new object
      if (!found) {
        updatedContainerData.push({ container_type_id: containerType, volume: value });
      }

      props.containerValueHadle(updatedContainerData);

      return {
        ...prevState,
        containerData: updatedContainerData,
      };
    });
  };


  const renderedContainers = props.pageData?.container_data?.map((containerItem, id) => {
    const conSizeItem = props.pageValuesData?.booking_wise_containers_table_data?.find(
      (item) => item.container_type_id === containerItem.id
    );

    return (
      <Col className="ps-3 pe-3" sm={3} key={id}>
        <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
          <Form.Label column>{containerItem.container_description}</Form.Label>
          <Form.Control
            column
            sm={10}
            type="number"
            placeholder="Enter value of container"
            name={containerItem.container_description}
            value={conSizeItem ? conSizeItem.volume : ""}
            className={
              props.validateFailedInputsList.includes("booking_wise_containers_table_data")
                ? "border-red2"
                : ""
            }
            onChange={(e) => handleContainer(containerItem.id, e.target.value)}
          />
        </Form.Group>
      </Col>
    );
  });

  return (
    <div className="d-flex flex-column form-box">
      <Form.Label>Container Size & Vol  </Form.Label>
      <Row>{renderedContainers}</Row>
      <Row>
        <Col>
          <Form.Label>Container Numbers</Form.Label>
          {
            Array.isArray(props.pageValuesData?.booking_wise_container_numbers_table_data)
              ? props.pageValuesData?.booking_wise_container_numbers_table_data.map((con_num_item, index) => (

                <div key={index}>
                  <Form.Control
                    className={props.validateFailedInputsList.includes("booking_wise_container_numbers_table_data") ? "border-red2" : "mb-1 w-25"}
                    type="text"
                    value={con_num_item.container_number}
                    placeholder="Container Number"
                    onBlur={(event) => handleFormChange(index, event, event.target.value)}
                  />
                </div>
              ))
              : (inputFields || []).map((input, index) => (
                <div key={index}>
                  <Form.Control
                    className={props.validateFailedInputsList.includes("booking_wise_container_numbers_table_data") ? "border-red2 mb-1 w-25" : "mb-1 w-25"}
                    type="text"
                    name={input.name}
                    placeholder="Container Number"
                    onBlur={(event) => handleFormChange(index, event, event.target.value)}
                  />
                </div>
              ))
          }

        </Col>
      </Row>
    </div>
  );
};

export default ContainerInfo;
