import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../TableView";
import { ToastContainer, toast } from "react-toastify";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import GenerateButton from "../GenerateButton";
import { write, utils } from "xlsx";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";

const Vessel = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("vessels");
  const [data, setData] = useState(undefined);
  const [bookingReportData, setBookingReportData] = useState(undefined);
  const [VesselCode, setVesselCode] = useState("");
  const [VesselDesc, setVesselDesc] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [Active, setActive] = useState("");
  const [btnActive, setBtnActive] = useState(true);
  const [selectRowData, setSelectRowData] = useState("");
  const [oldVesselCode, setOldVesselCode] = useState("");
  let [duplicateVal, setDuplicateVal] = useState(false);
  const [vesEmpty, setVesEmpty] = useState(false);
  const [vesDescEmpty, setVesDescEmpty] = useState(false);

  function generateReport(bookingReportData) {
    const worksheet = utils.json_to_sheet(bookingReportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Report");
    const excelData = write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelData], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "report.xlsx");
  }

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 30 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Vessel_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const createNewRow = () => {
    setBtnActive(false);
    setSelectRowData("");
    setVesselCode("");
    setVesselDesc("");
    setDuplicateVal(false);
  };

  function setSelectedRow(rowid) {
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data;
        setSelectRowData(selectRowData);
        setVesselCode(
          selectRowData.vessels.vessel_code
            ? selectRowData.vessels.vessel_code
            : ""
        );
        setOldVesselCode(
          selectRowData.vessels.vessel_code
            ? selectRowData.vessels.vessel_code
            : ""
        );
        setVesselDesc(
          selectRowData.vessels.vessel_description
            ? selectRowData.vessels.vessel_description
            : ""
        );
        if (
          selectRowData.vessels.active_status
            ? selectRowData.vessels.active_status === 1
            : ""
        ) {
          setIsChecked(true);
        } else {
          setIsChecked(false);
        }
        setBtnActive(false);
      });
  }

  const edditRow = (event) => {
    event.preventDefault();
    if (oldVesselCode === VesselCode) {
      if (VesselCode.length === 0) {
        toast.error("Vessel Code can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setVesEmpty(true);
        return;
      } else {
        setVesEmpty(false);
      }

      if (VesselDesc.length === 0) {
        toast.error("Vessel Description can not be empty", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setVesDescEmpty(true);
        return;
      } else {
        setVesDescEmpty(false);
      }

      if (selectRowData !== "") {
        const inputData = {
          vessel_code: VesselCode,
          vessel_description: VesselDesc,
          active_status: isChecked ? 1 : 0,
        };
        console.log(inputData);
        axios
          .post(
            `${BASE_URL}${pageComponent}/update/${selectRowData.vessels.id}`,
            inputData,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          )
          .then((response) => {
            setVesselCode("");
            setVesselDesc("");
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(window.location.reload.bind(window.location), 2000);
          })
          .catch((error) => {
            toast.error("error ekak", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        toast.error("Please Select data Row", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const duplicate = data.some((el) => el.vessel_code === VesselCode);
      if (duplicate === true) {
        toast.error("This Vessel code already included", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (duplicate === false) {
        if (VesselCode.length === 0) {
          toast.error("Vessel Code can not be empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setVesEmpty(true);
          return;
        } else {
          setVesEmpty(false);
        }
        if (VesselDesc.length === 0) {
          toast.error("Vessel Description can not be empty", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setVesDescEmpty(true);
          return;
        } else {
          setVesDescEmpty(false);
        }
        if (selectRowData !== "") {
          const inputData = {
            vessel_code: VesselCode,
            vessel_description: VesselDesc,
            active_status: isChecked ? 1 : 0,
          };
          axios
            .post(
              `${BASE_URL}${pageComponent}/update/${selectRowData.vessels.id}`,
              inputData
            )
            .then((response) => {
              setVesselCode("");
              setVesselDesc("");
              toast.success("Successfully Save Data ! ", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setTimeout(window.location.reload.bind(window.location), 2000);
            })
            .catch((error) => {
              toast.error("error ekak", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        } else {
          alert("Please Select data Row");
        }
      }
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const duplicate = await data.some((el) => el.vessel_code === VesselCode);
    const inputData = {
      vessel_code: VesselCode,
      vessel_description: VesselDesc,
      Active: isChecked ? 1 : 0,
    };
    if (VesselCode.length === 0) {
      toast.error("Vessel Code can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setVesEmpty(true);
    } else {
      setVesEmpty(false);
    }

    if (VesselDesc.length === 0) {
      toast.error("Vessel Description can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setVesDescEmpty(true);
      return;
    } else {
      setVesDescEmpty(false);
    }

    if (duplicate === true) {
      toast.error("This Vessel code already included", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (duplicate === false) {
      axios
        .post(`${BASE_URL}${pageComponent}/store`, inputData, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((response) => {
          setVesselCode("");
          setVesselDesc("");
          toast.success("Successfully Saved Data ! ", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(window.location.reload.bind(window.location), 2000);
        })
        .catch((error) => {
          toast.error("error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });


    }
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.vessels);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  const deleteRow = async (event) => {
    event.preventDefault();
    try {
      await axios.delete(
        `${BASE_URL}${pageComponent}/destroy/${selectRowData.vessels.id}`, // Correct URL format
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      toast.warning("Successfully Deleted Data Row", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setTimeout(() => {
        window.location.reload(); // Reload the page after deletion
      }, 2000);
    } catch (error) {
      console.error("Error deleting row:", error);
      // Handle error and display a message if needed
    }
  };

  const columns = ["vessel_code", "vessel_description", "active_status"];
  const tTitle = ["Vessel Code", "Vessel Description", "Active Status"];
  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-4 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vessel Code</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Ves. Code"
                    readOnly={selectRowData !== "" ? true : false}
                    className={vesEmpty === true ? "border-red2" : ""}
                    name="VesselCode"
                    value={VesselCode}
                    onChange={(e) => setVesselCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Vessel Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ves. Desc"
                    autoComplete="off"
                    className={vesDescEmpty === true ? "border-red2" : ""}
                    name="VesselDesc"
                    value={VesselDesc}
                    onChange={(e) => setVesselDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Row className="text-center">
              <Col className="text-end">

                {props.selectedAuthority && props.selectedAuthority.edit === 1 ? (
                  <>
                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={selectRowData !== "" ? edditRow : handleSave}
                      disabled={btnActive}
                    >
                      Save
                    </Button>

                    <Button
                      className="ms-3"
                      variant="primary"
                      onClick={createNewRow}
                    >
                      New
                    </Button>
                  </>
                ) : null}
                {props.selectedAuthority && props.selectedAuthority.edit === 1 ? (

                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={edditRow}
                    disabled={btnActive}
                  >
                    Update
                  </Button>
                ) : null}

                {props.selectedAuthority && props.selectedAuthority.delete === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={deleteRow}
                    disabled={btnActive}
                  >
                    Delete
                  </Button>
                ) : null}


                {props.selectedAuthority && props.selectedAuthority.print === 1 ? (
                  <Button
                    className="ms-3"
                    variant="primary"
                    onClick={handleButtonClickPDF}
                  >
                    Print
                  </Button>

                ) : null}

                {/* <Button className="ms-3" variant="primary" type="submit">
                  Cancel
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TableView
            data={data}
            columns={columns}
            tTitle={tTitle}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>

        </Col>
      </Row>
      <Row className="mt-4">

      </Row>
    </Container>
  );
};

export default Vessel;