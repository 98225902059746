import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SelectDropdown from "../SelectDropdown";
import invoiceSearchMainDroPData from "../data/invoiceSearch.json";
import BillTable from "../BillTableView";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const InvoiceSearch = () => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState("search/search_invoice_by/");
    const [secondaryDropDownData, setSecondaryDropDownData] = useState(undefined);
    const [searchData, setSearchData] = useState([]);
    const [tableItems, setTableItems] = useState([]);
    const [validateFeildItems, setvalidateFeildItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null); // Track the selected row

    const serviceOptions = invoiceSearchMainDroPData ?
        invoiceSearchMainDroPData.map((searchItem) => ({
            value: searchItem.dataId,
            label: searchItem.labelName,
        })) : [];

    const valueServiceOrigin = async (event) => {
        setTableItems([]);
        setSearchData({ ...searchData, SearchBy: event.value });
        if (event.value) {
            await axios
                .get(`${BASE_URL}${pageComponent}${event.value}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authtoken}`,
                    },
                })
                .then((response) => {
                    setSecondaryDropDownData(response.data.data);
                });
        }
    }

    const secondDropdownDataCall = async (event) => {
        setTableItems([]);
        setSearchData({ ...searchData, id: event.value });
    }

    const secondaryOptions = secondaryDropDownData ?
        secondaryDropDownData.map((secondItem) => ({
            value: secondItem.id,
            label: secondItem.desc,
        })) : [];

    const findData = async () => {
        if (searchData.SearchBy === undefined) {
            toast.error("Please select Search Category", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setvalidateFeildItems(prevItems => [...prevItems, "priveDropdown"]);
        } else if (searchData.id === undefined) {
            setvalidateFeildItems(prevItems => prevItems.filter(item => item !== "priveDropdown"));
            toast.error("Please select Search Items", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setvalidateFeildItems(prevItems => [...prevItems, "secondaryDropdown"]);
        } else {
            setvalidateFeildItems(prevItems => prevItems.filter(item => item !== "priveDropdown"));
            setvalidateFeildItems(prevItems => prevItems.filter(item => item !== "secondaryDropdown"));

            if (Object.keys(searchData).length > 0) {
                try {
                    const response = await axios.get(`${BASE_URL}search/search_invoice_by`, {
                        params: searchData,
                        headers: {
                            Authorization: `Bearer ${authtoken}`,
                        },
                    });

                    setTableItems(response.data.data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        }
    };

    const handleGenerateReport = async (row) => {
        try {
            // Prepare the request URL
            const url = `${BASE_URL}search/invoice/get_report`;

            // Send the entire row data
            const response = await axios.post(url, row, {
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                    'Content-Type': 'application/json' // Ensure the content type is JSON
                },
                responseType: 'blob' // Ensures the response is handled as a binary blob
            });

            // Create a URL for the blob data and trigger download
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            const downloadUrl = window.URL.createObjectURL(blob);

            link.href = downloadUrl;
            link.setAttribute('download', 'Invoice_Report.pdf'); // Name of the downloaded file
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the URL object
            window.URL.revokeObjectURL(downloadUrl);

        } catch (error) {
            console.error("Error generating report:", error);
            toast.error("Error generating report. Please try again.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const headercolumns = [
        "Invoice No",
        "Booking No",
        "BL No",
        "Cost Amount",
        "Quote Amount",
        "Profit",
        "Get Report",
        "View Document",
    ];
    const columns = [
        "invoice_number",
        "booking_number",
        "bill_number",
        "total_cost",
        "total_quote",
        "profit",
        "get_report",
        "view_document",
    ];
    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className="mt-3 bottom-border pb-3">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <SelectDropdown
                                        options={serviceOptions}
                                        onChange={valueServiceOrigin}
                                        clName={validateFeildItems.includes("priveDropdown") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="text-end">
                                <Form.Group className="mb-3 text-start">
                                    <SelectDropdown
                                        options={secondaryOptions}
                                        onChange={secondDropdownDataCall}
                                        clName={validateFeildItems.includes("secondaryDropdown") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                                <Button className="text-right" variant="primary" type="button" onClick={findData}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <BillTable
                        data={tableItems}
                        columns={columns}
                        headercolumns={headercolumns}
                        setSelectedRow={setSelectedRow}
                        buttonLabel="Get Report"
                        buttonAction={handleGenerateReport}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default InvoiceSearch;
