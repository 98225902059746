import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Navigation from "../components/Navigation";
import NavigationToggle from "../components/NavigationToggle ";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Main componets
import DashboardComponents from "./DashboardComponents";
import Topbar from "../components/TopBar/Topbar";
// File componets List
import Vessel from "../components/File/Vessel";
import Voyage from "../components/File/Voyage";
import SalesPerson from "../components/File/SalesPerson";
import Country from "../components/File/Country";
import Ports from "../components/File/Ports";
import Currency from "../components/File/Currency";
import Incoterms from "../components/File/Incoterms";
import Containers from '../components/File/Container';
import Tax from "../components/File/Tax";
import Rates from '../components/File/Rates';
import Customer from '../components/File/Customer';
import Supplier from "../components/File/Supplier";
import TransactionsClass from '../components/File/TransactionClass';
import TransactionsType from '../components/File/TransactionType';
import PaymentMode from '../components/File/PaymentMode';
// Transaction componets List
import BookingCosting from '../components/Transactions/BookingCosting';
import BookingMSCDetails from '../components/Transactions/BookingMSCDetails';
import Invoice from '../components/Transactions/Invoice';
import SupplierInvoice from '../components/Transactions/SupplierInvoive';
import ExportBL from '../components/Transactions/ExportBL';
import AirwayBill from '../components/Transactions/AirwayBill';
import Manifest from "../components/Transactions/Manifest";
// Approval componets List
import BookingApproval from '../components/Approvals/BookingApproval';
import ApprovedBookingsInvoices from "../components/Approvals/ApprovedBookingsInvoices";
import InvoiceApproval from '../components/Approvals/InvoiceApproval';
import CreditNoteApproval from "../components/Approvals/CreditNoteApproval";
// Reports componets List
import BookingReports from "../components/Reports/BookingReports";
import BookingDetails from "../components/Reports/BookingReports/BookingDetails";
// Systems
import UserCreation from "../components/Systems/UserCreation";
import UserAuthority from "../components/Systems/UserAuthority";

// Search
import CreditNote from "../components/search/CreditNote";
import BookingSearch from "../components/search/BookingSearch";
import InvoiceSearch from "../components/search/InvoiceSearch";



const componentMap = {
  vessel: Vessel,
  voyage: Voyage,
  salesperson: SalesPerson,
  country: Country,
  port: Ports,
  currency: Currency,
  incoterms: Incoterms,
  container: Containers,
  tax: Tax,
  rates: Rates,
  customer: Customer,
  supplier: Supplier,
  transactionsclass: TransactionsClass,
  transactionstype: TransactionsType,
  paymentmode: PaymentMode,
  bookingcosting: BookingCosting,
  Bookingmscdetails: BookingMSCDetails,
  forminvoice: Invoice,
  supinvoice: SupplierInvoice,
  ExportBL: ExportBL,
  airexport: AirwayBill,
  manifest: Manifest,
  bookingapproval: BookingApproval,
  invoiceapproval: InvoiceApproval,
  bookingreports: BookingReports,
  bookingreportdetails: BookingDetails,
  usercreation: UserCreation,
  userauthority: UserAuthority,
  creditnoteapproval: CreditNoteApproval,
  approvedbookingsinvoices: ApprovedBookingsInvoices,
  creditnote: CreditNote,
  bookingSearch: BookingSearch,
  invoiceSearch: InvoiceSearch,

};

const pageTitleMap = {
  vessel: "Vessel",
  voyage: "Voyage",
  salesperson: "Sales Person",
  country: "Country",
  port: "Ports",
  currency: "Currency",
  incoterms: "Incoterms",
  container: "Containers",
  tax: "Tax",
  rates: "Rates",
  customer: "Customer",
  supplier: "Supplier",
  transactionsclass: "Transactions Class",
  transactionstype: "Transactions Type",
  paymentmode: "Payment Mode",
  bookingcosting: "Booking Costing",
  Bookingmscdetails: "Booking MCS Details",
  forminvoice: "Invoice",
  supinvoice: "Supplier Invoice",
  ExportBL: "Export BL",
  airexport: "Air Waybill",
  manifest: "Manifest",
  bookingapproval: "Booking Approval",
  invoiceapproval: "Invoice Approval",
  bookingreports: "All Reports",
  bookingreportdetails: "Booking Details Report ",
  usercreation: "User Creation",
  userauthority: "User Authority",
  creditnoteapproval: "Credit Note Approval",
  approvedbookingsinvoices: "Approved Booking Invoices",
  creditnote: "Credit Note",
  bookingSearch: "Booking Search",
  invoiceSearch: "Invoice Search"

};

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState(localStorage.getItem('pageLocation') ? localStorage.getItem('pageLocation') : "dashboard");

  const [showNavigation, setShowNavigation] = useState(true);

  // Retrieve the value from local storage
  const userAuthoritiesFromLocalStorage = localStorage.getItem('userAuthorities');

  // Check if the retrieved value is a string representing an object
  // Parse the string to an object or set it as an empty array if it's null or undefined
  let initialUserAuthorities;
  try {
    initialUserAuthorities = userAuthoritiesFromLocalStorage ? JSON.parse(userAuthoritiesFromLocalStorage) : [];
  } catch (error) {
    // Handle the error (e.g., log it) if parsing fails
    console.error("Parsing error:", error);
    // Set initialUserAuthorities as an empty array or handle the error accordingly
    initialUserAuthorities = [];
  }

  // Use useState to set the initial state
  const [userAuthorities, setUserAuthorities] = useState(initialUserAuthorities);
  const [selectedAuthority, setSelectedAuthority] = useState(undefined);

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedAuthority = localStorage.getItem('selectedAuthority');

    // Check if storedAuthority is the string "undefined"
    const parsedAuthority = storedAuthority !== "undefined" ? JSON.parse(storedAuthority) : undefined;

    // Set the selectedAuthority state
    setSelectedAuthority(parsedAuthority);
  }, []);

  const toggleNavigation = () => {
    setShowNavigation(!showNavigation);
  };

  const ComponentToRender = componentMap[activeComponent] || DashboardComponents;
  const pageTitle = pageTitleMap[activeComponent] || "dashboard";

  useEffect(() => {
    const sessionExpiry = Cookies.get("sessionExpiry");
    const currentTime = new Date().toISOString();
    const isLoggedIn = Cookies.get('isLoggedIn') === 'true';

    const isSessionExpired = sessionExpiry && currentTime > sessionExpiry;

    if (!isLoggedIn || isSessionExpired) {
      toast.error("Session has expired. Please log in again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location.href = "/";
    }
  }, []);

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col className="ps-0 pe-0">
          <Topbar pageTitle={activeComponent} pageTitleMap={pageTitleMap} setActiveComponent={setActiveComponent} />
        </Col>
      </Row>
      <Row className={`main-content pb-3 ${showNavigation ? "sidebar-open" : "sidebar-close"}`}>
        <Col className={`bg-light sidebar  nav-sec ${showNavigation ? "show" : "hide"}`}>
          <Navigation setActiveComponent={setActiveComponent} activeComponent={activeComponent} userAuthorities={userAuthorities} />
        </Col>
        <Col className={`w-auto content ${showNavigation ? "panel-area" : "main-content-right-side"}`}>
          <NavigationToggle showNavigation={showNavigation} toggleNavigation={toggleNavigation} />
          <ComponentToRender setActiveComponent={setActiveComponent} selectedAuthority={selectedAuthority !== undefined ? selectedAuthority : " "} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
