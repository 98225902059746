import TableView from "./TableView";

const About = () => {
  const data = [
    { id: 1, name: 'John', age: 30 },
    { id: 2, name: 'Jane', age: 25 },
    { id: 3, name: 'Bob', age: 40 }
  ];

  const columns = ['id', 'name', 'age'];

  return (
    <div>
      <h1>About page</h1>
      <TableView data={data} columns={columns} />
    </div>
  );
}

export default About;