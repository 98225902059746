// const BASE_URL = "https://demomol.evolve-demo.com/MOL_CLIENT/api/";
// const DOC_Path = "https://demomol.evolve-demo.com/MOL_CLIENT/storage/app/";

const BASE_URL = "https://demomol.evolve-demo.com/MOL_DEVELOPMENT/api/";
const DOC_Path = "https://demomol.evolve-demo.com/MOL_DEVELOPMENT/storage/app/";

// const BASE_URL = "http://127.0.0.1:8000/api/";
// const DOC_Path = "http://127.0.0.1:8000/storage/app/";

// const BASE_URL = 'https://backend.logisyslkcmb.com/api/';
// const DOC_Path = 'https://backend.logisyslkcmb.com/storage/app/';

export default BASE_URL;
//
