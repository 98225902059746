import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../../TableView";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExportBLAttachedDT = (props) => {
  return (
    <Container fluid style={{ boxShadow: 'none' }}>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form >
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Additional Details </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    name="additional_details"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.additional_details
                        ? props.pageValuesData.additional_details
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ExportBLAttachedDT;
