import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp, faAngleDown, faListCheck, faFileContract, faScrewdriverWrench, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import companyLogo from "../images/logo.png";
import formdata from "./data/formdata.json";

const Navigation = (props) => {
  const [allformdata, setAllFormData] = useState([]);

  const hadleNavLinkClick = (componentName, authority) => {
    localStorage.setItem('selectedAuthority', JSON.stringify(authority));
    props.setActiveComponent(componentName);
    localStorage.setItem('pageLocation', componentName);

  };
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAllFormData(formdata);
  }, []);
  const toggleMenu = (menu) => {
    setMenuOpen((prevMenuOpen) => ({
      ...prevMenuOpen,
      [menu]: !prevMenuOpen[menu],
    }));
  };
  const isLinkActive = (componentName) => {
    return props.activeComponent === componentName ? "active-link" : "";
  };
  return (
    <Nav
      defaultActiveKey="/"
      className={`flex-column navigation sidebar-nav ${props.showNavigation ? "slide-left" : "slide-right"
        }`}
    >
      <img className="ms-auto me-auto mb-2" src={companyLogo} alt="LOGO" loading="lazy" style={{ maxWidth: '70%' }} />
      {/* <Nav.Link>Home</Nav.Link> */}
      <Nav.Link onClick={() => toggleMenu("submenuFile")}>
        <FontAwesomeIcon icon={faFile} /><div className="me-auto ps-1 pt-1">File</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuFile"] ? faAngleUp : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuFile"] ? "ActiveSUBItem" : ""
            }`}
        />
      </Nav.Link>

      <ul className={`submenu ${menuOpen["submenuFile"] ? "open" : "close"}`}>
        {allformdata &&
          allformdata.masterFiles &&
          allformdata.masterFiles.map(link => {
            const authority = props.userAuthorities.find(authority => authority.form_id === link.form_id && authority.select === 1);

            return (
              authority && (
                <li key={link.id}>
                  <Nav.Link onClick={() => hadleNavLinkClick(link.id, authority)} className={isLinkActive(link.id)}>
                    {link.text}
                  </Nav.Link>
                </li>
              )
            );
          })}
      </ul>

      <Nav.Link onClick={() => toggleMenu("submenuTransactions")}>
        <FontAwesomeIcon icon={faMoneyBillWave} /><div className="me-auto ps-1 pt-1">Transactions</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuTransactions"] ? faAngleDown : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuTransactions"] ? "ActiveSUBItem" : ""
            }`}
        />
      </Nav.Link>

      <ul className={`submenu ${menuOpen["submenuTransactions"] ? "open" : "close"}`}>
        {allformdata &&
          allformdata.transaction &&
          allformdata.transaction.map(link => {
            const authority = props.userAuthorities.find(authority => authority.form_id === link.form_id && authority.select === 1);
            return (
              authority && (
                <li key={link.id}>
                  <Nav.Link onClick={() => hadleNavLinkClick(link.id, authority)} className={isLinkActive(link.id)}>
                    {link.text}
                  </Nav.Link>
                </li>
              )
            );
          })}
      </ul>

      <Nav.Link onClick={() => toggleMenu("submenuApproval")}>
        <FontAwesomeIcon icon={faListCheck} /><div className="me-auto ps-1 pt-1">Approval</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuApproval"] ? faAngleUp : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuApproval"] ? "ActiveSUBItem" : ""
            }`}
        />

      </Nav.Link>
      <ul className={`submenu ${menuOpen["submenuApproval"] ? "open" : "close"}`}>
        {allformdata &&
          allformdata.approval &&
          allformdata.approval.map(link => {
            const authority = props.userAuthorities.find(authority => authority.form_id === link.form_id && authority.select === 1);

            return (
              authority && (
                <li key={link.id}>
                  <Nav.Link onClick={() => hadleNavLinkClick(link.id, authority)} className={isLinkActive(link.id)}>
                    {link.text}
                  </Nav.Link>
                </li>
              )
            );
          })}
      </ul>

      <Nav.Link onClick={() => toggleMenu("submenuReport")}>
        <FontAwesomeIcon icon={faFileContract} /><div className="me-auto ps-1 pt-1">Report</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuReport"] ? faAngleUp : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuReport"] ? "ActiveSUBItem" : ""
            }`}
        />
      </Nav.Link>

      <ul className={`submenu ${menuOpen["submenuReport"] ? "open" : "close"}`}>
        <li>
          <Nav.Link onClick={() => hadleNavLinkClick("bookingreports")} className={isLinkActive("bookingreports")}>
            All Reports
          </Nav.Link>
        </li>
      </ul>

      <Nav.Link onClick={() => toggleMenu("submenuSystem")}>
        <FontAwesomeIcon icon={faScrewdriverWrench} /><div className="me-auto ps-1 pt-1">System</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuSystem"] ? faAngleUp : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuSystem"] ? "ActiveSUBItem" : ""
            }`}
        />
      </Nav.Link>

      <ul className={`submenu ${menuOpen["submenuSystem"] ? "open" : "close"}`}>
        {allformdata &&
          allformdata.system &&
          allformdata.system.map(link => {
            const authority = props.userAuthorities.find(authority => authority.form_id === link.form_id && authority.select === 1);

            return (
              authority && (
                <li key={link.id}>
                  <Nav.Link onClick={() => hadleNavLinkClick(link.id, authority)} className={isLinkActive(link.id)}>
                    {link.text}
                  </Nav.Link>
                </li>
              )
            );
          })}
      </ul>

      <Nav.Link onClick={() => toggleMenu("submenuSerch")}>
        <FontAwesomeIcon icon={faMagnifyingGlass} /><div className="me-auto ps-1 pt-1">Search</div>
        <FontAwesomeIcon
          icon={menuOpen["submenuSerch"] ? faAngleUp : faAngleDown}
          className={`arrow-icon ${menuOpen["submenuSerch"] ? "ActiveSUBItem" : ""
            }`}
        />
      </Nav.Link>

      <ul className={`submenu ${menuOpen["submenuSerch"] ? "open" : "close"}`}>
        {allformdata &&
          allformdata.search &&
          allformdata.search.map(link => {
            const authority = props.userAuthorities.find(authority => authority.form_id === link.form_id && authority.select === 1);
            return (
              authority && (
                <li key={link.id}>
                  <Nav.Link onClick={() => hadleNavLinkClick(link.id, authority)} className={isLinkActive(link.id)}>
                    {link.text}
                  </Nav.Link>
                </li>
              )
            );
          })}
      </ul>
    </Nav>
  );
};

export default Navigation;
