import React from "react";
import Form from "react-bootstrap/Form";
const MultiInputs = (props) => {

  const { nuOfInputs, inputBaseName, dataObject, clName } = props;

  const inputComponents = Array.from({ length: nuOfInputs }, (_, index) => (
    <Form.Control
      key={index}
      type="text"
      placeholder=""
      className={clName ? clName + " mt-1" : "mt-1"}
      autoComplete="off"
      name={`${inputBaseName}${index + 1}`}
      value={dataObject[inputBaseName + (index + 1)] || ""}
      onChange={props.onChangeFunction}
    />
  ));


  return (
    <>
      {inputComponents}
    </>
  );
}

export default MultiInputs;