import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../TableView";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const Rates = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("rates");
  const [data, setData] = useState(undefined);
  const [rateCode, setRateCode] = useState("");
  const [rateDesc, setRateDesc] = useState("");
  const [inDirect, setInDirect] = useState(false);
  const [isInActive, setIsInActive] = useState(false);
  const [btnActive, setBtnActive] = useState(true);
  const [selectRowData, setSelectRowData] = useState("");
  const [emptyRateCode, setEmptyRateCode] = useState(false);
  const [emptyRateDesc, setEmptyRateDesc] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);

  const handleInActiveCheckboxChange = (event) => {
    setIsInActive(event.target.checked);
  };

  const handleInDirectCheckboxChange = (event) => {
    setInDirect(event.target.checked);
  };

  const handleSave = async (event) => {
    event.preventDefault();

    if (rateCode.length === 0) {
      toast.error("Container Code can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyRateCode(true);
      setEmptyInput(true);
    } else {
      setEmptyRateCode(false);
    }

    if (rateDesc.length === 0) {
      toast.error("Container Name can not be empty", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEmptyRateDesc(true);
      setEmptyInput(true);
    } else {
      setEmptyRateDesc(false);
    }

    if (emptyInput !== true) {
      const inputData = {
        rate_code: rateCode,
        rate_description: rateDesc,
        active_status: isInActive ? 1 : 0,
        direct_charge_to_customer_or_not: inDirect ? 1 : 0,
      };
      const duplicate = await data.some((el) => el.RateCode === rateCode);
      if (duplicate !== true) {
        axios
          .post(`${BASE_URL}${pageComponent}/store`, inputData, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
          .then((response) => {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(window.location.reload.bind(window.location), 2000);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        toast.error("This currency code already included", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
    }
  };

  const edditRow = (event) => {
    event.preventDefault();
    if (selectRowData !== "") {
      const inputData = {
        rate_code: rateCode,
        rate_description: rateDesc,
        active_status: isInActive ? 1 : 0,
        direct_charge_to_customer_or_not: inDirect ? 1 : 0,
      };
      axios
        .post(
          `${BASE_URL}${pageComponent}/update/${selectRowData.rate.id}`,
          inputData,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          toast.success("Successfully Save Data ! ", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(window.location.reload.bind(window.location), 2000);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      toast.error("Please Select data Row", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const createNewRow = () => {
    setBtnActive(false);
    setSelectRowData("");
    setRateCode("");
    setRateDesc("");
    setIsInActive(false);
    setInDirect(false);
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.rates);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  function setSelectedRow(rowid) {
    console.log(rowid);
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data;
        console.log(selectRowData.rate);
        setSelectRowData(selectRowData);
        setRateCode(
          selectRowData.rate.rate_code ? selectRowData.rate.rate_code : ""
        );
        setRateDesc(
          selectRowData.rate.rate_description
            ? selectRowData.rate.rate_description
            : ""
        );

        if (selectRowData.rate.active_status === 0) {
          setIsInActive(false);
        } else {
          setIsInActive(true);
        }

        if (selectRowData.rate.direct_charge_to_customer_or_not === 0) {
          setInDirect(false);
        } else {
          setInDirect(true);
        }

        setBtnActive(false);
      });
  }

  const deleteRow = (event) => {
    event.preventDefault();
    axios.delete(
      `${BASE_URL}${pageComponent}/destroy/${selectRowData.rate.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
    toast.warning("Successfully Deleted Data Row", {
      position: toast.POSITION.TOP_RIGHT,
    });
    setTimeout(window.location.reload.bind(window.location), 2000);
  };

  const tTitle = [
    "Rate Code",
    "Rate Description",
    "Rate Group Code",
    "Direct Charges to Customer or Not",
  ];
  const columns = [
    "rate_code",
    "rate_description",
    "rate_group_code",
    "direct_charge_to_customer_or_not",
  ];

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 30 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Rates_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-4 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="rate_code"
                    value={rateCode}
                    readOnly={selectRowData !== "" ? true : false}
                    className={emptyRateCode === true ? "border-red2" : ""}
                    onChange={(e) => setRateCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="rate_desc"
                    value={rateDesc}
                    className={emptyRateDesc === true ? "border-red2" : ""}
                    onChange={(e) => setRateDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3 " controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Indirect"
                checked={inDirect}
                onChange={handleInDirectCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                label="Inactive"
                checked={isInActive}
                onChange={handleInActiveCheckboxChange}
              />
            </Form.Group>
            <Row className="text-center">
              <Col className="text-end">
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="edit" // Assuming 'edit' is the action for editing
                  label="Save"
                  onClick={selectRowData !== "" ? edditRow : handleSave}
                  disabled={btnActive}
                />
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="new"
                  label="New"
                  onClick={createNewRow}

                />
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="edit"
                  label="Update"
                  onClick={edditRow}
                  disabled={btnActive}
                />
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="delete"
                  label="Delete"
                  onClick={deleteRow}
                  disabled={btnActive}
                />
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="print"
                  label="Print"
                  onClick={handleButtonClickPDF}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TableView
            data={data}
            columns={columns}
            tTitle={tTitle}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Rates;
