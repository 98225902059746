import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TableView from '../../TableView';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiInputs from '../../CommonComponents/MultiInputs';


const ExportBLSecondDT = (props) => {
    const dimensionsValueSet = (index, e) => {
        let curruntDimension = { "dimension": e.target.value }
        const preData = props.pageValuesData.air_export_wise_dimensions_data;
        preData[index] = curruntDimension;
        props.dimensionsobjectDataHadle(preData);

    }
    const addNewTextBox = () => {
        const newDate = {
            dimension: "",
        };
        props.dimensionsobjectDataHadle([...props.pageValuesData.air_export_wise_dimensions_data, newDate]);
    };

    return (
        <Container fluid style={{ boxShadow: 'none', padding: '0' }}>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>No of Pieces RCP</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder=""
                                        name="number_of_rcp_pieces"
                                        value={props.pageValuesData.number_of_rcp_pieces ? props.pageValuesData.number_of_rcp_pieces : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Gross Weight</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="gross_weight"
                                        value={props.pageValuesData.gross_weight ? props.pageValuesData.gross_weight : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>kg\lb</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="KGBL"
                                        value={props.pageValuesData.KGBL ? props.pageValuesData.KGBL : ""}
                                        onChange={props.pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Commodity Item No</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="common_item_number"
                                        value={props.pageValuesData.common_item_number ? props.pageValuesData.common_item_number : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Chargeable Weight</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder=""
                                        name="chargable_weight"
                                        value={props.pageValuesData.chargable_weight ? props.pageValuesData.chargable_weight : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Rate/Charges</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="rate_charge"
                                        value={props.pageValuesData.rate_charge ? props.pageValuesData.rate_charge : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Total</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="total"
                                        value={props.pageValuesData.total ? props.pageValuesData.total : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Nature and Quantity of Goods</Form.Label>
                                    <MultiInputs nuOfInputs={4} dataObject={props.pageValuesData} inputBaseName="nature_quantity_of_goods_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1" style={{ maxHeight: '160px', overflowY: 'scroll' }}>
                                    <Form.Label>Marks & Numbers</Form.Label>
                                    <MultiInputs nuOfInputs={10} dataObject={props.pageValuesData} inputBaseName="marks_numbers_" onChangeFunction={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>INCL. Dimensions or Volume</Form.Label>
                                    {props.pageValuesData.air_export_wise_dimensions_data ? props.pageValuesData.air_export_wise_dimensions_data.map((value, index) => (
                                        <Row key={index}>
                                            <Col>
                                                <Form.Group className="mb-1">
                                                    <Form.Control type="text" name="dimension" value={value.dimension} placeholder="" autoComplete="off" onChange={(e) => dimensionsValueSet(index, e)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    )) : ""}

                                </Form.Group>
                                <Button onClick={addNewTextBox}>Add New Row</Button>
                            </Col>

                        </Row>
                        <Row>
                            <Col></Col>
                            <Col> <Form.Label>Prepaid</Form.Label></Col>
                            <Col> <Form.Label>Collect</Form.Label></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Weight Charges</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="weight_charge_prepaid"
                                value={props.pageValuesData.weight_charge_prepaid ? props.pageValuesData.weight_charge_prepaid : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="weight_charge_collect"
                                value={props.pageValuesData.weight_charge_collect ? props.pageValuesData.weight_charge_collect : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Valuation Charges</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="valuation_charge_prepaid"
                                value={props.pageValuesData.valuation_charge_prepaid ? props.pageValuesData.valuation_charge_prepaid : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="valuation_charge_collect"
                                value={props.pageValuesData.valuation_charge_collect ? props.pageValuesData.valuation_charge_collect : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Total other Charges Due Carrier</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_other_charge_due_carrier_pre_paid"
                                value={props.pageValuesData.total_other_charge_due_carrier_pre_paid ? props.pageValuesData.total_other_charge_due_carrier_pre_paid : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_other_charge_due_carrier_collect"
                                value={props.pageValuesData.total_other_charge_due_carrier_collect ? props.pageValuesData.total_other_charge_due_carrier_collect : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Total other Charge Due Agent</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_other_charge_due_agent_pre_paid"
                                value={props.pageValuesData.total_other_charge_due_agent_pre_paid ? props.pageValuesData.total_other_charge_due_agent_pre_paid : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_other_charge_due_agent_collect"
                                value={props.pageValuesData.total_other_charge_due_agent_collect ? props.pageValuesData.total_other_charge_due_agent_collect : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Total Paid</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_paid"
                                value={props.pageValuesData.total_paid ? props.pageValuesData.total_paid : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="total_collect"
                                value={props.pageValuesData.total_collect ? props.pageValuesData.total_collect : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col><Form.Label>Currency Conversion Rate</Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder=""
                                name="currency_conversion_rate"
                                value={props.pageValuesData.currency_conversion_rate ? props.pageValuesData.currency_conversion_rate : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col> <Form.Label><Form.Control type="text" placeholder="" name="currency_conversion_charge"
                                value={props.pageValuesData.currency_conversion_charge ? props.pageValuesData.currency_conversion_charge : ""}
                                onChange={props.pageValueHadle} /></Form.Label></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1" controlId="MasterBL">
                                    <Form.Label>Total Collect</Form.Label>
                                    <Form.Control type="text"
                                        name="total_collect"
                                        value={props.pageValuesData.total_collect ? props.pageValuesData.total_collect : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>CC Charges in Destination</Form.Label>
                                    <Form.Control type="text"
                                        name="currency_conversion_charge"
                                        value={props.pageValuesData.currency_conversion_charge ? props.pageValuesData.currency_conversion_charge : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Charges in Destination</Form.Label>
                                    <Form.Control type="text"
                                        name="charges_in_destination"
                                        value={props.pageValuesData.charges_in_destination ? props.pageValuesData.charges_in_destination : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Row>
                                        <Col> <Form.Label>Executed On</Form.Label></Col>
                                        <Col> <Form.Label>Date</Form.Label></Col>
                                    </Row>
                                    <Form.Control type="date"
                                        name="executed_date"
                                        value={props.pageValuesData.executed_date ? props.pageValuesData.executed_date : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Place</Form.Label>
                                    <Form.Control type="text"
                                        name="executed_place"
                                        value={props.pageValuesData.executed_place ? props.pageValuesData.executed_place : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-1">
                                    <Form.Label>Total Collect Charges</Form.Label>
                                    <Form.Control type="text" placeholder=""
                                        name="total_collection_charge"
                                        value={props.pageValuesData.total_collection_charge ? props.pageValuesData.total_collection_charge : ""}
                                        onChange={props.pageValueHadle} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ExportBLSecondDT;