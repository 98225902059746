import React, { useState, useEffect, Component } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Cookies from "js-cookie";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBookmark, faBookBookmark, faUserDoctor, faTruckPlane, faBuildingColumns, faRoute, faReply, faTruckRampBox, faMoneyBills, faFileInvoiceDollar, faReceipt, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import SelectDropdown from "../SelectDropdown";
import ReportModel from "./ReportModel";
import reportsItems from "./Components/pageItemDATA/BookingReports.json"
import "react-toastify/dist/ReactToastify.css";

const BookingReports = () => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [currentReportData, setCurrentReportData] = useState([]);
    const PageinitialValues = {}
    const [pageValuesData, setpageValuesData] = useState(PageinitialValues);
    const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
    const [feildValidate, setFeildValidate] = useState(true);
    const [yearsWiseBookingHeaders, setYearsWiseBookingHeaders] = useState([]);
    const [selectedBookingData, setSelectedBookingData] = useState([]);

    // Retrieve the value from local storage
    const userAuthoritiesFromLocalStorage = localStorage.getItem('userAuthorities');

    // Check if the retrieved value is a string representing an object
    // Parse the string to an object or set it as an empty array if it's null or undefined
    let initialUserAuthorities;
    try {
        initialUserAuthorities = userAuthoritiesFromLocalStorage ? JSON.parse(userAuthoritiesFromLocalStorage) : [];
    } catch (error) {
        // Handle the error (e.g., log it) if parsing fails
        console.error("Parsing error:", error);
        // Set initialUserAuthorities as an empty array or handle the error accordingly
        initialUserAuthorities = [];
    }

    // Use useState to set the initial state
    const [userAuthorities, setUserAuthorities] = useState(initialUserAuthorities);

    const exportToExcel = () => {
        if (selectedBookingData.length === 0) {
            toast.error("No data available to export.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
        const fileName = `Job_Cost_And_Revenue_With_USD_${dateTime}.xlsx`;
        const worksheet = XLSX.utils.json_to_sheet(selectedBookingData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, fileName);
    };

    const iconMapping = {
        faUser: faUser,
        faBookmark: faBookmark,
        faBookBookmark: faBookBookmark,
        faUserDoctor: faUserDoctor,
        faTruckPlane: faTruckPlane,
        faBuildingColumns: faBuildingColumns,
        faRoute: faRoute,
        faReply: faReply,
        faTruckRampBox: faTruckRampBox,
        faMoneyBills: faMoneyBills,
        faFileInvoiceDollar,
        faReceipt: faReceipt,
        faFileInvoice: faFileInvoice,
    };

    const pageValueHadle = (e) => {
        if (e.target.name === "transport_mode_id" && e.target.checked) {
            setpageValuesData(prevData => ({
                ...prevData,
                transport_mode_id: 0,
            }));
        } else if (e.target.name === "job_type_id" && e.target.checked) {
            setpageValuesData(prevData => ({
                ...prevData,
                job_type_id: 0,
            }));
        } else {
            const { name, value } = e.target;
            setpageValuesData({ ...pageValuesData, [name]: value });
        }
    };

    const handleRadioVal = (fieldsLabel) => {
        const labelToApiMapping = {
            "Pending Booking": "other_pending_booking_detail",
            "Approve Booking not Invoice": "other_approved_booking_not_invoice_detail",
            "Invoice Booking": "other_invoiced_booking_detail",
            "Booking Wise Customers": "other_booking_wise_customer_detail",
            "Logistic Booking Sequence": "other_logistic_booking_sequence_detail",
            "FCL": "fcl_booking_detail",
            "LCL": "lcl_booking_detail",
        };

        const apiValue = labelToApiMapping[fieldsLabel];

        if (apiValue) {
            setCurrentReportData({ ...currentReportData, api: apiValue });
        }
    };

    const dropDownValueHadle = (selectedOption, { name }) => {
        if (name === "year") {

            fetch(`${BASE_URL}job_cost_and_revenue_with_USD/get_filtered_booking_numbers?year=${selectedOption.value}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    setYearsWiseBookingHeaders(data);
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }

        if (name === "booking_header_id") {

            fetch(`${BASE_URL}job_cost_and_revenue_with_USD/print?booking_header_id=${selectedOption.value}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    setSelectedBookingData(data)

                    // Process the retrieved 'data' here
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                    // Handle errors appropriately (e.g., display a message to the user)
                });
        }
        setpageValuesData(prevData => ({
            ...prevData,
            [name]: selectedOption.value
        }));
    };


    const [displayItemCount, setDisplayItemCount] = useState(3);

    // === Service Optons === //
    const serviceOptions = [];
    for (let i = 1; i <= 12; i++) {
        serviceOptions.push({ value: i, label: i.toString() });
    }

    const iconStyle = {
        color: "#FFFFFF",
        marginLeft: "15px",
        cursor: "pointer",
        margin: "5px",
    };

    function reportHadle(index) {
        setValidateFailedInputsList([]);
        setSelectedBookingData([]);
        setCurrentReportData(reportsItems[index]);
        handleShow();
        setpageValuesData(PageinitialValues);
    }
    const checkDATA = () => {
        console.log(pageValuesData);
    }
    const collemPerRow = displayItemCount;
    const displayReportItem = [];
    const displayInvoiceItem = [];
    const displayOtherItem = [];
    let currentReportRow = [];
    let reportItemnumber = 0;
    let currentInvoiceRow = [];
    let invoiceItemNumber = 0;
    let currentOtherRow = [];
    let otherItemNumber = 0;

    reportsItems.forEach((reportItem, index) => {
        if (reportItem.category === "BookingReports") {
            if (userAuthorities.find(userAuthorities => userAuthorities.form_id === reportItem.form_id)) {
                reportItemnumber++;
                currentReportRow.push(
                    <Col key={index} className="text-center report-col">
                        <Form.Group className="mb-3 report-button">
                            <Button type="btn" className="w-100 h-100" onClick={() => reportHadle(index)}><div><FontAwesomeIcon className="ms-2 mt-2" icon={iconMapping[reportItem.report_icon]}
                                size="xl" style={iconStyle} /></div>{reportItem.report_name}</Button>
                        </Form.Group>
                    </Col>
                );
                if (currentReportRow.length === collemPerRow || reportItemnumber === reportsItems.filter(item => item.category === "BookingReports").length) {
                    displayReportItem.push(<Row>{currentReportRow}</Row>);
                    currentReportRow = [];
                }
            }

        }
        if (reportItem.category === "InvoiceReports") {
            if (userAuthorities.find(userAuthorities => userAuthorities.form_id === reportItem.form_id)) {
                invoiceItemNumber++;
                currentInvoiceRow.push(
                    <Col key={index} className="text-center report-col">
                        <Form.Group className="mb-3 report-button">
                            <Button type="btn" className="w-100 h-100" onClick={() => reportHadle(index)}><div><FontAwesomeIcon className="ms-2 mt-2" icon={iconMapping[reportItem.report_icon]}
                                size="xl" style={iconStyle} /></div>{reportItem.report_name}</Button>
                        </Form.Group>
                    </Col>
                );
                if (currentInvoiceRow.length === collemPerRow || invoiceItemNumber === reportsItems.filter(item => item.category === "InvoiceReports").length) {
                    displayInvoiceItem.push(<Row>{currentInvoiceRow}</Row>);
                    currentInvoiceRow = [];
                }
            }
        }

        if (reportItem.category === "Others") {

            if (userAuthorities.find(userAuthorities => userAuthorities.form_id === reportItem.form_id)) {
                otherItemNumber++;
                currentOtherRow.push(
                    <Col key={index} className="text-center report-col">
                        <Form.Group className="mb-3 report-button">
                            <Button type="btn" className="w-100 h-100" onClick={() => reportHadle(index)}><div><FontAwesomeIcon className="ms-2 mt-2" icon={iconMapping[reportItem.report_icon]}
                                size="xl" style={iconStyle} /></div>{reportItem.report_name}</Button>
                        </Form.Group>
                    </Col>
                );
                if (currentOtherRow.length === collemPerRow || otherItemNumber === reportsItems.filter(item => item.category === "Others").length) {
                    displayOtherItem.push(<Row>{currentOtherRow}</Row>);
                    currentOtherRow = [];
                }
            }
        }

    });
    const inpuItemVal = (event) => {
        setDisplayItemCount(event.value);
    }

    const printpageValuesData = () => {
        if (currentReportData.formfields !== undefined) {
            const newvalidateFailedInputsList = [];
            currentReportData.formfields.forEach((formfield) => {
                if (formfield.status === "required") {
                    const isNameInPageValuesData = pageValuesData.hasOwnProperty(formfield.name);
                    let fieldValue = null;
                    if (isNameInPageValuesData) {
                        fieldValue = pageValuesData[formfield.name];
                    } else {
                        newvalidateFailedInputsList.push(formfield.name);
                        toast.error(`${formfield.fieldsLabel} can not be empty`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                }
            });
            setValidateFailedInputsList(newvalidateFailedInputsList);
            if (newvalidateFailedInputsList.length === 0) {
                setFeildValidate(true);
                if (pageValuesData.from_date !== undefined && pageValuesData.to_date !== undefined) {
                    if (pageValuesData.from_date <= pageValuesData.to_date) {
                        callReportPrintAPI();
                    } else {
                        toast.error("Invalid Date Range", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        console.log(pageValuesData.from_date)
                    }
                } else {
                    callReportPrintAPI();
                }

            } else {
                console.log("Canot be Empty felids " + newvalidateFailedInputsList.length);
            }
        } else {
            toast.error("Report Form Fields Undefined", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const callReportPrintAPI = () => {
        const queryString = Object.keys(pageValuesData)
            .map((key) => `${key}=${pageValuesData[key]}`)
            .join("&");
        if (currentReportData.api !== "") {
            const url = `${BASE_URL}${currentReportData.api}/print?${queryString}`;
            axios
                .get(url, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${authtoken}`,
                    },
                    responseType: "blob", // Set the response type to 'blob' to handle binary data
                })
                .then((res) => {
                    // Create a blob URL from the response data
                    const blob = new Blob([res.data], { type: "application/pdf" });
                    const pdfUrl = window.URL.createObjectURL(blob);

                    // Open the PDF URL in a new tab
                    window.open(pdfUrl);

                    // Release the object URL when done to free up resources
                    window.URL.revokeObjectURL(pdfUrl);

                    // toast.success("Downloading file", {
                    //   position: toast.POSITION.TOP_RIGHT,
                    // });
                })
                .catch((error) => {
                    console.error("Error fetching PDF:", error);
                });
        } else {
            toast.error("Request Undefined", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    return (
        <Container fluid className="pt-3">
            <ToastContainer autoClose={2000} />
            <Row className="d-flex align-items-center mx-auto mb-2 mt-2 text-end">
                <Col>
                    <label className="me-2 report-tile-label">Select Tile View Type </label>
                    <div className="d-inline-flex">
                        <SelectDropdown
                            options={serviceOptions}
                            onChange={inpuItemVal}
                            name={"service_origin"}
                            style={{ marginLeft: 'auto' }}
                        />
                    </div>
                </Col>
            </Row>
            <h4 className="report-page-titles">Booking Reports</h4>
            {displayReportItem}
            <h4 className="report-page-titles">Invoice Reports</h4>
            {displayInvoiceItem}
            <h4 className="report-page-titles">Other Reports</h4>
            {displayOtherItem}

            <Modal show={show} onHide={handleClose} size="xl">
                <ReportModel
                    pageValueHadle={pageValueHadle}
                    dropDownValueHadle={dropDownValueHadle}
                    handleRadioVal={handleRadioVal}
                    currentReportData={currentReportData}
                    pageValuesData={pageValuesData}
                    yearsWiseBookingHeaders={yearsWiseBookingHeaders}
                    selectedBookingData={selectedBookingData}
                    validateFailedInputsList={validateFailedInputsList}> </ReportModel>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={checkDATA}>
                        DATA Check
                    </Button> */}
                    {currentReportData.report_name !== "Job Cost And Revenue WIth USD" ?
                        <Button variant="primary" onClick={printpageValuesData}>
                            Print
                        </Button> : ""}
                    {currentReportData.report_name === "Job Cost And Revenue WIth USD" ?
                        <Button variant="primary" onClick={exportToExcel}>
                            Export Excel
                        </Button>
                        : ""}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default BookingReports;