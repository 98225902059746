import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import BASE_URL from "../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../TableView";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiInputs from "../CommonComponents/MultiInputs";
import SelectDropdown from "../SelectDropdown";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const Customer = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("customers");
  const [data, setData] = useState(undefined);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
  const [feildValidate, setFeildValidate] = useState(true);
  const [paytermList, setPaytermList] = useState(undefined);
  const [newItemMode, setNewItemMode] = useState(false);
  const [selectRowData, setSelectRowData] = useState("");
  const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["country", "state", "postal_code", "city", "contact_person", "email", "customer_name", "customer_code", "short_name", "telephone_1", "telephone_2", "contact_telephone"]);
  const PageinitialValues = {
    customer_code: "",
    customer_name: "",
    short_name: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    address_line_4: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    country_id: "",
    contact_person: "",
    telephone_1: "",
    telephone_2: "",
    fax: "",
    email: "",
    web: "",
    contact_telephone: "",
    payment_term: ""
  }
  const [currentCustomer, setCurrentCustomer] = useState(undefined);

  const pageValueHadle = (e) => {
    const { name, value } = e.target;
    setCurrentCustomer({ ...currentCustomer, [name]: value });
  };

  function setSelectedRow(rowid) {
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data;
        setCurrentCustomer(selectRowData.customer)
        setNewItemMode(false);
      });
  }
  const createNewRow = () => {
    setNewItemMode(true);
    setCurrentCustomer(PageinitialValues);
  };

  const datackeck = () => {
    console.log(currentCustomer);
    console.log(data);
  }

  // === Payment Term === //

  useEffect(() => {
    fetch(`${BASE_URL}customer_payment_terms/dropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPaytermList(data.payment_term);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken]);

  const paytermOptions = paytermList ?
    paytermList.map((payTermOptionItem) => ({
      value: payTermOptionItem.id,
      label: payTermOptionItem.payment_term,

    })) : [];

  const valuePaymentTerm = (event) => {
    setCurrentCustomer({ ...currentCustomer, "payment_term": event.value });
  }

  const deleteCustomer = () => {
    if (currentCustomer && currentCustomer.id !== undefined) {
      axios.delete(
        `${BASE_URL}${pageComponent}/destroy/${currentCustomer.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      toast.warning("Successfully Deleted Data Row", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else
      toast.error("Please Select Customer", {
        position: toast.POSITION.TOP_RIGHT,
      });
  }

  const datapassStage = () => {
    console.log(currentCustomer); // Check if payment_term is included here
    if (feildValidate === true) {
      if (currentCustomer.id !== undefined) {
        axios
          .post(
            `${BASE_URL}${pageComponent}/update/${currentCustomer.id}`,
            currentCustomer,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          )
          .then((response) => {
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => {
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        axios
          .post(`${BASE_URL}${pageComponent}/store`, currentCustomer, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
          .then((response) => {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => {
            console.error(error);
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  }

  const validateEmptyField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setFeildValidate(true);
    }
    return true;
  };
  const validateInvalidField = (fieldName) => {
    toast.error(`Invalid ${fieldName}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };


  const validateFillData = async () => {
    // === Contact number Validation === //
    const PhoneNumberList = (["telephone_1", "telephone_2", "contact_telephone"]);
    PhoneNumberList.forEach(phoneNumberItem => {
      if (!validateFailedInputsList.includes(phoneNumberItem)) {
        const phoneNumber = currentCustomer[phoneNumberItem].replace(/\D/g, '');
        const phonePattern = /^(?:\+?\d{1,3})?\d{10,}$/;
        if (phonePattern.test(phoneNumber)) {
          setFeildValidate(false);
        } else {
          setFeildValidate(false);
          setValidateFailedInputsList([...validateFailedInputsList, phoneNumberItem]);
          const keyWord = phoneNumberItem.split('_');
          const capitalizeKeyWords = keyWord.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          });
          const labelText = capitalizeKeyWords.join(' ');
          validateInvalidField(labelText)
        }
      }
    });
    // === Email Validation === //
    if (!validateFailedInputsList.includes("email")) {
      const currentEmail = currentCustomer.email;
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (emailPattern.test(currentEmail)) {
        setFeildValidate(false);
      } else {
        setFeildValidate(false);
        setValidateFailedInputsList([...validateFailedInputsList, "email"]);
        validateInvalidField("E-mail");
      }
    }
    datapassStage()
  }

  const handleSave = async (event) => {
    event.preventDefault();
    const isEmpty = (value) => !value;
    const newvalidateFailedInputsList = [];
    for (const key in currentCustomer) {
      if (currentCustomer.hasOwnProperty(key)) {
        const value = currentCustomer[key];
        if (isEmpty(value)) {
          newvalidateFailedInputsList.push(key);
          if (cantBeEmptyFeilds.includes(key)) {
            const keyWord = key.split('_');
            const capitalizeKeyWords = keyWord.map(word => {
              return word.charAt(0).toUpperCase() + word.slice(1);
            });
            const labelText = capitalizeKeyWords.join(' ');
            validateEmptyField(value, labelText);
          }
        }
      }
    }
    setValidateFailedInputsList(newvalidateFailedInputsList);
    if (newvalidateFailedInputsList.some(field => cantBeEmptyFeilds.includes(field))) {
    } else {
      validateFillData();
    }

  };

  const edditRow = (event) => {

  };

  const deleteRow = (event) => {
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.customers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  const tTitle = [
    "ID",
    "short name",
    "customer name",
    "address line 1",
    "address line 2",
    "address line 3",
    "address line 4",
    "city",
    "state",
    "postal code",
    "country",
    "telephone 1",
    "telephone 2",
    "fax",
    "email",
    "web",
    "contact person",
    "contact phone",
    "pay term",
  ];

  const columns = [
    "id",
    "short_name",
    "customer_name",
    "address_line_1",
    "address_line_2",
    "address_line_3",
    "address_line_4",
    "city",
    "state",
    "postal_code",
    "country",
    "telephone_1",
    "telephone_2",
    "fax",
    "email",
    "web",
    "contact_person",
    "contact_phone",
    "payment_term",
  ];

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 25 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Customer_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-3 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"customer_code"}
                    readOnly={selectRowData !== "" ? true : false}
                    // className={validateFailedInputsList.includes('customer_code') ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.customer_code ? currentCustomer.customer_code : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Name <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"customer_name"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes('customer_name') ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.customer_name ? currentCustomer.customer_name : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Short Name <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"short_name"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes('short_name') ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.short_name ? currentCustomer.short_name : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>E-Mail <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    name={"email"}
                    value={currentCustomer !== undefined ? currentCustomer.email ? currentCustomer.email : "" : ""}
                    className={validateFailedInputsList.includes('email') ? "border-red2" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Telephone 1 <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"telephone_1"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes('telephone_1') ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.telephone_1 ? currentCustomer.telephone_1 : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Telephone 2 <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"telephone_2"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("telephone_2") ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.telephone_2 ? currentCustomer.telephone_2 : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Fax</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"fax"}
                    readOnly={selectRowData !== "" ? true : false}
                    value={currentCustomer !== undefined ? currentCustomer.fax ? currentCustomer.fax : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"web"}
                    readOnly={selectRowData !== "" ? true : false}
                    value={currentCustomer !== undefined ? currentCustomer.web ? currentCustomer.web : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Contact Person <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"contact_person"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes('contact_person') ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.contact_person ? currentCustomer.contact_person : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Address <span className="RQField">*</span></Form.Label>
                  <MultiInputs nuOfInputs={4} clName={["address_line_1", "address_line_2", "address_line_3", "address_line_4"].every(line => validateFailedInputsList.includes(line)) ? "border-red2" : ""} dataObject={currentCustomer !== undefined ? currentCustomer : ""} inputBaseName="address_line_" onChangeFunction={pageValueHadle} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>City <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"city"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("city") ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.city ? currentCustomer.city : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Postal Code <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"postal_code"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("postal_code") ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.postal_code ? currentCustomer.postal_code : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>State/Province <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"state"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("state") === true ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.state ? currentCustomer.state : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Country <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"country"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("country") ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.country ? currentCustomer.country : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Payment Term <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    type="text"
                    placeholder=""
                    name={"payment_term"}
                    clName={validateFailedInputsList.includes("payment_term") ? "border-red2" : ""}
                    options={paytermOptions}
                    onChange={valuePaymentTerm}
                    value={currentCustomer !== undefined ? paytermOptions.find(option => option.value === currentCustomer.payment_term) : null}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Contact Telephone <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name={"contact_telephone"}
                    readOnly={selectRowData !== "" ? true : false}
                    className={validateFailedInputsList.includes("contact_telephone") ? "border-red2" : ""}
                    value={currentCustomer !== undefined ? currentCustomer.contact_telephone ? currentCustomer.contact_telephone : "" : ""}
                    onChange={pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="text-center mt-3">
              <Col>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button className="ms-3" variant="primary" type="submit">
                    Search
                  </Button>
                </Form>
              </Col>
              <Col className="text-end">
                {/* <Button
                  className="ms-3"
                  variant="primary"
                  onClick={datackeck}
                >
                  data ckeck
                </Button> */}
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="save"
                  label="Save"
                  onClick={selectRowData !== "" ? edditRow : handleSave}
                  disabled={newItemMode ? false : true}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="new"
                  label="New"
                  onClick={createNewRow}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="update"
                  label="Update"
                  onClick={handleSave}
                  disabled={newItemMode ? true : false}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="delete"
                  label="Delete"
                  onClick={deleteCustomer}
                  disabled={newItemMode ? true : false}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="print"
                  label="Print"
                  onClick={handleButtonClickPDF}
                />


                {/* <Button className="ms-3" variant="primary" type="submit">
                  Cancel
                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TableView
            data={data}
            tTitle={tTitle}
            columns={columns}
            setSelectedRow={setSelectedRow}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Customer;
