import React, { useEffect } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BillTable from "../BillTableView";
import { Container } from "react-bootstrap";
import SelectDropdown from "../SelectDropdown";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const SupplierInvoice = (props) => {
  const storedToken = Cookies.get("authToken");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [invoiceNOList, setInvoiceNOList] = useState(undefined);
  const [pageData, setPageData] = useState(undefined);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [nbtPercentage, setNbtPercentage] = useState("");
  const [vatPercentage, setVatPercentage] = useState("");
  const [payterm, setPayterm] = useState("");
  const [paytermDesc, setPaytermDesc] = useState("");
  const [bookindNo, setBookindNo] = useState("");
  const [supName, setSupName] = useState("");
  const [supCode, setSupCode] = useState("");
  const [rateCode, setRateCode] = useState("");
  const [rateDesc, setRatedesc] = useState("");
  const [currency, setcurrency] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [eXGRate, setEXGRate] = useState("");
  const [volume, setvolume] = useState("");
  const [amount, setAmount] = useState("");
  const [totAmount, setTotAmount] = useState("");
  const [supINVNO, setSupINVNO] = useState("");
  const [iNVDate, setINVDate] = useState("");
  const [postDate, setPostDate] = useState("");
  const [period, setPeriod] = useState("");
  const [transactionClass, setTransactionClass] = useState("");
  const [transactionClassName, setTransactionClassName] = useState("");
  const [paymentModeCode, setPaymentModeCode] = useState("");
  const [paymentModeDesc, setPaymentModeDesc] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [transactionTypeName, setTransactionTypeName] = useState("");
  const [remark, setRemark] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [feildValidate, setFeildValidate] = useState(true);
  const [edditMode, setEdditMode] = useState(false);
  const [invoiceNumList, setInvoiceNumList] = useState(false);
  const [tableItems, setTableItems] = useState([]);
  const [tableValidateFeildItems, setTablevalidateFeildItems] = useState([]);
  const [selectedTbaleRowID, setSelectedTbaleRowID] = useState("");

  // ====  Page Data ==== //
  useEffect(() => {
    fetch(`${BASE_URL}supplier_invoices/alldropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken]);

  // ====  Booking No ==== //
  const bookingNoOptions = pageData ?
    pageData.booking_data.map((bookinNoOptionItem) => ({
      value: bookinNoOptionItem.id,
      label: bookinNoOptionItem.booking_number,
    })) : [];

  // ====  Supplier ==== //
  const suplierOptions = pageData ?
    pageData.suppliers_data.map((suplierOptionItem) => ({
      value: suplierOptionItem.id,
      label: suplierOptionItem.supplier_name,
    })) : [];

  const valueToSupplierHadle = (event) => {
    setSupCode(event.value);
    setSupName(event.label);
  };

  // ====  Rate ==== //
  const ratesOptions = pageData ?
    pageData.rates_data.map((rateOptionItem) => ({
      value: rateOptionItem.id,
      label: rateOptionItem.rate_description,
    })) : [];

  const valueToRateHadle = (event) => {
    setRateCode(event.value);
    setRatedesc(event.label);
  };

  // ====  Currency ==== //
  const currencyOptions = pageData ?
    pageData.currency_data.map((currencyOptionItem) => ({
      value: currencyOptionItem.id,
      label: currencyOptionItem.currency_description,
    })) : [];

  const valueToCurruncyHadle = (event) => {
    setcurrency(event.value);
    setCurrencyName(event.label);
  };

  // ====  Payment Term ==== //
  const paytermListOptions = pageData ?
    pageData.payment_terms_to_supplier_data.map((paytermOptionItem) => ({
      value: paytermOptionItem.id,
      label: paytermOptionItem.payment_term,
    })) : [];

  const setPaytermDt = (event) => {
    setPayterm(event.value);
    setPaytermDesc(event.label);
  };

  // ====  Transaction Class ==== //
  const transactionClassListOptions = pageData ?
    pageData.transaction_classes_data.map((transactionClassItem) => ({
      value: transactionClassItem.id,
      label: transactionClassItem.transaction_class,
    })) : [];

  const setTransactionClassDt = (event) => {
    setTransactionClass(event.value);
    setTransactionClassName(event.label);
  };

  // ====  Transaction Type ==== //
  const transactionTypeListOptions = pageData ?
    pageData.transaction_types_data.map((transactionTypeItem) => ({
      value: transactionTypeItem.id,
      label: transactionTypeItem.transaction_type,
    })) : [];

  const setTransactionTypeDt = (event) => {
    setTransactionType(event.value);
    setTransactionTypeName(event.label);
  };

  // ====  Payment Mode ==== //
  const paymentModeOptions = pageData ?
    pageData.payment_modes_data.map((transactionTypeItem) => ({
      value: transactionTypeItem.id,
      label: transactionTypeItem.payment_mode,
    })) : [];

  const hadlePaymentMode = (event) => {
    setPaymentModeCode(event.value);
    setPaymentModeDesc(event.label);
  }
  const passBookingNUM = (value) => {
    setBookindNo(value);
  };

  // ====  InvNo ==== //

  const invNoOptions = invoiceNOList ?
    invoiceNOList.map((InvNoItem) => ({
      value: InvNoItem.supplier_invoice_number,
      label: InvNoItem.supplier_invoice_number,
    })) : [];

  // === validatios === //

  const validateField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setTablevalidateFeildItems(prevItems => [...prevItems, fieldName]);
      // setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setTablevalidateFeildItems(prevItems => prevItems.filter(item => item !== fieldName));
      setFeildValidate(true);
    }
    return true;
  };

  const saveSupINvoice = () => {
    postDataToSave();
  };

  const edditInvoice = () => {
    setEdditMode(true);
  }

  const searchInvoice = () => {
    setInvoiceNumList(true);
    const dateRange = {
      from_date: fromDate,
      to_date: toDate,
    };

    const url = 'supplier_invoices/get_specific_date_range_data';

    // Encode the dateRange object as a query parameter
    const encodedData = encodeURIComponent(JSON.stringify(dateRange));

    // Append the encoded data to the URL as a query parameter
    const fullUrl = `${BASE_URL}${url}?data=${encodedData}`;

    axios
      .get(fullUrl, {
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        setInvoiceNOList(res.data.supplier_invoice)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const postDataToSave = () => {
    if (feildValidate === true) {
      const supplierInvoice = {
        supplier_invoice_Wise_rates_data: tableItems,
      };
      axios.post(
        `${BASE_URL}supplier_invoices/store`, supplierInvoice,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authtoken}`,
          }
        }
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
    }
  }

  const selectInv = (event) => {
    setSelectedInvoice(event.value);
    const invoice_number = event.value;

    const url = 'supplier_invoices/search/';

    // Encode the dateRange object as a query parameter
    const encodedData = encodeURIComponent(JSON.stringify(invoice_number));

    // Append the encoded data to the URL as a query parameter
    const fullUrl = `${BASE_URL}${url}?data=${encodedData}`;

    axios
      .get(fullUrl, {
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const headercolumns = [
    "Supplier Name",
    "Rate",
    "Currency",
    "Ex Rate",
    "Volume",
    "Amount",
    "Total Amount",
    "Remark",
    "SupInvNo",
    "SupInvDate",
    "AccPacACCTID",
    "AccPacACCTDESC",
    "Period",
    "NBT",
    "VAT",
    "PaymentTerm",
    "TransactionClass",
    "TransactionType",
    "PaymentMode",
  ];

  const columns = [
    "SuppName",
    "Rate",
    "currency",
    "exchange_rate",
    "volume",
    "amount",
    "totalAmount",
    "remark",
    "supplier_invoice_number",
    "supplier_invoice_date",
    "AccPacACCTID",
    "AccPacAccTDESC",
    "period",
    "NBT",
    "VAT",
    "PaymentTerm",
    "TransactionClass",
    "TransactionType",
    "PaymentMode",
  ];

  function setSelectedRow(rowid, row) {
    setSupCode(row.supplier_id);
    setRateCode(row.rate_id);
    setEXGRate(row.exchange_rate);
    setvolume(row.volume);
    setAmount(row.amount);
    setTotAmount(row.totalAmount);
    setcurrency(row.currency_id);
    setSupINVNO(row.supplier_invoice_number);
    setINVDate(row.supplier_invoice_date);
    setPostDate(row.post_date);
    setPeriod(row.period);
    setPayterm(row.payment_term_id);
    setTransactionClass(row.transaction_class_id);
    setPaymentModeCode(row.payment_mode_id);
    setTransactionType(row.transaction_type_id);
    setRemark(row.remark);
    setSelectedTbaleRowID(rowid);
  }

  const setTableValue = async () => {
    validateField(supCode, "Supplier");
    validateField(rateCode, "Rate");
    validateField(currency, "Currency");
    validateField(eXGRate, "Exg Rate");
    validateField(volume, "Volume");
    validateField(amount, "Amount");
    validateField(totAmount, "Tot Amount");
    validateField(remark, "Remark");
    validateField(supINVNO, "Sup Inv No");
    validateField(iNVDate, "Sup Inv Date");
    validateField(postDate, "Post Date");
    validateField(period, "Period");
    validateField(payterm, "Payment Term");
    validateField(transactionClass, "Transaction Class");
    validateField(paymentModeCode, "Payment Mode");
    validateField(transactionType, "Transaction Type");
    
    // Check if booking number is selected
    if (!bookindNo || !bookindNo.value) {
      toast.error("Booking Number is required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    appndTableValue();
  };

  const appndTableValue = async () => {
    if (tableValidateFeildItems.length === 0) {
      const updateTableVal = {
        SuppName: supName ? supName : "",
        supplier_id: supCode,
        booking_header_id: bookindNo.value,
        rate_id: rateCode,
        Rate: rateDesc,
        currency: currencyName,
        currency_id: currency,
        exchange_rate: eXGRate,
        volume: volume,
        nbt_percentage: nbtPercentage,
        vat_percentage: vatPercentage,
        amount: amount,
        totalAmount: amount * volume * eXGRate,
        remark: remark,
        supplier_invoice_number: supINVNO,
        supplier_invoice_date: iNVDate,
        post_date: postDate,
        period: period,
        PaymentTerm: paytermDesc,
        payment_term_id: payterm,
        transaction_class_id: transactionClass,
        TransactionClass: transactionClassName,
        transaction_type_id: transactionType,
        TransactionType: transactionTypeName,
        PaymentMode: paymentModeDesc,
        payment_mode_id: paymentModeCode,
      };
      if ((amount * volume * eXGRate) > 0) {
        const updateTableLIst = tableItems.concat(updateTableVal);
        setTableItems(updateTableLIst);
      }
    }
  };

  const calculateTotalAmount = () => {
    return tableItems.reduce(
      (total, tableItem) => total + Number(tableItem.totalAmount),
      0
    );
  };

  const removeTableRow = async () => {
    const updateTableLIst = tableItems.filter((obj, index) => index !== selectedTbaleRowID);
    setTableItems(updateTableLIst);
  }

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className="mt-3 bottom-border pb-3">
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Booking No</Form.Label>
                  <SelectDropdown
                    options={bookingNoOptions}
                    onChange={passBookingNUM}
                    disabled={edditMode === true ? true : false}
                    value={
                      bookindNo !== "" ?
                        bookingNoOptions.find(option => option.value === bookindNo) : null
                    }
                  />
                  <Form.Check
                    type="checkbox"
                    className="mt-1"
                    label="All Bookings"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Invoice No</Form.Label>
                  <SelectDropdown
                    options={invNoOptions}
                    onChange={selectInv}
                    disabled={invoiceNumList === true ? false : true}
                    value={
                      selectedInvoice !== "" ?
                        invNoOptions.find(option => option.value === selectedInvoice) : null
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="date"
                    disabled={edditMode === true ? false : true}
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="date"
                    disabled={edditMode === true ? false : true}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2">
                <Button className="mt-3" variant="primary" onClick={searchInvoice} disabled={edditMode === true ? false : true}>
                  Search
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-2" >
                  <Form.Label>Supplier <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={suplierOptions}
                    onChange={valueToSupplierHadle}
                    value={supCode !== "" ? suplierOptions.find(option => option.value === supCode) : null}
                    clName={tableValidateFeildItems.includes("Supplier") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2" >
                  <Form.Label>Rate <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={ratesOptions}
                    onChange={valueToRateHadle}
                    value={rateCode !== "" ? ratesOptions.find(option => option.value === rateCode) : null}
                    clName={tableValidateFeildItems.includes("Rate") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>Exchange Rate <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="exgrate"
                    value={eXGRate}
                    onChange={(e) => setEXGRate(e.target.value)}
                    className={tableValidateFeildItems.includes("Exg Rate") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>Volume <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="volume"
                    value={volume}
                    onChange={(e) => setvolume(e.target.value)}
                    className={tableValidateFeildItems.includes("Volume") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <Form.Label>Amount <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value) & setTotAmount(amount * volume * eXGRate)}
                    className={tableValidateFeildItems.includes("Amount") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Currency <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={currencyOptions}
                    onChange={valueToCurruncyHadle}
                    value={currency !== "" ? currencyOptions.find(option => option.value === currency) : null}
                    clName={tableValidateFeildItems.includes("Currency") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Total Amount <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="totAmount"
                    value={totAmount ? totAmount : amount * volume * eXGRate}
                    onChange={(e) => setTotAmount(e.target.value)}
                    className={tableValidateFeildItems.includes("Tot Amount") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Supplier Invoice No <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="supplier_invoice_number"
                    value={supINVNO}
                    onChange={(e) => setSupINVNO(e.target.value)}
                    className={tableValidateFeildItems.includes("Sup Inv No") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Supplier Invoice Date <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="date"
                    value={iNVDate}
                    onChange={(e) => setINVDate(e.target.value)}
                    className={tableValidateFeildItems.includes("Sup Inv Date") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Post Date <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="date"
                    value={postDate}
                    onChange={(e) => setPostDate(e.target.value)}
                    className={tableValidateFeildItems.includes("Post Date") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Period <span className="RQField">*</span> </Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                    className={tableValidateFeildItems.includes("Period") ? "border-red2" : ""}
                  >
                    <option value=""></option>
                    <option value="6">06</option>
                    <option value="7">07</option>
                    <option value="8">08</option>
                    <option value="9">09</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Payment Term <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={paytermListOptions}
                    onChange={setPaytermDt}
                    value={payterm !== "" ? paytermListOptions.find(option => option.value === payterm) : null}
                    clName={tableValidateFeildItems.includes("Payment Term") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Transaction Class <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={transactionClassListOptions}
                    onChange={setTransactionClassDt}
                    value={transactionClass !== "" ? transactionClassListOptions.find(option => option.value === transactionClass) : null}
                    clName={tableValidateFeildItems.includes("Transaction Class") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Payment Mode <span className="RQField">*</span></Form.Label>

                  <SelectDropdown
                    options={paymentModeOptions}
                    onChange={hadlePaymentMode}
                    value={paymentModeCode !== "" ? paymentModeOptions.find(option => option.value === paymentModeCode) : null}
                    clName={tableValidateFeildItems.includes("Payment Mode") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>Transaction Type <span className="RQField">*</span></Form.Label>
                  <SelectDropdown
                    options={transactionTypeListOptions}
                    onChange={setTransactionTypeDt}
                    value={transactionType !== "" ? transactionTypeListOptions.find(option => option.value === transactionType) : null}
                    clName={tableValidateFeildItems.includes("Transaction Type") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Remark <span className="RQField">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="remark"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    className={tableValidateFeildItems.includes("Remark") ? "border-red2" : ""}
                  />
                </Form.Group>
              </Col>
              <Col className="text-center mt-4">
                <Button
                  className="ms-3"
                  variant="primary"
                  onClick={setTableValue}
                >
                  ADD
                </Button>
                <Button className="ms-3" variant="primary" onClick={removeTableRow}>
                  Delete
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-4" style={{ overflowX: 'scroll', paddingBottom: '15px' }}>
        <Col>
          <BillTable data={tableItems}
            columns={columns}
            headercolumns={headercolumns}
            setSelectedRow={setSelectedRow} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Total Amount {calculateTotalAmount()}</Form.Label>
          </Form.Group>
        </Col>
        <Col className="mt-3 ms-auto text-end">
          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="save"
            label="Save"
            onClick={saveSupINvoice}
            className="ms-3"
            variant="primary"
          />
          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="edit"
            label="Edit"
            onClick={edditInvoice}
            className="ms-3"
            variant="primary"
          />
          {/* <Button className="ms-3" variant="primary" type="submit">
            Cancel
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
};

export default SupplierInvoice;
