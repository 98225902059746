import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import AirwayBillFirstPage from "./AirwayBill_include/FirstPage";
import AirwayBillSecondPage from "./AirwayBill_include/SecondPage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const AirwayBill = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("air_export_billing");
  const [selectedBLNo, setSelectedBLNo] = useState("");
  const [feildValidate, setFeildValidate] = useState(true);
  const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["house_billing_number", "vessel_id", "voyage_id", "port_of_delivery_id", "port_of_loading_id", "master_billing_number", "billing_date"]);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([""]);

  const PageinitialValues = {
    vessel_id: "",
    voyage_id: "",
    currency_id: "",
    port_of_delivery_id: "",
    port_of_loading_id: "",
    master_billing_number_id: "",
    house_billing_number: "",
    billing_date: "",
    shipper_1: "",
    shipper_2: "",
    shipper_3: "",
    shipper_4: "",
    shipper_5: "",
    shipper_account: "",
    consignee_1: "",
    consignee_2: "",
    consignee_3: "",
    consignee_4: "",
    consignee_5: "",
    consignee_account: "",
    notify_party_1: "",
    notify_party_2: "",
    notify_party_3: "",
    notify_party_4: "",
    notify_party_5: "",
    notify_party_6: "",
    agent_code: "",
    account_number: "",
    ChgsCode: "",
    currency_description: "",
    WTVALPPD: "",
    WTVAColl: "",
    OtherColl: "",
    OtherPPD: "",
    declared_value_for_carriage: "",
    declared_value_for_customers: "",
    amount_of_insurance: "",
    airline_code_1: "",
    airline_code_2: "",
    airline_code_by_1: "",
    airline_code_3: "",
    airline_code_by_2: "",
    air_export_wise_dimensions_data: [{ dimension: "" }],
    air_export_wise_flight_dates_data: [
      {
        flight_date: "",
        flight_date_1: ""
      },
      {
        flight_date: "",
        flight_date_1: ""
      }
    ],
    airport_of_destination: "",
    hand_information_1: "",
    hand_information_2: "",
    hand_information_3: "",
    number_of_rcp_pieces: "",
    gross_weight: "",
    KGBL: "",
    common_item_number: "",
    chargable_weight: "",
    rate_charge: "",
    total: "",
    marks_numbers_1: "",
    marks_numbers_2: "",
    marks_numbers_3: "",
    marks_numbers_4: "",
    marks_numbers_5: "",
    marks_numbers_6: "",
    marks_numbers_7: "",
    marks_numbers_8: "",
    marks_numbers_9: "",
    marks_numbers_10: "",
    nature_quantity_of_goods_1: "",
    nature_quantity_of_goods_2: "",
    nature_quantity_of_goods_3: "",
    nature_quantity_of_goods_4: "",
    weight_charge_prepaid: "",
    valuation_charge_prepaid: "",
    tax_prepaid: "",
    total_other_charge_due_carrier_pre_paid: "",
    total_other_charge_due_agent_pre_paid: "",
    weight_charge_collect: "",
    valuation_charge_collect: "",
    tax_collect: "",
    total_other_charge_due_carrier_collect: "",
    total_other_charge_due_agent_collect: "",
    total_paid: "",
    currency_conversion_rate: "",
    total_collect: "",
    currency_conversion_charge: "",
    charges_in_destination: "",
    executed_date: "",
    executed_place: "",
    total_collection_charge: "",
    other_chargers_1: "",
    other_chargers_2: "",
    other_chargers_3: "",
    entered_sequence: "",
    dimension: "",
    sequence: "",
    flight_date: "",
    ir_export_wise_flight_dates_sequence: "",
    flight_date_1: ""
  }

  const [pageValuesData, setpageValuesData] = useState(PageinitialValues);

  const hadleDropdown = (e) => {
    setpageValuesData({ ...pageValuesData, "currency_id": e.value });
  }
  const masterBillNoHadle = (event) => {
    setSelectedBLNo(event.value);
  }

  const pageValueHadle = (e) => {
    const { name, value } = e.target;
    setpageValuesData({ ...pageValuesData, [name]: value });
  };

  const objectDataHadle = (value) => {
    const air_export_wise_flight_dates_data = value;
    setpageValuesData({ ...pageValuesData, air_export_wise_flight_dates_data });
  }

  const dimensionsobjectDataHadle = (value) => {
    console.log(value);
    const air_export_wise_dimensions_data = value;
    setpageValuesData({ ...pageValuesData, air_export_wise_dimensions_data });
  }

  const handleContainerCUS = () => {
    console.log(pageValuesData);

  };
  const selectBLDataSet = (selectedData) => {
    setpageValuesData({ ...PageinitialValues, ...selectedData });
  }

  const saveAirbill = () => {
    axios.post(
      `${BASE_URL}air_export_billing/store`, pageValuesData,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Successfully Save Data ! ", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error);
        console.log(pageValuesData);
      })
  }

  const updateAirBill = () => {
    if (selectedBLNo !== "") {
      console.log(selectedBLNo);
      axios.post(
        `${BASE_URL}air_export_billing/update/${selectedBLNo}`, pageValuesData,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          alert(error);
          console.log(pageValuesData);
        })
    } else {
      alert("please select BL NO")
    }
  }

  const deleteItem = (event) => {
    event.preventDefault();
    if (pageValuesData.air_export_billing_id) {
      if (pageValuesData.air_export_billing_id !== "") {
        axios.delete(
          `${BASE_URL}${pageComponent}/destroy/${pageValuesData.air_export_billing_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
        toast.warning("Successfully Deleted Data Row", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setTimeout(window.location.reload.bind(window.location), 2000);
      } else {
        toast.error("BL Number undefined", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("You Can't BL Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const printBillDetails = () => {
    if (selectedBLNo) {
      if (selectedBLNo !== "") {
        const queryParams = {
          master_billing_number_id: selectedBLNo,
        };
        const queryString = Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&");

        const url = `${BASE_URL}air_export_billing/print?${queryString}`;
        axios
          .get(url, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
            responseType: "blob", // Set the response type to 'blob' to handle binary data
          })
          .then((res) => {
            // Create a blob URL from the response data
            const blob = new Blob([res.data], { type: "application/pdf" });
            const pdfUrl = window.URL.createObjectURL(blob);

            // Open the PDF URL in a new tab
            window.open(pdfUrl);

            // Release the object URL when done to free up resources
            window.URL.revokeObjectURL(pdfUrl);

            // toast.suc[cess("Downloading file", {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
          })
          .catch((error) => {
            console.error("Error fetching PDF:", error);
          });
      } else {
        toast.error("Please select an BL Number", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      alert("please Select valid Export BL");
    }
  }

  const validateEmptyField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setFeildValidate(true);
      if (feildValidate === true) {
        if (pageValuesData.air_export_billing_id) {
          updateAirBill();
        } else {
          saveAirbill();
        }
      }
    }
    return true;
  };

  const validateFeild = () => {
    const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
    const newvalidateFailedInputsList = [];
    for (const key in pageValuesData) {
      const value = pageValuesData[key];
      if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
        newvalidateFailedInputsList.push(key);
        const keyWord = key.split('_');
        const capitalizeKeyWords = keyWord.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const labelText = capitalizeKeyWords.join(' ');
        validateEmptyField(value, labelText);
      }
      setValidateFailedInputsList(newvalidateFailedInputsList);
    }
  }

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Tabs
        defaultActiveKey="home"
        id="justify-tab-example"
        className="mb-3 mt-3 pt-2"
        justify
      >
        <Tab eventKey="home" title="Page No 1">
          <AirwayBillFirstPage
            masterBillNoHadle={masterBillNoHadle}
            pageValueHadle={pageValueHadle}
            pageValuesData={pageValuesData}
            hadleDropdown={hadleDropdown}
            objectDataHadle={objectDataHadle}
            selectBLDataSet={selectBLDataSet}
          />
        </Tab>
        <Tab eventKey="profile" title="Page No 2">
          <AirwayBillSecondPage
            pageValueHadle={pageValueHadle}
            pageValuesData={pageValuesData}
            hadleDropdown={hadleDropdown}
            objectDataHadle={objectDataHadle}
            dimensionsobjectDataHadle={dimensionsobjectDataHadle}
          />
        </Tab>
      </Tabs>
      <Row>
        <Col className="text-end mb-2">
          {/* <Button
            className="ms-3"
            variant="primary"
            onClick={handleContainerCUS}
          >
            Data check
          </Button> */}
          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="save"
            label="Save"
            onClick={validateEmptyField}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="new"
            label="New"
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="update"
            label="Update"
            onClick={validateEmptyField}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="delete"
            label="Delete"
            onClick={deleteItem}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="print"
            label="Print"
            onClick={printBillDetails}
            className="ms-3"
            variant="primary"
          />


          {/* <Button className="ms-3" variant="primary" onClick={validateFeild}>
                  Validate
                </Button> */}

          {/* <Button className="ms-3" variant="primary" type="submit">
                Cancel
              </Button> */}
        </Col>
      </Row>

    </Container>
  );
};

export default AirwayBill;
