import TableView from "./TableView";
const Contact = () => {
    const data = [
        { id: 1, city: 'New York', population: 10000000 },
        { id: 2, city: 'London', population: 9000000 },
        { id: 3, city: 'Tokyo', population: 13000000 }
    ];

    const columns = ['city', 'population'];
    return (
        <>
            <h1>Contact Page</h1>
            <TableView data={data} columns={columns} />
        </>

    );
}

export default Contact;