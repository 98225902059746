import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiInputs from "../../CommonComponents/MultiInputs";

const ExportBLSecondDT = (props) => {
  const keysToCheck = ["marks_numbers_1", "marks_numbers_2", "marks_numbers_3"];

  // Generate an array of keys
  return (
    <Container fluid style={{ boxShadow: 'none' }}>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Container No | Seal No | Marks & Numbers</Form.Label>
                  <MultiInputs nuOfInputs={10} dataObject={props.pageValuesData} inputBaseName="marks_numbers_" onChangeFunction={props.onpageValueHadleFunction}

                    clName={
                      keysToCheck.every(key => props.validateFailedInputsList.includes("marks_numbers_1"))
                        ? "border-red2"
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>
                    No of Container or Package | kind of Packages | Description of Goods
                  </Form.Label>
                  <MultiInputs nuOfInputs={10} dataObject={props.pageValuesData} inputBaseName="package_description_" onChangeFunction={props.onpageValueHadleFunction} clName={props.validateFailedInputsList.includes("package_description_1") ? "border-red2" : ""} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Freight and Charges</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="freight_charges"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.freight_charges
                        ? props.pageValuesData.freight_charges
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Revenue Tons</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="revenue_tons"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.revenue_tons
                        ? props.pageValuesData.revenue_tons
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Rate</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="rate"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.rate ? props.pageValuesData.rate : ""
                    }
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Per</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="percentage"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.percentage ? props.pageValuesData.percentage : ""
                    }
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Prepaid</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder=""
                    name="prepaid"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.prepaid
                        ? props.pageValuesData.prepaid
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Collect</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="collect"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.collect
                        ? props.pageValuesData.collect
                        : ""
                    }
                    placeholder=""
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" controlId="MasterBL">
                  <Form.Label>Exchange Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="exchange_rate"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.exchange_rate
                        ? props.pageValuesData.exchange_rate
                        : ""
                    }
                    autoComplete="off"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Prepaid at</Form.Label>
                  <Form.Control
                    type="text"
                    name="prepaid_at"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.prepaid_at
                        ? props.pageValuesData.prepaid_at
                        : ""
                    }
                    placeholder=""
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Payable at</Form.Label>
                  <Form.Control
                    type="text"
                    name="payble_at"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.payble_at
                        ? props.pageValuesData.payble_at
                        : ""
                    }
                    placeholder=""
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Total Paid in Local Currency</Form.Label>
                  <Form.Control
                    type="text"
                    name="total_paid_in_national_currency"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.total_paid_in_national_currency
                        ? props.pageValuesData.total_paid_in_national_currency
                        : ""
                    }
                    autoComplete="off"
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Place and Date of Issue</Form.Label>
                  <Form.Control
                    type="text"
                    name="place_date_issue"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.place_date_issue
                        ? props.pageValuesData.place_date_issue
                        : ""
                    }
                    placeholder=""
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>No of Original Waybill</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="number_of_original_waybill"
                    onChange={props.onpageValueHadleFunction}
                    value={
                      props.pageValuesData.number_of_original_waybill
                        ? props.pageValuesData.number_of_original_waybill
                        : ""
                    }
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ExportBLSecondDT;
