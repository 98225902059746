import React, { useState } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import { Link } from 'react-router-dom';
import { Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Col, Row, Container, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const Topbar = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    };

    const toggleUserDropdown = () => {
        setIsUserDropdownOpen(!isUserDropdownOpen);
    };

    const openNotificationDropdown = () => {
        setIsNotificationDropdownOpen(true);
    };

    const closeNotificationDropdown = () => {
        setIsNotificationDropdownOpen(false);
    };

    const openUserDropdown = () => {
        setIsUserDropdownOpen(true);
    };

    const closeUserDropdown = () => {
        setIsUserDropdownOpen(false);
    };

    const logout = async () => {
        Cookies.remove("isLoggedIn");
        Cookies.remove("pageLocation");
        localStorage.removeItem('userAuthorities');
        try {
            const response = await fetch(`${BASE_URL}auth/logout`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            });

            if (response.status === 200) {
                window.location.href = "/";
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    };


    const iconStyle = {
        color: "#219ebc",
        marginleft: "15px",
        cursor: "pointer",
    };

    return (
        <Container fluid className="topbar">
            <Row className="p-1">
                <Col className="text-start ms-5">
                    <div className="breadcromb">
                        <Nav.Link onClick={() => props.setActiveComponent("submenuFile")}>
                            <span className="base-link">
                                <FontAwesomeIcon icon={faHouse} /> Home
                            </span>
                        </Nav.Link>
                        <span className="page-name">
                            {props.pageTitleMap[props.pageTitle] ? "/" + props.pageTitleMap[props.pageTitle] : ""}
                        </span>
                    </div>
                </Col>
                <Col className="ms-auto text-end ico-nline mt-auto mb-auto">
                    <div className="ms-auto d-flex">
                        <div
                            className="icon-item"
                            onMouseEnter={openNotificationDropdown}
                            onMouseLeave={closeNotificationDropdown}
                        >
                            <Dropdown show={isNotificationDropdownOpen} className="custom-dropdown">
                                <Dropdown.Toggle
                                    className="user-action"
                                    id="notification_action"
                                    onMouseEnter={openNotificationDropdown}

                                ><Badge className="badge" bg="primary">
                                        48
                                    </Badge>
                                    <FontAwesomeIcon className="ms-2 mt-2" icon={faBell} size="lg" style={iconStyle} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/notification-1">Notification 1</Dropdown.Item>
                                    <Dropdown.Item href="#/notification-2">Notification 2</Dropdown.Item>
                                    <Dropdown.Item href="#/notification-3">Notification 3</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div
                            className="icon-item"
                            onMouseEnter={openUserDropdown}
                            onMouseLeave={closeUserDropdown}
                        >
                            <Dropdown show={isUserDropdownOpen} className="custom-dropdown">
                                <Dropdown.Toggle
                                    className="user-action"
                                    id="user_action"
                                    onMouseEnter={openUserDropdown}

                                >
                                    <FontAwesomeIcon className="ms-2 mt-2" icon={faUser} size="lg" style={iconStyle} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item>My Profile</Dropdown.Item>
                                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Topbar;
