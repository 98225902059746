import React, { useState, useEffect, Component } from "react";
import ReactDOM from 'react-dom';
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import DOC_Path from "../config";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";

const BookingApproval = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("booking_costing/pending_data");
  const [bookingList, setBookingList] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const headercolumns = ["Booking No", "BL No", "Cost Amount", "Quote Amount", "Profit", "Select", "View", "View Document", "Remark"];
  const columns = ["booking_number", "bill_number", "Cost_Amount", "Quote_amt", "profit"];
  const [postsPerPage] = useState(15);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentItems = bookingList !== undefined ? bookingList.slice(indexOfFirstPost, indexOfLastPost) : undefined;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bookingWiseFileList, setBookingWiseFileList] = useState([]);
  const [currentBookingNo, setCurrentBookingNo] = useState("")

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  useEffect(() => {
    console.log(DOC_Path);
    fetch(`${BASE_URL}${pageComponent}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBookingList(data.pending_booking_numbers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [authtoken, pageComponent]);

  const handleCheckboxChange = (event, id) => {
    console.log(id);
    if (event.target.checked) {
      setSelectedRows([...selectedRows, id]);
    } else {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    }
  };

  const viewBooking = (id) => {
    if (id !== "") {
      axios
        .get(`${BASE_URL}booking_costing/print/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((res) => {
          // Create a blob URL from the response data
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          // Open the URL in a new tab
          window.open(url);

          // Release the object URL when done to free up resources
          window.URL.revokeObjectURL(url);

          // toast.success("downloading file", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    } else {
      toast.error("Please select Booking No", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const viewBookingDocs = (id, blno) => {
    const parts = blno.split('/');
    const Partlink = `${parts[0]}-${parts[1]}`;
    setCurrentBookingNo(Partlink)
    if (id) {
      axios
        .get(`${BASE_URL}approvals/search/${id}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((response) => {
          setBookingWiseFileList(response.data);
          // ==== sent data to table arrange ==== /
        })
        .catch((error) => {
          console.error(error);
        });
    }
    handleShow();
  };

  const modelclearandClose = () => {
    setBookingWiseFileList([]);
    handleClose();
  }

  const handleSave = async (event) => {
    event.preventDefault();
    if (selectedRows === "") {
      toast.error("Please Select Booking", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log(selectedRows);
      const datalist = [];
      selectedRows.forEach((bookingItem) => {
        const bookingItems = {
          booking_header_id: bookingItem,
          approval_status: 1,
        }
        datalist.push(bookingItems);
      });

      const selectedBooking = {
        "form_id": props.selectedAuthority.form_id,
        "form_name": props.selectedAuthority.form_name,
        booking_id_values: datalist,
      };

      console.log(selectedBooking);

      axios
        .post(
          `${BASE_URL}booking_approvals/approve`, JSON.stringify(selectedBooking, null, 2),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          window.location.reload(); // Reload the page after deletion
        })
        .catch((error) => {
          console.error(error);
          alert(error);
          console.log(datalist);
        })
    }
  };

  const handleReject = async (event) => {
    event.preventDefault();
    if (selectedRows === "") {
      toast.error("Please Select Booking", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      console.log(selectedRows);
      const datalist = [];
      selectedRows.forEach((bookingItem) => {
        const bookingItems = {
          booking_header_id: bookingItem,
          approval_status: 2,
        }
        datalist.push(bookingItems);
      });

      const selectedBooking = {
        booking_id_values: datalist,
      };

      axios
        .post(
          `${BASE_URL}booking_approvals/approve`, JSON.stringify(selectedBooking, null, 2),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              window.location.reload(); // Reload the page after deletion
            }, 2000);
          }
        })
        .catch((error) => {
          console.error(error);
          alert(error);
          console.log(datalist);
        })
    }
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <div>
          <Modal show={show} onHide={handleClose} size="md">
            <h5 className="page-name p-1 ms-2">{currentBookingNo} Attachments</h5>
            {bookingWiseFileList !== undefined ? (
              bookingWiseFileList.map((fileItem, index) => (
                <div className="mb-2" key={fileItem.id}>
                  <a href={`${DOC_Path}${currentBookingNo}/${fileItem}`} target="_blank" rel="noreferrer">
                    {/* <a href={`https://demomol.evolve-demo.com/MOL_DEVELOPMENT/storage/app/${currentBookingNo}/${fileItem}`} target="_blank" rel=""> */}
                    <h6 className="ms-2 ">{index + 1 + ")"} {fileItem}</h6>
                  </a>
                </div>
              ))
            ) : (
              // Loader component
              <div className="loader">
                <h5 className="ms-3">Attachments not uploaded</h5>
              </div>
            )}
            <Modal.Footer>
              <Button variant="secondary" onClick={modelclearandClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Table className="mt-2" responsive hover size="sm" striped="columns">
          <thead>
            <tr>
              {columns !== null
                ? headercolumns.map((column) => (
                  <th className="text-center" key={column.booking_header_id}>
                    {column}
                  </th>
                ))
                : ""}
            </tr>
          </thead>
          <tbody>
            {currentItems !== undefined ? (
              currentItems.map((row, index) => (
                // <tr key={row.booking_header_id} onClick={() => setSelectedRow(index,row)}>
                <tr key={row.booking_header_id}>
                  {columns.map((column) => (
                    <td className="text-center " key={column}>
                      {row[column]}
                    </td>
                  ))}
                  <td className="text-center">
                    <input
                      inline
                      label="1"
                      name="group1"
                      type={"checkbox"}
                      className="text-center"
                      onChange={(event) => handleCheckboxChange(event, row.booking_header_id)}
                    />
                  </td>
                  <td className="text-center">
                    <Button className="ms-3" variant="primary" type="submit" onClick={(event) => viewBooking(row.booking_header_id)}>
                      View
                    </Button>
                  </td>
                  <td>
                    <Button className="ms-3" variant="primary" type="submit" onClick={(event) => viewBookingDocs(row.booking_header_id, row.booking_number)}>
                      View Document
                    </Button>
                  </td>
                  <td>
                    <input type="text"></input>
                  </td>
                </tr>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </tbody>

        </Table>
        <ReactPaginate
          onPageChange={paginate}
          pageCount={bookingList !== undefined ? Math.ceil(bookingList.length / postsPerPage) : ""}
          previousLabel={"Prev"}
          nextLabel={"Next"}
          containerClassName={"pagination"}
          pageLinkClassName={"page-number"}
          previousLinkClassName={"page-number"}
          nextLinkClassName={"page-number"}
          activeLinkClassName={"active"}
        />
      </Row>
      <Row className="text-center">
        <Col className="text-end">
          {props.selectedAuthority && props.selectedAuthority.approve === 1 ?
            (<> <Button className="ms-3 p-2" variant="primary" onClick={handleSave}>
              Approve
            </Button>
              <Button className="ms-3 p-2" variant="primary" onClick={handleReject}>
                Reject
              </Button></>) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default BookingApproval;