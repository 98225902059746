import Table from "react-bootstrap/Table";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

const TableView = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (props.data) {
      // Initialize input values for each row and column
      const initialInputValues = {};
      props.data.forEach((row) => {
        initialInputValues[row.id] = {};
        props.columns.forEach((column) => {
          initialInputValues[row.id][column] = row[column] || '';
        });
      });
      setInputValues(initialInputValues);
    }
  }, [props.data, props.columns]);

  if (!props.data) {
    return <div>Loading...</div>;
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentItems = props.data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleChange = (e, rowId, column) => {
    const { value } = e.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [rowId]: {
        ...prevValues[rowId],
        [column]: value,
      },
    }));
  };

  return (
    <div>
      <Table responsive hover size="sm" striped="columns">
        <thead>
          <tr>
            {props.tTitle
              ? props.tTitle.map((column, index) => (
                <th className="text-center text-trans-cap" key={index}>
                  {column}
                </th>
              ))
              : props.columns.map((column, index) => (
                <th className="text-center text-trans-cap" key={index}>
                  {column}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((row) => (
            <tr key={row.id} onClick={() => props.setSelectedRow(row.id)}>
              {props.columns.map((column, index) => (
                <td className="text-center" key={index}>
                  {row[column]}
                  {/* <input
                    type="text"
                    value={inputValues[row.id] ? inputValues[row.id][column] : ''}
                    onChange={(e) => handleChange(e, row.id, column)}
                  /> */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        onPageChange={paginate}
        pageCount={Math.ceil(props.data.length / postsPerPage)}
        previousLabel={"Prev"}
        nextLabel={"Next"}
        containerClassName={"pagination"}
        pageLinkClassName={"page-number"}
        previousLinkClassName={"page-number"}
        nextLinkClassName={"page-number"}
        activeLinkClassName={"active"}
      />
    </div>
  );
};

export default TableView;
