import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../../config";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SelectDropdown from "../../SelectDropdown";

const BookingInfo = (props) => {
  const storedToken = Cookies.get("authToken");
  const [authtoken, setAuthToken] = useState(storedToken || "");


  const passBookingNUM = (selectedBookingNo) => {
    props.onhandleBookingNunber(selectedBookingNo.value);
  };

  const bookingNoOptions = props.pageData?.booking_data !== undefined ?
    props.pageData.booking_data.map((bookinNoOptionItem) => ({
      value: bookinNoOptionItem.id,
      label: bookinNoOptionItem.booking_number,
    })) : [];

  const tranModeList = props.pageData?.transmode_data !== undefined ?
    props.pageData.transmode_data.map((transItem) => ({
      value: transItem.id,
      label: transItem.transport_mode_description,
    })) : [];

  const jobTypesOptions = props.pageData?.job_types_data !== undefined ?
    props.pageData.job_types_data.map((jobTypeOptionItem) => ({
      value: jobTypeOptionItem.id,
      label: jobTypeOptionItem.job_description,
    })) : [];

  const incotermsOptions = props.pageData?.incoterms_data !== undefined ?
    props.pageData.incoterms_data.map((incotermsOptionItem) => ({
      value: incotermsOptionItem.id,
      label: incotermsOptionItem.term_description,
    })) : [];

  const polOptions = props.pageData?.ports_data !== undefined ?
    props.pageData.ports_data.map((polOptionItem) => ({
      value: polOptionItem.id,
      label: polOptionItem.port_description,
    })) : [];

  const vesselOptions = props.pageData?.vessels_data !== undefined ?
    props.pageData.vessels_data.map((vesselOptionItem) => ({
      value: vesselOptionItem.id,
      label: vesselOptionItem.vessel_description,
    })) : [];

  const voyageOptions = props.pageData?.voyage_data !== undefined ?
    props.pageData.voyage_data.map((voyageOptionItem) => ({
      value: voyageOptionItem.id,
      label: voyageOptionItem.voyage_description,
    })) : [];

  const selectedVoyageOptions = props.pageData?.voyage_data !== undefined
    ? props.pageData.voyage_data.map((voyageOptionItem) => {
      if (props.pageValuesData?.vessel_id === voyageOptionItem.vessel_id) {
        return {
          value: voyageOptionItem.id,
          label: voyageOptionItem.voyage_description,
        };
      } else {
        return null; // or undefined, if you want to skip this item
      }
    }).filter((item) => item !== null) // Remove null values if necessary
    : [];

  const salesPersonOptions = props.pageData?.sales_persons_data !== undefined ?
    props.pageData?.sales_persons_data.map((salesPersonOptionItem) => ({
      value: salesPersonOptionItem.id,
      label: salesPersonOptionItem.sales_person_name,
    })) : [];

  return (
    <div className="d-flex flex-column form-box">
      <Row className="mb-1">
        <Col>
          <Form.Group  >
            <Form.Label>Booking No {props.newBookingMode}</Form.Label>
            <SelectDropdown
              options={bookingNoOptions}
              onChange={(e) => passBookingNUM(e)}
              disabled={props.newBookingMode}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Transport Mode <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={tranModeList}
              onChange={(event) => props.handleDropdownValues(event, "transport_mode_id")}
              value={
                props.pageValuesData?.transport_mode_id !== undefined
                  ? tranModeList.find(option => option.value === props.pageValuesData.transport_mode_id)
                  : null
              }
              clName={props.validateFailedInputsList.includes("transport_mode_id") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Booking Date <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              name="booking_date"
              className={props.validateFailedInputsList.includes("booking_date") ? "border-red2" : ""}
              value={props.pageValuesData?.booking_date?.split(' ')[0] || ''}
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Type of Job <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={jobTypesOptions}
              onChange={(event) => props.handleDropdownValues(event, "job_type_id")}
              value={
                props.pageValuesData?.job_type_id !== undefined !== ""
                  ? jobTypesOptions.find(option => option.value === props.pageValuesData?.job_type_id)
                  : null
              }
              clName={props.validateFailedInputsList.includes("job_type_id") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Incoterm <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={incotermsOptions}
              onChange={(event) => props.handleDropdownValues(event, "incoterm_id")}
              value={
                props.pageValuesData?.incoterm_id !== undefined
                  ? incotermsOptions.find(option => option.value === props.pageValuesData?.incoterm_id)
                  : null
              }
              clName={props.validateFailedInputsList.includes("incoterm_id") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Form.Group  >
            <Form.Label>Total Volume <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={props.pageValuesData?.total_volume}
              className={props.validateFailedInputsList.includes("total_volume") ? "border-red2" : ""}
              name="total_volume"
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>P O L <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={polOptions}
              onChange={(event) => props.handleDropdownValues(event, "port_of_loading_id")}
              clName={props.validateFailedInputsList.includes("port_of_loading_id") ? "border-red2" : ""}
              value={
                props.pageValuesData?.port_of_loading_id !== undefined ?
                  polOptions.find(option => option.value === props.pageValuesData?.port_of_loading_id) : null
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>P O D <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={polOptions}
              onChange={(event) => props.handleDropdownValues(event, "port_of_delivery_id")}
              clName={props.validateFailedInputsList.includes("port_of_delivery_id") ? "border-red2" : ""}
              value={
                props.pageValuesData?.port_of_delivery_id !== undefined ?
                  polOptions.find(option => option.value === props.pageValuesData?.port_of_delivery_id) : null
              }

            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>ETA/ETD <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              value={props.pageValuesData?.estimated_time_of_arrival_and_departure?.split(' ')[0] || ''}
              name="estimated_time_of_arrival_and_departure"
              className={props.validateFailedInputsList.includes("estimated_time_of_arrival_and_departure") ? "border-red2" : ""}
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>
              Vessel <span className="RQField">*</span>
            </Form.Label>
            <SelectDropdown
              options={vesselOptions}
              onChange={(event) => (props.handleDropdownValues(event, "vessel_id"))}
              value={
                props.pageValuesData?.vessel_id !== undefined ?
                  vesselOptions.find(option => option.value === props.pageValuesData?.vessel_id) : null
              }
              clName={props.validateFailedInputsList.includes("vessel_id") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Form.Group  >
            <Form.Label>Voyage <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={props.pageValuesData?.vessel_id ? selectedVoyageOptions : voyageOptions}
              onChange={(event) => (props.handleDropdownValues(event, "voyage_id"))}
              value={
                props.pageValuesData?.voyage_id !== undefined ?
                  voyageOptions.find(option => option.value === props.pageValuesData?.voyage_id) : null
              }
              clName={props.validateFailedInputsList.includes("voyage_id") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Remark <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={props.pageValuesData?.remark}
              className={props.validateFailedInputsList.includes("remark") ? "border-red2" : ""}
              name="remark"
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Carrier Code <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={props.pageValuesData?.carrier_code}
              className={props.validateFailedInputsList.includes("carrier_code") ? "border-red2" : ""}
              name="carrier_code"
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>BL Number <span className="RQField">*</span></Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={props.pageValuesData?.bill_number}
              className={props.validateFailedInputsList.includes("bill_number") ? "border-red2" : ""}
              name="bill_number"
              onChange={props.hadleInputValues}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group  >
            <Form.Label>Sales Person <span className="RQField">*</span></Form.Label>
            <SelectDropdown
              options={salesPersonOptions}
              onChange={(event) => (props.handleDropdownValues(event, "sales_person_id"))}
              clName={props.validateFailedInputsList.includes("sales_person_id") ? "border-red2" : ""}
              value={
                props.pageValuesData?.sales_person_id !== undefined ?
                  salesPersonOptions.find(option => option.value === props.pageValuesData?.sales_person_id)
                  : null
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Form.Label>Shipment Type <span className="RQField">*</span></Form.Label>
        <Col>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="FCL"
              checked={props.pageValuesData?.shipment_type === "FCL" ? true : false}
              onChange={(event) => (props.hadleCheckboxValues(event, "FCL"))}
              className={props.validateFailedInputsList.includes("shipment_type") ? "border-red2" : ""}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="" controlId="formBasicCheckbox" >
            <Form.Check
              className={props.validateFailedInputsList.includes("shipment_type") ? "border-red2" : ""}
              type="checkbox"
              label="LCL"
              checked={props.pageValuesData?.shipment_type === "LCL" ? true : false}
              onChange={(event) => (props.hadleCheckboxValues(event, "LCL"))}

            />
          </Form.Group>
        </Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>

    </div>
  );
};

export default BookingInfo;
