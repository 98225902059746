import React, { useState, useEffect, Component } from "react";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import SelectDropdown from "../SelectDropdown";
import BillTable from "../BillTableView";

const ReportModel = (props) => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [customerList, setCustomerList] = useState(undefined);
    const [jobTypeList, setjobTypeList] = useState(undefined);
    const [vesselList, setVesselList] = useState(undefined);
    const [voyageList, setVoyageList] = useState(undefined);
    const [creditNote, setCreditNote] = useState(undefined);
    const [bookingNumberList, setBookingNumberList] = useState();
    const [invoiceAmount, setInvoiceAmount] = useState("");
    const [creaditNoteInvoiceNumber, setCreaditNoteInvoiceNumber] = useState("");
    const [years, setYears] = useState([]);
    const headercolumns = [
        "Invoice Number",
        "Booking Number",
        "Booking Date",
        "Customer Name",
        "Rate Description",
        "Cost Exchange Rate",
        "Cost USD Amount",
        "Cost Exchange Rate",
        "Cost LKR Amount",
        "Revenue USD Amount",
        "Revenue Exchange Rate",
        "Revenue LKR Amount",
    ];
    const columns = [
        "invoice_number",
        "booking_number",
        "booking_date",
        "customer_name",
        "rate_description",
        "cost_exchange_rate",
        "cost_USD_amount",
        "cost_exchange_rate",
        "cost_LKR_amount",
        "revenue_USD_amount",
        "revenue_exchange_rate",
        "revenue_LKR_amount",
    ];




    const tranModeList = [{ value: 1, label: 'SEA' }, { value: 2, label: 'AIR' }, { value: 3, label: 'LAND' }];

    useEffect(() => {
        fetch(`${BASE_URL}customers/dropdown`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCustomerList(data.customers);
            })
            .catch((error) => {
                alert(error());
            });
    }, [authtoken]);

    useEffect(() => {
        fetch(`${BASE_URL}job_types/dropdown`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setjobTypeList(data.job_types);
            })
            .catch((error) => {
                alert(error());
            });
    }, [authtoken]);

    useEffect(() => {
        fetch(`${BASE_URL}vessels/dropdown`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setVesselList(data.vessels);
            })
            .catch((error) => {
                alert(error());
            });
    }, [authtoken]);

    useEffect(() => {
        fetch(`${BASE_URL}voyages/dropdown`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setVoyageList(data);

            })
            .catch((error) => {
                alert(error());
            });
    }, [authtoken]);

    useEffect(() => {
        fetch(`${BASE_URL}credit_note/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authtoken}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCreditNote(data.credit_note);

            })
            .catch((error) => {
                alert(error());
            });
    }, [authtoken]);

    useEffect(() => {
        const styear = 2018;
        const endyear = new Date().getFullYear();;
        const yearsList = []
        for (let year = styear; year <= endyear; year++) {
            yearsList.push(year)
        }
        setYears(yearsList);
    }, []);


    useEffect(() => {
        if (props.pageValueHadle.year) {


            fetch(`${BASE_URL}job_cost_and_revenue_with_USD/get_filtered_booking_numbers?year=${props.pageValueHadle.year}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    // setBookingNumberList(data)
                    console.log(data);
                    // Process the retrieved 'data' here
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                    // Handle errors appropriately (e.g., display a message to the user)
                });
        }
    }, [authtoken, props.pageValueHadle.year]);

    useEffect(() => {
        if (props.yearsWiseBookingHeaders) {
            setBookingNumberList(props.yearsWiseBookingHeaders)
        }
    }, [props.yearsWiseBookingHeaders])

    const BookingSelect = (e) => {
        if (e.value) {
            const bHeaderID = e.value;

            fetch(`${BASE_URL}job_cost_and_revenue_with_USD/print?booking_header_id=${e.value}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log(data);

                    // Process the retrieved 'data' here
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                    // Handle errors appropriately (e.g., display a message to the user)
                });
        }
    }


    const customersOptions = customerList ?
        customerList.map((customerOptionItem) => ({
            value: customerOptionItem.id,
            label: customerOptionItem.customer_name,
        })) : [];

    const jobTypeOptions = customerList ?
        jobTypeList.map((jobOptionItem) => ({
            value: jobOptionItem.id,
            label: jobOptionItem.job_description,
        })) : [];

    const vesselOptions = vesselList ?
        vesselList.map((vesselOptionItem) => ({
            value: vesselOptionItem.id,
            label: vesselOptionItem.vessel_description,
        })) : [];

    const selectedVoyageOptions = voyageList
        ? voyageList.map((voyageOptionItem) => {
            if (props.pageValuesData.vessel_id === voyageOptionItem.vessels_id) {
                return {
                    value: voyageOptionItem.voyages_id,
                    label: voyageOptionItem.voyage_description,
                };
            } else {
                return null; // or undefined, if you want to skip this item
            }
        }).filter((item) => item !== null) // Remove null values if necessary
        : [];

    const creditnoteOptions = creditNote ?
        creditNote.map((creditnoteOptionItem) => ({
            value: creditnoteOptionItem.credit_note_id,
            label: creditnoteOptionItem.credit_note_number,
        })) : [];

    const yearsOptions = years ?
        years.map((year) => ({
            value: year,
            label: year,
        })) : [];

    const yearWiseBookingNumbers = bookingNumberList ?
        bookingNumberList.map((bookingnumberOptionItem) => ({
            value: bookingnumberOptionItem.booking_header_id,
            label: bookingnumberOptionItem.booking_number,
        })) : [];



    let currentRow = [];
    const displayItem = [];
    const collemPerRow = 2;

    const renderSelectDropdown = (fieldsItem, options, clName) => {
        if (fieldsItem.fieldsType === "SelectDropdown") {
            return (
                <SelectDropdown
                    name={fieldsItem.name}
                    options={options}
                    clName={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                    onChange={props.dropDownValueHadle}
                />
            );
        }
        return null;
    };

    const renderSelectDropdownyear = (fieldsItem, options, clName) => {
        if (fieldsItem.fieldsType === "SelectDropdown") {
            return (
                <SelectDropdown
                    name={fieldsItem.name}
                    options={options}
                    clName={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : "test"}
                    onChange={props.dropDownValueHadle}
                />
            );
        }
        return null;
    };

    const renderbookingNumberWiseDataSelectDropdown = (fieldsItem, options, clName) => {
        if (fieldsItem.fieldsType === "SelectDropdown") {
            return (
                <SelectDropdown
                    name={fieldsItem.name}
                    options={options}
                    clName={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : "test"}
                    onChange={BookingSelect}
                />
            );
        }
        return null;
    };

    useEffect(() => {
        if (props.pageValuesData.credit_note_id !== undefined) {
            const foundItem = creditNote.find(item => item.credit_note_id === props.pageValuesData.credit_note_id);
            const invoiceAmount = foundItem ? foundItem.invoice_amount : '';
            const CreaditNoteInvoiceNumber = foundItem ? foundItem.invoice_number : '';
            setInvoiceAmount(invoiceAmount);
            setCreaditNoteInvoiceNumber(CreaditNoteInvoiceNumber);
        }
    }, [props.pageValuesData.credit_note_id, creditNote]); // Add any other dependencies as needed

    if (props.currentReportData.formfields !== undefined) {
        props.currentReportData.formfields.forEach((fieldsItem, index) => {
            currentRow.push(
                <Col key={index}>
                    <Form.Group className="mb-3" controlId={`formGroup_${index}`}>
                        {fieldsItem.fieldsType !== "checkbox" && fieldsItem.fieldsType !== "radio" ? <Form.Label>{fieldsItem.fieldsLabel}</Form.Label> : ""}

                        {fieldsItem.fieldsType === "date" ?
                            <Form.Control
                                type={fieldsItem.fieldsType}
                                placeholder={fieldsItem.placeholder}
                                name={fieldsItem.name}
                                onChange={props.pageValueHadle}
                                className={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                            />
                            : ""}
                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "customer_id" ?
                            renderSelectDropdown(fieldsItem, customersOptions, "customer_id")
                            : ""}
                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "job_type_id" ?
                            renderSelectDropdown(fieldsItem, jobTypeOptions, "job_type_id")
                            : ""}

                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "transport_mode_id" ?
                            renderSelectDropdown(fieldsItem, tranModeList, "transport_mode_id")
                            : ""}

                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "vessel_id" ?
                            renderSelectDropdown(fieldsItem, vesselOptions, "vessel_id")
                            : ""}

                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "voyage_id" ?
                            renderSelectDropdown(fieldsItem, selectedVoyageOptions, "voyage_id")
                            : ""}

                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "credit_note_id" ?
                            renderSelectDropdown(fieldsItem, creditnoteOptions, "credit_note_id")
                            : ""}
                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "year" ?
                            renderSelectDropdown(fieldsItem, yearsOptions, "year")
                            : ""}
                        {fieldsItem.fieldsType === "SelectDropdown" && fieldsItem.name === "booking_header_id" ?
                            renderSelectDropdown(fieldsItem, yearWiseBookingNumbers, "booking_header_id")
                            : ""}

                        {fieldsItem.fieldsType === "checkbox" ?
                            <Form.Check
                                type="checkbox"
                                placeholder={fieldsItem.placeholder}
                                name={fieldsItem.name}
                                label={fieldsItem.fieldsLabel}
                                onChange={props.pageValueHadle}
                                className={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                            />
                            : ""}

                        {fieldsItem.fieldsType === "radio" ?
                            <Form.Check
                                type="radio"
                                label={fieldsItem.fieldsLabel}
                                placeholder={fieldsItem.placeholder}
                                name={fieldsItem.name}
                                onChange={() => props.handleRadioVal(fieldsItem.fieldsLabel)}
                                className={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                            />
                            : ""}

                        {fieldsItem.fieldsType === "Input" && fieldsItem.name === "invoice_number" ?
                            <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="off"
                                disabled="true"
                                name={fieldsItem.name}
                                className={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                                value={creaditNoteInvoiceNumber !== "" ? creaditNoteInvoiceNumber : ""}
                            // onChange={props.pageValueHadle}
                            />
                            : ""}

                        {fieldsItem.fieldsType === "Input" && fieldsItem.name === "invoice_amount" ?
                            <Form.Control
                                type="text"
                                placeholder=""
                                autoComplete="off"
                                name={fieldsItem.name}
                                disabled="true"
                                className={props.validateFailedInputsList.includes(fieldsItem.name) ? "border-red2" : ""}
                                value={invoiceAmount !== "" ? invoiceAmount : ""}
                            // onChange={props.pageValueHadle}
                            />
                            : ""}

                    </Form.Group>
                </Col>
            );
            if (currentRow.length === collemPerRow || index === props.currentReportData.formfields.length - 1) {
                displayItem.push(<Row>{currentRow}</Row>);
                currentRow = []
            }

        });
    } else {
        displayItem.push(<h4 style={{ color: 'red' }}>Undefined Report Data</h4>);
    }


    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title className="page-name">{props.currentReportData.report_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {displayItem}

                {props.currentReportData.report_name === "Job Cost And Revenue WIth USD" ?
                    <BillTable
                        data={props.selectedBookingData ? props.selectedBookingData : ""}
                        columns={columns}
                        headercolumns={headercolumns}

                    />
                    : ""}

            </Modal.Body>
        </>
    );
};

export default ReportModel;
