import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import TableView from "../../TableView";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectDropdown from "../../SelectDropdown";
import MultiInputs from "../../CommonComponents/MultiInputs";

const AirwayBillFirstPage = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("air_export_billing");
  const [blNumList, setBlNumList] = useState(undefined);
  const [curruncyList, setCurruncyList] = useState(undefined);

  // === Master BL NO === //
  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setBlNumList(data.export_billing);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken, pageComponent]);

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0]; // Formats to yyyy-MM-dd
  };
  const bLNoOptions = blNumList ?
    blNumList.map((bLOptionItem) => ({
      value: bLOptionItem.billing_number_id,
      label: bLOptionItem.master_billing_number,
    })) : [];

  const valueToMsterBLNo = (event) => {
    console.log(event.value);
    props.masterBillNoHadle(event);
    fetch(`${BASE_URL}${pageComponent}/search/${event.value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        props.selectBLDataSet(data.air_export_billing_data);
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    fetch(`${BASE_URL}currency/dropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCurruncyList(data.currencies);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken]);

  const currencyOptions = curruncyList ?
    curruncyList.map((currencyOptionItem) => ({
      value: currencyOptionItem.id,
      label: currencyOptionItem.currency_description,
    })) : [];
  const valueToCurruncyHadle = (event) => {
    props.pageValueHadle(event);
  };

  const handleFlightDateChange = (index, value) => {
    const updatedDates = [...props.pageValuesData.air_export_wise_flight_dates_data];
    updatedDates[index].flight_date = value;
    updatedDates[index].flight_date_1 = props.pageValuesData.air_export_wise_flight_dates_data[index].flight_date_1;
    props.objectDataHadle(updatedDates);
  };

  const handleFlightDate1Change = (index, value) => {
    const updatedDates = [...props.pageValuesData.air_export_wise_flight_dates_data];
    updatedDates[index].flight_date_1 = value;
    updatedDates[index].flight_date = props.pageValuesData.air_export_wise_flight_dates_data[index].flight_date;
    props.objectDataHadle(updatedDates);
  };

  const addNewRow = () => {
    const newDate = {
      flight_date: "",
      flight_date_1: ""
    };
    props.objectDataHadle([...props.pageValuesData.air_export_wise_flight_dates_data, newDate]);
  };

  return (
    <Container fluid style={{ boxShadow: 'none', padding: '0' }}>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Master BL No</Form.Label>
                  <SelectDropdown
                    options={bLNoOptions}
                    onChange={valueToMsterBLNo}
                    name={"master_billing_number_id"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>House BL No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="House BL NO"
                    autoComplete="off"
                    name="house_billing_number"
                    value={props.pageValuesData.house_billing_number ? props.pageValuesData.house_billing_number : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    autoComplete="off"
                    name="billing_date"
                    value={formatDate(props.pageValuesData.billing_date ? props.pageValuesData.billing_date : "")}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label className="">Vessel</Form.Label>
                  <br />
                  <Form.Label className="printted-label">{props.pageValuesData.vessel_code ? props.pageValuesData.vessel_code : ""}</Form.Label>
                  <Form.Label className="printted-label ms-3">
                    {props.pageValuesData.vessel_description ? props.pageValuesData.vessel_description : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label className="">Voyage</Form.Label>
                  <br />
                  <Form.Label className="printted-label">{props.pageValuesData.voyage_code ? props.pageValuesData.voyage_code : ""}</Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.voyage_description ? props.pageValuesData.voyage_description : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label className="">Port of Loading</Form.Label>
                  <br />
                  <Form.Label className="printted-label">{props.pageValuesData.loading_port_code ? props.pageValuesData.loading_port_code : ""} </Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.loading_port_description ? props.pageValuesData.loading_port_description : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label className="">Port of Discharge</Form.Label>
                  <br />
                  <Form.Label className="printted-label">{props.pageValuesData.delivery_port_code ? props.pageValuesData.delivery_port_code : ""} </Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.delivery_port_description ? props.pageValuesData.delivery_port_description : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Shipper Account</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="shipper_account"
                    value={props.pageValuesData.shipper_account ? props.pageValuesData.shipper_account : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Consignee Account</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    name="consignee_account"
                    value={props.pageValuesData.consignee_account ? props.pageValuesData.consignee_account : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Agent's IATA Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="agent_code"
                    value={props.pageValuesData.agent_code ? props.pageValuesData.agent_code : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Account No</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete=""
                    name="account_number"
                    value={props.pageValuesData.account_number ? props.pageValuesData.account_number : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Airport of Destination</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="airport_of_destination"
                    value={props.pageValuesData.airport_of_destination ? props.pageValuesData.airport_of_destination : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Declared Value for Carriage</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="declared_value_for_carriage"
                    value={props.pageValuesData.declared_value_for_carriage ? props.pageValuesData.declared_value_for_carriage : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Declared Value for Customs</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete=""
                    name="declared_value_for_customers"
                    value={props.pageValuesData.declared_value_for_customers ? props.pageValuesData.declared_value_for_customers : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Amount of Insurance</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="amount_of_insurance"
                    value={props.pageValuesData.amount_of_insurance ? props.pageValuesData.amount_of_insurance : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Currency</Form.Label>
                  <SelectDropdown
                    options={currencyOptions}
                    onChange={props.hadleDropdown}
                    value={props.pageValuesData.currency_id !== "" ? currencyOptions.find(option => option.value === props.pageValuesData.currency_id) : null}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Charges Code</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete=""
                    name="ChgsCode"
                    value={props.pageValuesData.ChgsCode ? props.pageValuesData.ChgsCode : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>PDD</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="WTVALPPD"
                    value={props.pageValuesData.WTVALPPD ? props.pageValuesData.WTVALPPD : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col className="text-center">
                {" "}
                <Form.Label>WT/VAT</Form.Label>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>COLL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="WTVAColl"
                    value={props.pageValuesData.WTVAColl ? props.pageValuesData.WTVAColl : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>PPD</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="OtherPPD"
                    value={props.pageValuesData.OtherPPD ? props.pageValuesData.OtherPPD : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
              <Col className="text-center">
                {" "}
                <Form.Label>Other</Form.Label>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>COLL</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="OtherColl"
                    value={props.pageValuesData.OtherColl ? props.pageValuesData.OtherColl : ""}
                    onChange={props.pageValueHadle}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Shipper</Form.Label>
                  <MultiInputs nuOfInputs={5} dataObject={props.pageValuesData} inputBaseName="shipper_" onChangeFunction={props.pageValueHadle} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Consignee</Form.Label>
                  <MultiInputs nuOfInputs={5} dataObject={props.pageValuesData} inputBaseName="consignee_" onChangeFunction={props.pageValueHadle} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Notify Party</Form.Label>
                  <MultiInputs nuOfInputs={6} dataObject={props.pageValuesData} inputBaseName="notify_party_" onChangeFunction={props.pageValueHadle} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label> Handling Information</Form.Label>
                  <MultiInputs nuOfInputs={3} dataObject={props.pageValuesData} inputBaseName="hand_information_" onChangeFunction={props.pageValueHadle} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_1"
                        value={props.pageValuesData.airline_code_1 ? props.pageValuesData.airline_code_1 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_2"
                        value={props.pageValuesData.airline_code_2 ? props.pageValuesData.airline_code_2 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>By</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_by_1"
                        value={props.pageValuesData.airline_code_by_1 ? props.pageValuesData.airline_code_by_1 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>To</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_3"
                        value={props.pageValuesData.airline_code_3 ? props.pageValuesData.airline_code_3 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>By</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_by_2"
                        value={props.pageValuesData.airline_code_by_2 ? props.pageValuesData.airline_code_by_2 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>For Carrier Use Only</Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Declared Value For Carriage</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="declared_value_for_carriage"
                        value={props.pageValuesData.declared_value_for_carriage ? props.pageValuesData.declared_value_for_carriage : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Declared Value For Customers</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="declared_value_for_customers"
                        value={props.pageValuesData.declared_value_for_customers ? props.pageValuesData.declared_value_for_customers : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Amount of Insurance</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        autoComplete="off"
                        name="airline_code_by_1"
                        value={props.pageValuesData.airline_code_by_1 ? props.pageValuesData.airline_code_by_1 : ""}
                        onChange={props.pageValueHadle}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                {props.pageValuesData.air_export_wise_flight_dates_data.map((date, index) => (
                  <Row key={index}>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Flight Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoComplete="off"
                          name="flight_date"
                          value={date.flight_date}
                          onChange={(e) => handleFlightDateChange(index, e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Flight Date 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          autoComplete="off"
                          name="flight_date_1"
                          value={date.flight_date_1}
                          onChange={(e) => handleFlightDate1Change(index, e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ))}
                <Button onClick={addNewRow} className="mb-2">Add New Row</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AirwayBillFirstPage;
