import React, { useState, useEffect, Component } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApprovedBookingsInvoices = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("export_billing");
  const [pageValuesData, setpageValuesData] = useState({ from_date: "", to_date: "" });
  const [feildValidate, setFeildValidate] = useState(true);
  const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["from_date", "to_date"]);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([""]);

  const pageValueHadle = (e) => {
    const { name, value } = e.target;
    setpageValuesData({ ...pageValuesData, [name]: value });
  };

  const validateEmptyField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setFeildValidate(true);
    }
    return true;
  };

  const validateFeild = () => {
    const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
    const newvalidateFailedInputsList = [];
    for (const key in pageValuesData) {
      const value = pageValuesData[key];
      if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
        newvalidateFailedInputsList.push(key);
        const keyWord = key.split('_');
        const capitalizeKeyWords = keyWord.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const labelText = capitalizeKeyWords.join(' ');
        validateEmptyField(value, labelText);
      }
      setValidateFailedInputsList(newvalidateFailedInputsList);
    }
    if (pageValuesData.from_date > pageValuesData.to_date) {
      toast.error('Invalid date range', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {

      const queryString = Object.keys(pageValuesData)
        .map((key) => `${key}=${pageValuesData[key]}`)
        .join("&");

      const url = `${BASE_URL}approvals/print?${queryString}`;
      axios
        .get(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((res) => {
          // Create a blob URL from the response data
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = window.URL.createObjectURL(blob);

          // Open the PDF URL in a new tab
          window.open(pdfUrl);

          // Release the object URL when done to free up resources
          window.URL.revokeObjectURL(pdfUrl);

          // toast.suc[cess("Downloading file", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    }
  }

  return (
    <Container fluid className="pt-3">
      <ToastContainer autoClose={2000} />
      <Row className="d-flex align-items-center mx-auto mb-2 mt-2">
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>From Date</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              autoComplete="off"
              name="from_date"
              value={pageValuesData.from_date ? pageValuesData.from_date : ""}
              onChange={pageValueHadle}
              className={validateFailedInputsList.includes("from_date") ? "border-red2" : ""}
            />
          </Form.Group>

        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>To  Date</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              autoComplete="off"
              name="to_date"
              value={pageValuesData.to_date ? pageValuesData.to_date : ""}
              onChange={pageValueHadle}
              className={validateFailedInputsList.includes("to_date") ? "border-red2" : ""}
            />
          </Form.Group>

        </Col>
      </Row>
      <Row className="text-center">
        <Col className="text-end">
          {/* <Button
            className="ms-3"
            variant="primary"
            onClick={datackeck}
          >
            Data check
          </Button> */}
          {props.selectedAuthority && props.selectedAuthority.print === 1 ?
            (<Button className="ms-3" variant="primary" onClick={validateFeild}>
              Print
            </Button>) : null}
          {/* <Button className="ms-3" variant="primary" type="submit">
            Cancel
          </Button> */}
        </Col>
      </Row>
    </Container>
  );
}

export default ApprovedBookingsInvoices;