import React, { useState, useEffect, Component } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import BASE_URL from '../config';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import TableView from '../TableView';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectDropdown from '../SelectDropdown';
import ReusableButtonComponent from '../CommonComponents/ReusableButtonComponent';

const Supplier = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState('suppliers');
  const [data, setData] = useState(undefined);
  const [paytermList, setPaytermList] = useState(undefined);
  const [newItemMode, setNewItemMode] = useState(false);
  const [currentSupplier, setCurrentSupplier] = useState(undefined);
  const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["county", "state", "postal_code", "city", "contact_person", "email", "supplier_name", "supplier_code", "short_name", "telephone_1", "telephone_2", "contact_telephone", "GL_account", "pay_term"]);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
  const [feildValidate, setFeildValidate] = useState(true);

  const [refresh, setRefresh] = useState(false);

  const PageinitialValues = {
    supplier_code: "",
    supplier_name: "",
    short_name: "",
    email: "",
    telephone_1: "",
    telephone_2: "",
    fax: "",
    web: "",
    contact_person: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    address_line_4: "",
    city: "",
    postal_code: "",
    state: "",
    country: "",
    pay_term: "",
    contact_telephone: "",
    GL_account: "",
  }
  function setSelectedRow(rowid) {
    setCurrentSupplier(PageinitialValues);
    axios
      .get(`${BASE_URL}${pageComponent}/single/${rowid}`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      })
      .then((res) => {
        const selectRowData = res.data.supplier;
        setCurrentSupplier({ ...PageinitialValues, ...selectRowData });
        setNewItemMode(false);
      });
  }
  const deleteSupplier = () => {
    if (currentSupplier && currentSupplier.id !== undefined) {
      axios.delete(
        `${BASE_URL}${pageComponent}/destroy/${currentSupplier.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      toast.warning("Successfully Deleted Data Row", {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location.reload();
    } else
      toast.error("Please Select Supplier", {
        position: toast.POSITION.TOP_RIGHT,
      });
  }

  const datackeck = () => {
    console.log(currentSupplier);
  }

  const pageValueHadle = (e) => {
    const patternPhone = /^[0-9+]*$/;
    if (e.target.name.startsWith("telephone") || e.target.name.startsWith("fax") || e.target.name.startsWith("contact_telephone")) {
      if (patternPhone.test(e.target.value)) {
        const { name, value } = e.target;
        setCurrentSupplier({ ...currentSupplier, [name]: value });
      } else {
        toast.error(`Phone numbers should contain only digits (0-9) and symbol (+).`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const { name, value } = e.target;
      setCurrentSupplier({ ...currentSupplier, [name]: value });
    }
  };

  // New Item Mode Hadle

  const createNewRow = () => {
    setNewItemMode(true);
    setCurrentSupplier(PageinitialValues);
  }

  // insert Supplier List

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }).then((response) => response.json())
      .then((data) => {
        setData(data.suppliers);
      })
  }, [authtoken, pageComponent]);

  // === Payment Term === //

  useEffect(() => {
    fetch(`${BASE_URL}customer_payment_terms/dropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPaytermList(data.payment_term);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken]);

  const paytermOptions = paytermList ?
    paytermList.map((payTermOptionItem) => ({
      value: payTermOptionItem.id,
      label: payTermOptionItem.payment_term,

    })) : [];

  const valuePaymentTerm = (event) => {
    setCurrentSupplier({ ...currentSupplier, "payment_terms_to_supplier_id": event.value, "pay_term": event.value });
  }
  const validateEmptyField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setFeildValidate(true);
    }
    return true;
  };
  const handleSave = async (event) => {
    event.preventDefault();
    const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
    const newvalidateFailedInputsList = [];
    for (const key in currentSupplier) {
      const value = currentSupplier[key];
      if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
        newvalidateFailedInputsList.push(key);
        const keyWord = key.split('_');
        const capitalizeKeyWords = keyWord.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const labelText = capitalizeKeyWords.join(' ');
        validateEmptyField(value, labelText);
      }
    }
    setValidateFailedInputsList(newvalidateFailedInputsList);
    if (newvalidateFailedInputsList.length === 0) {
      setFeildValidate(true);
      datapassStage();
    } else {
      console.log("Canot be Empty felids" + newvalidateFailedInputsList.length);
    }
  }
  const datapassStage = () => {
    if (feildValidate === true) {
      if (currentSupplier.id !== undefined) {
        axios
          .post(
            `${BASE_URL}${pageComponent}/update/${currentSupplier.id}`,
            currentSupplier,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          )
          .then((response) => {
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => {
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      } else {
        axios
          .post(`${BASE_URL}${pageComponent}/store`, currentSupplier, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          })
          .then((response) => {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .catch((error) => {
            console.error(error);
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
      }
    }
  }

  const tTitle = ['supplier ID', 'short name', 'supplier name', 'address line 1', 'address line 2', 'address line 3', 'address line 4', 'city', 'state', 'postal code', 'country', 'telephone 1', 'telephone 2', 'fax', 'email', 'web', 'contact person', 'GL account', 'Pay term'];
  const columns = ['id', 'short_name', 'supplier_name', 'address_line_1', 'address_line_2', 'address_line_3', 'address_line_4', 'city', 'state', 'postal_code', 'country', 'telephone_1', 'telephone_2', 'fax', 'email', 'web', 'contact_person', 'GL_account', 'Pay_term'];

  const columnsPerRow = 4; // You can adjust this as needed
  const formInputs = [];
  let currentRow = [];

  Object.keys(PageinitialValues).forEach((inputItem, index) => {
    let currunt_key = inputItem;
    const keyWord = inputItem.split('_');
    const capitalizeKeyWords = keyWord.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const labelText = capitalizeKeyWords.join(' ');
    if (inputItem === "pay_term") {
      currentRow.push(
        <Col key={index}>
          <Form.Group className="mb-3" controlId={`formGroup_${inputItem}`}>
            <Form.Label>{labelText} {cantBeEmptyFeilds.includes(inputItem) ? <span className="RQField">*</span> : ""}</Form.Label>
            <SelectDropdown
              clName={validateFailedInputsList.includes("pay_term") ? "border-red2" : ""}
              options={paytermOptions}
              onChange={valuePaymentTerm}
              value={currentSupplier !== undefined ? paytermOptions.find(option => option.value === currentSupplier.payment_terms_to_supplier_id) : null}
            />
          </Form.Group>
        </Col>
      );
    } else {
      currentRow.push(
        <Col key={index}>
          <Form.Group className="mb-3" controlId={`formGroup_${inputItem}`}>
            <Form.Label>{labelText} {cantBeEmptyFeilds.includes(inputItem) ? <span className="RQField">*</span> : ""} </Form.Label>
            <Form.Control
              type="text"
              name={inputItem}
              placeholder=""
              className={validateFailedInputsList.includes(inputItem) ? "border-red2" : ""}
              value={currentSupplier !== undefined ? currentSupplier[inputItem] !== " " ? currentSupplier[inputItem] : "" : ""}
              onChange={pageValueHadle}
            />
          </Form.Group>
        </Col>
      );

    }

    // If the current row has the desired number of columns or we've reached the end, push it to the rows array
    if (currentRow.length === columnsPerRow || index === Object.keys(PageinitialValues).length - 1) {
      formInputs.push(<Row key={formInputs.length}>{currentRow}</Row>);
      currentRow = [];
    }
  });

  const handleButtonClickPDF = () => {
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(columns) || columns.length === 0) {
      // Handle the scenario where the data or selected keys are invalid or empty
      toast.error("Invalid data or selected keys for export.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const filteredRows = data.map(obj => {
      const filteredRow = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          filteredRow[key] = obj[key];
        }
      });
      return filteredRow;
    });

    const doc = new jsPDF();
    const rows = filteredRows.map(obj => Object.values(obj));

    const columnStyles = {}; // Object to hold column styles

    // Set cell widths (adjust widths as needed)
    columns.forEach(key => {
      columnStyles[key] = { cellWidth: 15 }; // Change width as per your requirement
    });

    doc.autoTable({
      columns: tTitle.map(key => ({ header: key, dataKey: key })),
      body: rows,
      columnStyles,
      startY: 5, // Adjust the starting Y position for the table
      // ... other configuration options
    });

    // Save the PDF file
    const dateTime = new Date().toISOString().replace(/[-T:.Z]/g, '');
    const fileName = `Supplier_PDF_Report_${dateTime}.pdf`;
    doc.save(fileName);
  };

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form className='mt-3 bottom-border pb-3'>
            {formInputs}
            <Row className='text-center mt-3 ms-1'>
              <Col className="p-0">
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-1 ms-1"
                    aria-label="Search"
                  />
                  <Button className="ms-2" variant="primary" type="submit">
                    Search
                  </Button>
                </Form>
              </Col>
              <Col className='text-start'>
                {/* <Button
                                    className="ms-3"
                                    variant="primary"
                                    onClick={datackeck}
                                >
                                    data ckeck
                                </Button> */}
                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="save"
                  label="Save"
                  onClick={handleSave}
                  disabled={newItemMode ? false : true}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="new"
                  label="New"
                  onClick={createNewRow}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="update"
                  label="Update"
                  onClick={handleSave}
                  disabled={newItemMode ? true : false}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="delete"
                  label="Delete"
                  onClick={deleteSupplier}
                  disabled={newItemMode ? true : false}
                />

                <ReusableButtonComponent
                  selectedAuthority={props.selectedAuthority}
                  action="print"
                  label="Print"
                  onClick={handleButtonClickPDF}
                />


                {/* <Button className="ms-2" variant="primary" type="submit">
                                    Cancel
                                </Button> */}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>{refresh && <TableView data={data} columns={columns} tTitle={tTitle} setSelectedRow={setSelectedRow} />}
          <TableView data={data} columns={columns} tTitle={tTitle} setSelectedRow={setSelectedRow} />
        </Col>
      </Row>
    </Container>
  );
}

export default Supplier;