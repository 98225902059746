import React, { useState, useEffect, Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import ExportBLFirstDT from "./ExportBL_include/ExportBLFirstDT";
import ExportBLSecondDT from "./ExportBL_include/ExportBLSecondDT";
import ExportBLAttachedDT from "./ExportBL_include/ExportBLAttachedDT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

const ExportBL = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [pageComponent, setPageComponent] = useState("export_billing");
  const [cantBeEmptyFeilds, setCantBeEmptyFeilds] = useState(["place_of_delivery", "place_of_receipt", "pre_carrage_by", "final_destination", "gross_weight", "number_of_containers_or_packages", "measurement",
    "total_no_of_container_in_words", "shipper_1", "consignee_1", "notify_party_1", "deliver_goods_1", "marks_numbers_2", "package_description_1"]);
  const [newManifestMode, setNewManifestMode] = useState(true);
  const [feildValidate, setFeildValidate] = useState(true);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);

  // === Inputs Valuse Hadle === //
  const firstPageinitialValues = {
    collect: "",
    consignee_1: "",
    consignee_2: "",
    consignee_3: "",
    consignee_4: "",
    consignee_5: "",
    consignee_6: "",
    billing_date: "",
    deliver_goods_1: "",
    deliver_goods_2: "",
    deliver_goods_3: "",
    deliver_goods_4: "",
    deliver_goods_5: "",
    deliver_goods_6: "",
    delivery_port_code: "",
    delivery_port_description: "",
    delivery_port_id: "",
    exchange_rate: "",
    final_destination: "",
    freight_charges: "",
    gross_weight: "",
    house_billing_number: "",
    loading_port_code: "",
    loading_port_description: "",
    loading_port_id: "",
    master_billing_number_id: "",
    marks_numbers_1: "",
    marks_numbers_2: "",
    marks_numbers_3: "",
    marks_numbers_4: "",
    marks_numbers_5: "",
    marks_numbers_6: "",
    marks_numbers_7: "",
    marks_numbers_8: "",
    marks_numbers_9: "",
    marks_numbers_10: "",
    measurement: "",
    notify_party_1: "",
    notify_party_2: "",
    notify_party_3: "",
    notify_party_4: "",
    notify_party_5: "",
    notify_party_6: "",
    number_of_containers_or_packages: "",
    number_of_original_waybill: "",
    package_description_1: "",
    package_description_2: "",
    package_description_3: "",
    package_description_4: "",
    package_description_5: "",
    package_description_6: "",
    package_description_7: "",
    package_description_8: "",
    package_description_9: "",
    package_description_10: "",
    payble_at: "",
    percentage: "",
    place_date_issue: "",
    place_of_delivery: "",
    place_of_receipt: "",
    pre_carrage_by: "",
    prepaid: "",
    prepaid_at: "",
    rate: "",
    revenue_tons: "",
    shipper_1: "",
    shipper_2: "",
    shipper_3: "",
    shipper_4: "",
    shipper_5: "",
    shipper_6: "",
    total_no_of_container_in_words: "",
    total_paid_in_national_currency: "",
    vessel_code: "",
    vessel_description: "",
    vessel_id: "",
    voyage_code: "",
    voyage_description: "",
    voyage_id: "",
    // additional_details: "",
  };
  const newItemMode = () => {
    setNewManifestMode(true);
    setpageValuesData(firstPageinitialValues);
  }
  const [pageValuesData, setpageValuesData] = useState(firstPageinitialValues);

  const pageValueHadle = (e) => {
    const { name, value } = e.target;
    setpageValuesData({ ...pageValuesData, [name]: value });
  };

  const dropdownValueHandle = (key_Name, value) => {
    console.log("Received key_Name:", key_Name);
    console.log("Received value:", value);
    setpageValuesData({ ...pageValuesData, [key_Name]: value });
  };

  const selectBLDataSet = (selectedData) => {
    setpageValuesData({ ...firstPageinitialValues, ...selectedData });
  }

  // === Inputs Valuse Hadle End === //

  const printBillDetails = () => {
    if (pageValuesData.is_export_billing_record === 1 && pageValuesData.master_billing_number_id) {
      const queryParams = { master_billing_number_id: pageValuesData.master_billing_number_id };
      const queryString = new URLSearchParams(queryParams).toString();
      const url = `${BASE_URL}export_billing_detail/print?${queryString}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = window.URL.createObjectURL(blob);
          window.open(pdfUrl);
          window.URL.revokeObjectURL(pdfUrl);
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
          if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
          } else if (error.request) {
            console.error("Request data:", error.request);
          } else {
            console.error("Error message:", error.message);
          }
        });
    } else {
      toast.error("Please select a valid BL Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const printCustomerBillDetails = () => {
    if (pageValuesData.is_export_billing_record) {
      if (pageValuesData.is_export_billing_record === 1) {
        if (pageValuesData.master_billing_number_id !== "") {
          const queryParams = {
            master_billing_number_id: pageValuesData.master_billing_number_id,
          };

          const queryString = Object.keys(queryParams)
            .map((key) => `${key}=${queryParams[key]}`)
            .join("&");

          const url = `${BASE_URL}export_billing/print?${queryString}`;
          axios
            .get(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
              responseType: "blob", // Set the response type to 'blob' to handle binary data
            })
            .then((res) => {
              // Create a blob URL from the response data
              const blob = new Blob([res.data], { type: "application/pdf" });
              const pdfUrl = window.URL.createObjectURL(blob);

              // Open the PDF URL in a new tab
              window.open(pdfUrl);

              // Release the object URL when done to free up resources
              window.URL.revokeObjectURL(pdfUrl);

              // toast.suc[cess("Downloading file", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            })
            .catch((error) => {
              console.error("Error fetching PDF:", error);
            });
        } else {
          toast.error("Please select an BL Number", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        alert("is_export_billing_record not 1");
      }
    } else {
      alert("please Select valid Export BL");
    }
  }

  const validateEmptyField = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'string' && !fieldValue.trim()) {
      setFeildValidate(false);
      toast.error(`${fieldName} can not be empty`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      setFeildValidate(true);
    }
    return true;
  };

  const saveExportBL = () => {

    const isEmpty = (value) => value === null || value === undefined || value === " " || !value;
    const newvalidateFailedInputsList = [];
    for (const key in pageValuesData) {
      const value = pageValuesData[key];
      if (isEmpty(value) && cantBeEmptyFeilds.includes(key)) {
        newvalidateFailedInputsList.push(key);
        const keyWord = key.split('_');
        const capitalizeKeyWords = keyWord.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const labelText = capitalizeKeyWords.join(' ');
        validateEmptyField(value, labelText);
      }
      setValidateFailedInputsList(newvalidateFailedInputsList);
    }

    if (feildValidate === true) {
      const successCallback = () => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      };
      axios
        .post(
          `${BASE_URL}export_billing/store`, pageValuesData,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        )
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            toast.success("Successfully Save Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } successCallback();
        })
        .catch((error) => {
          console.error(error);
          alert(error);
          console.log(pageValuesData);
        });
    }
  };

  const updateEXBill = () => {
    if (pageValuesData.master_billing_number_id !== "") {
      const successCallback = () => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      };
      axios.post(
        `${BASE_URL}export_billing/update/${pageValuesData.master_billing_number_id}`, pageValuesData,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Successfully Updated Data ! ", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } successCallback();
        })
        .catch((error) => {
          console.error(error);
          alert(error);
          console.log(pageValuesData);
        })

    } else {
      alert("please select BL NO")
    }
  }

  const deleteItem = (event) => {
    event.preventDefault();
    if (pageValuesData.export_billing_id) {
      if (pageValuesData.export_billing_id !== "") {
        axios.delete(
          `${BASE_URL}${pageComponent}/destroy/${pageValuesData.export_billing_id}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
        toast.warning("Successfully Deleted Data Row", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // setTimeout(window.location.reload.bind(window.location), 2000);
      } else {
        toast.error("BL Number undefined", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

    } else {
      toast.error("You Can't BL Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Container fluid>
      <ToastContainer autoClose={2000} />
      <Tabs
        defaultActiveKey="home"
        id="justify-tab-example"
        className="mb-3 mt-1 pt-1"
        justify
      >
        <Tab eventKey="home" title="Detail page 1">
          <ExportBLFirstDT
            onpageValueHadleFunction={pageValueHadle}
            selectBLDataSet={selectBLDataSet}
            dropdownValueHandle={dropdownValueHandle}
            pageValuesData={pageValuesData}
            validateFailedInputsList={validateFailedInputsList}
            setNewManifestMode={setNewManifestMode}
          />
        </Tab>
        <Tab eventKey="profile" title="Detail page 2">
          <ExportBLSecondDT
            onpageValueHadleFunction={pageValueHadle}
            validateFailedInputsList={validateFailedInputsList}
            pageValuesData={pageValuesData}
          />
        </Tab>
        <Tab eventKey="" title="Attached Sheet Details">
          <ExportBLAttachedDT
            onpageValueHadleFunction={pageValueHadle}
            pageValuesData={pageValuesData}
          />
        </Tab>
      </Tabs>
      <Row className=" pb-3 mt-2">

        <Col className="text-end">
          {/* <Button className="ms-3" variant="primary" onClick={dataTest}>
                  Data Test
                </Button> */}
          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="save"
            label="Save"
            onClick={saveExportBL}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="new"
            label="New"
            onClick={newItemMode}
            className="ms-3"
            variant="primary"
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="update"
            label="Update"
            onClick={updateEXBill}
            className="ms-3"
            variant="primary"
            disabled={newManifestMode}
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="delete"
            label="Delete"
            onClick={deleteItem}
            className="ms-3"
            variant="primary"
            disabled={newManifestMode}
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="print"
            label="Print"
            onClick={printBillDetails}
            className="ms-3"
            variant="primary"
            disabled={newManifestMode}
          />

          <ReusableButtonComponent
            selectedAuthority={props.selectedAuthority}
            action="customerPrint"
            label="Customer Print"
            onClick={printCustomerBillDetails}
            className="ms-3"
            variant="primary"
            disabled={newManifestMode}
          />

          {/* <Button className="ms-3" variant="primary" type="submit">
                Cancel
              </Button> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ExportBL;