import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Cookies from "js-cookie";
import BASE_URL from './config';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const [show, setShow] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createNewAccount = async (e) => {
    e.preventDefault();
    // Check if any of the input fields are empty
    if (!userCode || !password || !passwordConfirmation) {
      toast.error("Please fill in all fields.");
      return; // Prevent further execution if fields are empty
    } else {
      if (password !== passwordConfirmation) {
        toast.error("Password and password confirmation do not match.");
        return; // Prevent further execution if passwords don't match
      } {

        try {
          const response = await fetch(
            `${BASE_URL}auth/login?email=${userCode}&password=${password}`,
            {
              method: "POST",
            }
          );

          if (response.ok) {
            const responseData = await response.json();
            const authToken = responseData.access_token;

            // Set session expiry time (e.g., 30 minutes from now)
            const sessionExpiry = new Date();
            sessionExpiry.setMinutes(sessionExpiry.getMinutes() + 30);
            Cookies.set("authToken", authToken);
            // Cookies.set("authToken", responseData.access_token);
            Cookies.set("authUser", JSON.stringify(responseData.user));
            Cookies.set("sessionExpiry", sessionExpiry.toISOString()); // Store expiry time
            window.location.href = "/dashboard";
          } else {
            toast.error("Incorrect User Name Or Password", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      };
      toast.success('Successfully Saved Data ! ', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }


  return (
    <>
      <div className="register-link" variant="primary" onClick={handleShow}>
        Create New Account
      </div>

      <Modal show={show} onHide={handleClose}>
        <ToastContainer autoClose={2000} />
        <Modal.Header closeButton>
          <Modal.Title>Create New Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-center">User Code</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password Confirmation</Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={createNewAccount}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Register;
