import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SelectDropdown from "../SelectDropdown";
import bookingSearchMainDroPData from "../data/bookingSearch.json";
import { ToastContainer, toast } from "react-toastify";
import BillTable from "../BillTableView";
import "react-toastify/dist/ReactToastify.css";

const BookingSearch = () => {
    const storedToken = Cookies.get("authToken");
    const storedUser = Cookies.get("authUser");
    const [authtoken, setAuthToken] = useState(storedToken || "");
    const [authUser, setAuthUser] = useState(storedUser || "");
    const [pageComponent, setPageComponent] = useState("search/search_booking_by/");
    const [secondaryDropDownData, setSecondaryDropDownData] = useState([]);
    const [searchData, setSearchData] = useState({});
    const [tableItems, setTableItems] = useState([]);
    const [validateFeildItems, setValidateFeildItems] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null); // Track the selected row

    const serviceOptions = bookingSearchMainDroPData ?
        bookingSearchMainDroPData.map((searchItem) => ({
            value: searchItem.dataId,
            label: searchItem.labelName,
        })) : [];

    const valueServiceOrigin = async (event) => {
        setTableItems([]);
        setSearchData({ ...searchData, SearchBy: event.value });
        if (event.value) {
            try {
                const response = await axios.get(`${BASE_URL}${pageComponent}${event.value}`, {
                    headers: {
                        Authorization: `Bearer ${authtoken}`,
                    },
                });
                setSecondaryDropDownData(response.data.data);
            } catch (error) {
                console.error("Error fetching secondary dropdown data:", error);
                toast.error("Error fetching secondary dropdown data", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const secondDropdownDataCall = (event) => {
        setTableItems([]);
        setSearchData({ ...searchData, id: event.value });
    };

    const secondaryOptions = secondaryDropDownData ?
        secondaryDropDownData.map((secondItem) => ({
            value: secondItem.id,
            label: secondItem.desc,
        })) : [];

    const findData = async () => {
        if (!searchData.SearchBy) {
            toast.error("Please select Search Category", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setValidateFeildItems(prevItems => [...prevItems, "priveDropdown"]);
        } else if (!searchData.id) {
            setValidateFeildItems(prevItems => prevItems.filter(item => item !== "priveDropdown"));
            toast.error("Please select Search Items", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setValidateFeildItems(prevItems => [...prevItems, "secondaryDropdown"]);
        } else {
            setValidateFeildItems(prevItems => prevItems.filter(item => item !== "priveDropdown" && item !== "secondaryDropdown"));

            try {
                const response = await axios.get(`${BASE_URL}search/search_booking_by`, {
                    params: searchData,
                    headers: {
                        Authorization: `Bearer ${authtoken}`,
                    },
                });
                setTableItems(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const headercolumns = [
        "Booking No",
        "Bill No",
        "Total Cost",
        "Total Quote",
        "Profit",
        "Get Report",
        "View Document",
    ];
    const columns = [
        "booking_number",
        "bill_number",
        "total_cost",
        "total_quote",
        "profit",
        "get_report",
        "view_document",
    ];

    const handleGenerateReport = async (row) => {
        try {
            // Prepare the data to be sent to the API
            const requestData = {
                ...row,
            };

            // Make a POST request to generate the report
            const response = await axios.post(`${BASE_URL}search/search_booking_by/get_report`, requestData, {
                headers: {
                    Authorization: `Bearer ${authtoken}`,
                },
                responseType: 'blob', // Handle PDF response as binary data
            });

            // Create a URL for the PDF and open it in a new tab
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Selected_Booking_Details_Report.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error generating report:", error);
            toast.error("Error generating report. Please try again.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <Container fluid>
            <ToastContainer autoClose={2000} />
            <Row>
                <Col>
                    <Form className="mt-3 bottom-border pb-3">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <SelectDropdown
                                        options={serviceOptions}
                                        onChange={valueServiceOrigin}
                                        clName={validateFeildItems.includes("priveDropdown") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className="text-end">
                                <Form.Group className="mb-3 text-start">
                                    <SelectDropdown
                                        options={secondaryOptions}
                                        onChange={secondDropdownDataCall}
                                        clName={validateFeildItems.includes("secondaryDropdown") ? "border-red2" : ""}
                                    />
                                </Form.Group>
                                <Button className="text-right" variant="primary" type="button" onClick={findData}>
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <BillTable
                        data={tableItems}
                        columns={columns}
                        headercolumns={headercolumns}
                        setSelectedRow={setSelectedRow}
                        buttonLabel="Get Report"
                        buttonAction={handleGenerateReport}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default BookingSearch;
