import React from 'react';
import { Button } from 'react-bootstrap';

const ReusableButtonComponent = ({ selectedAuthority, action, label, onClick, disabled }) => {
  if (!selectedAuthority || !selectedAuthority[action] === 1) {
    return null;
  }

  return (
    <Button
      className="ms-3"
      variant="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ReusableButtonComponent;
