import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const NavigationToggle = ({ toggleNavigation }) => {
  return (
    <button
      onClick={toggleNavigation}
      className="toggle-button ms-3 btn btn-primary"
    >
      <FontAwesomeIcon icon={faBars} size="2x" />
    </button>
  );
};

export default NavigationToggle;
