import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SimpleChart from "./SimpleChart";
import TwoLevelPieChart from "./TwoLevelPieChart";
import CustomContentOfTooltip from "./CustomContentOfTooltip";
import LegendEffectOpacity from "./LegendEffectOpacity";

const DashboardComponents = () => {
  return (
    <Container fluid>
      <Row text-center className="mt-2">
        <Col className="chart-box md-9">
          <LegendEffectOpacity />
        </Col>
        <Col className="chart-box md-3">
          <CustomContentOfTooltip />
        </Col>
      </Row>
      <Row text-center>
        <Col xs={3} className="">
          <TwoLevelPieChart />
        </Col>
        <Col xs={9} className="">
          <CustomContentOfTooltip />
        </Col>
      </Row>
      <Row>
        <Col className="mt-3 bg-white p-15 Full-width-chart">
          <SimpleChart />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardComponents;
