import React from "react";
import PropTypes from "prop-types";
import ColumnResizer from 'react-table-column-resizer';
import { Button } from "react-bootstrap";

function BillTable({ data, headercolumns, columns, setSelectedRow, buttonLabel, buttonAction }) {
  const [showFullText, setShowFullText] = React.useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const cellStyle = {
    maxHeight: '20px',
    overflow: 'hidden'
  };

  const amountFormat = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(amount);
  };

  return (
    <div>
      <table className="billTableView" style={{ border: '1px solid #1A4697', borderRadius: '5px' }}>
        <thead>
          <tr>
            {headercolumns?.map((column, index) => (
              <React.Fragment key={index}>
                <th className="text-center" style={{ ...cellStyle, minWidth: '120px', maxWidth: '120px', whiteSpace: 'nowrap', color: '#1A4697', paddingBottom: '1px', borderBottom: '1px solid #1A4697', borderLeft: '1px solid #1A4697', borderRadius: '5px' }}>
                  {column}
                </th>
                <ColumnResizer className="columnResizer" />
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={row.id} onClick={() => setSelectedRow(index, row)}>
              {columns.map((column, colIndex) => (
                <React.Fragment key={colIndex}>
                  <td className="text-center" style={{ ...cellStyle, minWidth: '120px', maxWidth: '120px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', padding: '6px 0', borderBottom: '1px solid #1A4697', borderTop: '1px solid #1A4697', borderRadius: '5px' }}>
                    {column === "get_report" ? (
                      <Button
                        variant="primary"
                        onClick={() => buttonAction(row)}
                      >
                        {buttonLabel}
                      </Button>
                    ) : typeof row[column] === 'number' ? (
                      amountFormat(row[column])
                    ) : (
                      row[column]
                    )}
                  </td>
                  <ColumnResizer className="columnResizer" />
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

BillTable.propTypes = {
  data: PropTypes.array.isRequired,
  headercolumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRow: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
};

export default BillTable;
