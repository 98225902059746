import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import EditableTable from "../../EditableTable";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContainerDetails = (props) => {
    const { manifest_container_data, manifestWiseContainerNumbersDataHadle, pageValuesData, pageValueHadle, manifest_wise_container_numbers_data } = props;

    // State to hold the data to use for the table
    const [dataToUse, setDataToUse] = useState([]);

    // Use useEffect to determine which data to use based on the payload
    useEffect(() => {
        if (Array.isArray(manifest_wise_container_numbers_data) && manifest_wise_container_numbers_data.length > 0) {
            setDataToUse(manifest_wise_container_numbers_data);
        } else {
            setDataToUse(manifest_container_data);
        }
    }, [manifest_container_data, manifest_wise_container_numbers_data]);

    const passContData = () => {
        props.manifestWiseContainerNumbersDataHadle();
        console.log("passContData function is a calling");
    };

    return (
        <Container fluid style={{ boxShadow: 'none', padding: '0' }}>
            <ToastContainer autoClose={2000} />
            <Row className='mt-4'>
                <Col className="mt-4" style={{ overflowX: "scroll" }}>
                    <EditableTable
                        data={dataToUse}
                        manifestWiseContainerNumbersDataHadle={manifestWiseContainerNumbersDataHadle}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Total No of Packages <span className="RQField">*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="total_number_of_packages"
                                        value={pageValuesData.total_number_of_packages || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Total No of M3</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="total_number_of_m3"
                                        value={pageValuesData.total_number_of_m3 || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Kind of Packages</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="kind_of_packages"
                                        value={pageValuesData.kind_of_packages || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label>No of Containers</Form.Label>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>20 FT</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="_20ft"
                                        value={pageValuesData._20ft || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>40 FT</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="_40ft"
                                        value={pageValuesData._40ft || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Over 40 FT</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="over_40ft"
                                        value={pageValuesData.over_40ft || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="MasterBL">
                                    <Form.Label>Carrier Identifier</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="career_identifier"
                                        value={pageValuesData.career_identifier || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>SLPA Reference No for Vessel</Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        placeholder=""
                                        name="slpa_reference_num_for_vessel"
                                        value={pageValuesData.slpa_reference_num_for_vessel || ""}
                                        onChange={pageValueHadle}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ContainerDetails;
