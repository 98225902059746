import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../config";
import "react-toastify/dist/ReactToastify.css";
import Col from "react-bootstrap/Col";
import BookingInfo from "./BookingCosting_include/BookingInfo";
import ContainerInfo from "./BookingCosting_include/ContainerInfo";
import CustomerandSupplierinfo from "./BookingCosting_include/CustomerandSupplierinfo";
import ReusableButtonComponent from "../CommonComponents/ReusableButtonComponent";

axios.interceptors.request.use((config) => {
  config.headers["Access-Control-Allow-Origin"] = "*"; // Replace '*' with the desired domain or configure it dynamically
  config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  config.headers["Access-Control-Allow-Headers"] =
    "Content-Type, Authorization";
  return config;
});

const BookingCosting = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [pageData, setPageData] = useState(undefined);
  const [pageValuesData, setpageValuesData] = useState({
    booking_wise_rates_table_data: [],
    cost: 0.0000, // Set default value for cost
    quote: 0.0000 // Set default value for quote
  });
  const [cantBeEmptyFields, setCantBeEmptyFields] = useState([
    "transport_mode_id",
    "job_type_id",
    "sales_person_id",
    "incoterm_id",
    "vessel_id",
    "voyage_id",
    "port_of_loading_id",
    "port_of_delivery_id",
    "booking_date",
    "carrier_code",
    "bill_number",
    "estimated_time_of_arrival_and_departure",
    "shipment_type",
    "total_volume",
    "total_nbt",
    "total_vat",
    "total_nbt_quote",
    "total_vat_quote",
    "total_cost",
    "total_quote",
    "remark",
    "booking_wise_rates_table_data"
  ]);
  const [validateFailedInputsList, setValidateFailedInputsList] = useState([]);
  const [file, setFile] = useState(null);

  const [jsonData, setJsonData] = useState({});
  const [image, setImage] = useState("");
  const [responseMsg, setResponseMsg] = useState({
    status: "",
    message: "",
    error: "",
  });

  useEffect(() => {
    fetch(`${BASE_URL}booking_costing/alldropdown`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPageData(data);
      })
      .catch((error) => {
        alert(error());
      });
  }, [authtoken]);


  const handleDropdownValues = (event, propertyToUpdate) => {
    if (propertyToUpdate === 'currency_id') {
      const selectedCurrency = pageData.currency_data.find(currency => currency.id === event.value);
      if (selectedCurrency && selectedCurrency.currency_description === 'LKR') {
        setpageValuesData({
          ...pageValuesData,
          [propertyToUpdate]: event.value,
          exchange_rate: 1.0000
        });
      } else {
        setpageValuesData({ ...pageValuesData, [propertyToUpdate]: event.value });
      }
    } else {
      setpageValuesData({ ...pageValuesData, [propertyToUpdate]: event.value });
    }
  };

  const hadleInputValues = (e) => {
    setpageValuesData({ ...pageValuesData, [e.target.name]: e.target.value });
  }

  const hadleCheckboxValues = (event, propertyToUpdate) => {
    if (propertyToUpdate === "FCL" && event.target.checked === true) {
      setpageValuesData({ ...pageValuesData, "shipment_type": "FCL" });
    } else if (propertyToUpdate === "LCL" && event.target.checked === true) {
      setpageValuesData({ ...pageValuesData, "shipment_type": "LCL" });
    } else {
      setpageValuesData({ ...pageValuesData, "shipment_type": "" });
    }

  }

  const containerValueHadle = (booking_wise_containers_table_data) => {
    setpageValuesData({ ...pageValuesData, booking_wise_containers_table_data });
  };

  const handleCon_NumList = (booking_wise_container_numbers_table_data) => {
    setpageValuesData({ ...pageValuesData, booking_wise_container_numbers_table_data });
  };

  const handleConSizeVal = (value) => {
    setConSizeVal(value);
  };

  const handleTableData = async (booking_wise_rates_table_data) => {
    const updatedTableData = booking_wise_rates_table_data.map(data => ({
      ...data,
      cost: data.cost || 0.0000, // Default to 0.0000 if cost is not provided
      quote: data.quote || 0.0000 // Default to 0.0000 if quote is not provided
    }));

    if (pageValuesData && Array.isArray(pageValuesData.booking_wise_rates_table_data)) {
      setpageValuesData((prevData) => ({
        ...prevData,
        booking_wise_rates_table_data: [
          ...(prevData.booking_wise_rates_table_data || []),
          ...updatedTableData,
        ],
      }));
    } else {
      setpageValuesData({ ...pageValuesData, booking_wise_rates_table_data: updatedTableData });
    }
  };

  useEffect(() => {
    // Only call appendCalculationsValues if pageValuesData.booking_wise_rates_table_data changes
    if (pageValuesData.booking_wise_rates_table_data) {
      appendCalculationsValues();
    }
  }, [pageValuesData.booking_wise_rates_table_data]);




  const checkdata = () => {
    console.log(pageValuesData);
    console.log(selectedFiles);
  }

  const [selectedBookingItem, setSelectedBookingItem] = useState("");
  const [bookingNo, setBookingNo] = useState("");
  const [conSizeVal, setConSizeVal] = useState({});
  const [con_NumList, setCon_NumList] = useState([]);
  const [tabledata, setTabledata] = useState([]);


  const [newBookingMode, setNewBookingMode] = useState(false);


  const handleSelectedBooking = async (value) => {
    if (value) {
      await axios
        .get(`${BASE_URL}booking_costing/search/${value}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          const currentCustomizedRowData = [];
          const currentTableDatarow = response.data.booking_wise_rates_table_data.map((tableItem, index) => {
            const currentCustomer = pageData.customers_data.find((item) => item.id === tableItem.customer_id);
            const currentRateName = pageData.rates_data.find((item) => item.id === tableItem.rate_id);
            const currentCurrencyName = pageData.currency_data.find((item) => item.id === tableItem.currency_id);
            const currentSupplier = pageData?.suppliers_data.find((item) => item.id === tableItem.supplier_id);

            const totCost = tableItem.exchange_rate * tableItem.volume * tableItem.cost;
            const totQuote = tableItem.exchange_rate * tableItem.volume * tableItem.quote;
            const totnbt = totCost * tableItem.nbt_percentage;
            const totVat = totCost * tableItem.vat_percentage;
            const totNbtQuote = totQuote * tableItem.nbt_percentage;
            const totVatQuote = totQuote * tableItem.vat_percentage;


            if (!currentCustomizedRowData[index]) {
              currentCustomizedRowData[index] = [];
            }

            return {
              "CustName": currentCustomer.customer_name,
              "RateName": currentRateName.rate_description,
              "Currency": currentCurrencyName.currency_description,
              "TotalCost": totCost,
              "TotalQuote": totQuote,
              "Difference": totQuote - totCost,
              "SupplierName": currentSupplier.supplier_name,
              "TotalNBT": totnbt,
              "TotalVAT": totVat,
              "TotalNBTQuote": totNbtQuote,
              "TotalVATQoute": totVatQuote,
              ...tableItem,
            };
          });

          setpageValuesData({ ...response.data, ["booking_wise_rates_table_data"]: currentTableDatarow });
        });
    }
  };


  const handleBookingNunber = (bookingNUM) => {
    setBookingNo(bookingNUM);
    handleSelectedBooking(bookingNUM);
  };

  const bookingOnChange = (values) => {
    setSelectedBookingItem(values);
  };

  const printBooking = (e) => {
    if (bookingNo !== "") {
      axios
        .get(`${BASE_URL}booking_costing/print/${bookingNo}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((res) => {
          // Create a blob URL from the response data
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          // Open the URL in a new tab
          window.open(url);

          // Release the object URL when done to free up resources
          window.URL.revokeObjectURL(url);

          // toast.success("downloading file", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        })
        .catch((error) => {
          console.error("Error fetching PDF:", error);
        });
    } else {
      toast.error("Please select Booking No", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // ==== Total Calculation ==== // 
  const calculateTotalCost = () => {
    if (pageValuesData?.booking_wise_rates_table_data !== undefined) {
      return pageValuesData?.booking_wise_rates_table_data.reduce(
        (total, tabledata) => total + tabledata.TotalCost * 1,
        0
      );
    } else {
      return 0;
    }
  };

  const calculateTotalVAT = () => {
    if (pageValuesData?.booking_wise_rates_table_data !== undefined) {
      return pageValuesData?.booking_wise_rates_table_data.reduce(
        (totalVat, tabledata) => totalVat + tabledata.TotalVAT * 1,
        0
      );
    } else {
      return 0;
    }
  };

  const calculateTotalNBT = () => {
    if (pageValuesData?.booking_wise_rates_table_data !== undefined) {
      return pageValuesData?.booking_wise_rates_table_data.reduce(
        (total, tabledata) => total + tabledata.TotalNBT * 1,
        0
      );
    } else {
      return 0;
    }
  };

  const calculateTotalQuote = () => {
    if (pageValuesData?.booking_wise_rates_table_data !== undefined) {
      return pageValuesData?.booking_wise_rates_table_data.reduce(
        (totalQuote, tabledata) => totalQuote + tabledata.TotalQuote + tabledata.TotalVATQoute,
        0
      );
    } else {
      return 0;
    }
  };

  const calculateTotalVATQuote = () => {
    if (pageValuesData?.booking_wise_rates_table_data !== undefined) {
      return pageValuesData?.booking_wise_rates_table_data.reduce(
        (totalVatQuote, tabledata) => totalVatQuote + tabledata.TotalVATQoute,
        0
      );
    } else {
      return 0;
    }
  };

  // ==== Total Calculation End ==== // 

  const appendCalculationsValues = () => {
    const totalCost = calculateTotalCost();
    const totalVAT = calculateTotalVAT();
    const totalNBT = calculateTotalNBT();
    const totalQuote = calculateTotalQuote();
    const totalVatQuote = calculateTotalVATQuote();

    const totalCostWithTax = (totalCost + totalVAT).toFixed(2);
    const totalVATValue = totalVAT.toFixed(2);
    const totalNBTValue = totalNBT.toFixed(2);
    const totalQuoteValue = totalQuote.toFixed(2);
    const totalVatQuoteValue = totalVatQuote.toFixed(2);
    const totalNBTQuoteValue = (totalNBT * totalQuote).toFixed(2);
    const differenceValue = totalQuote - (totalCost + totalVAT);


    setpageValuesData((prevData) => ({
      ...prevData,
      total_cost: totalCostWithTax,
      total_vat: totalVATValue,
      total_nbt: totalNBTValue,
      total_quote: totalQuoteValue,
      total_vat_quote: totalVatQuoteValue,
      total_nbt_quote: totalNBTQuoteValue,
      difference: differenceValue,

    }));

  }



  const saveBookingInfo = (res) => {
    setValidateFailedInputsList([]);

    const newvalidateFailedInputsList = [];
    for (const key in cantBeEmptyFields) {
      const value = cantBeEmptyFields[key];

      // Check if the key corresponds to booking_wise_containers_table_data
      if (value === 'booking_wise_containers_table_data') {
        // Check if the array exists and has a length greater than 0
        if (
          pageValuesData &&
          pageValuesData[value] &&
          pageValuesData[value].length > 0
        ) {
          // Check if any object has an empty string or '0' as volume
          const invalidData = pageValuesData[value].every(
            item => item.volume === '' || item.volume === '0'
          );

          if (invalidData) {
            // Handle the case where data is invalid
            newvalidateFailedInputsList.push(value);
            const labelText = "Container Size & Vol";
            validateEmptyField(labelText);
          }
          continue; // Skip the logic for this specific key since the array is not empty
        }
      } else if (value === 'booking_wise_container_numbers_table_data') {
        if (pageValuesData && pageValuesData[value]) {
          const invalidData = Object.values(pageValuesData[value]).every(item => item === '');
          if (invalidData) {
            // Handle the case where data is invalid
            newvalidateFailedInputsList.push(value);
            const labelText = "Container Numbers";
            validateEmptyField(labelText);
          }
          continue; // Skip the logic for this specific key since the array is not empty
        }
      } else if (value === 'booking_wise_rates_table_data') {
        if (pageValuesData && !pageValuesData[value]) {
          // Handle the case where data is invalid
          newvalidateFailedInputsList.push(value);
          const labelText = "Booking Table Data";
          validateEmptyField(labelText);
        }
      }

      // For other keys or if the array is empty
      if (
        (pageValuesData && !(value in pageValuesData)) ||
        (!pageValuesData || !pageValuesData[value] || pageValuesData[value].length === 0)
      ) {
        newvalidateFailedInputsList.push(value);
        const keyWord = value.split(/_|id/).filter(Boolean);
        const capitalizeKeyWords = keyWord.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const labelText = capitalizeKeyWords.join(' ');
        validateEmptyField(labelText);
      }
    }
    setTimeout(() => {
      saveData();
    }, 1000);
  };

  const validateEmptyField = (labelText) => {
    toast.error(`${labelText} can not be empty`);
  }

  const saveData = () => {
    const formData = new FormData();

    if (selectedFiles && selectedFiles.length > 0) {

      // Append files from selectedFiles array to the FormData object
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("images[]", selectedFiles[i]);
      }
      // Append pageValuesData to the FormData object
      formData.append("pageValuesData", JSON.stringify(pageValuesData));
    } else {
      formData.append("pageValuesData", JSON.stringify(pageValuesData));

    }

    if (validateFailedInputsList.length > 0) {

    } else {
      bookingNo !== "" ?

        axios.post(
          `${BASE_URL}booking_costing/update/${bookingNo}`, pageValuesData,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authtoken}`,
            }
          }
        )
          .then((response) => {
            console.log(response);
            if (response) {
              toast.success("Successfully Updated Data ! ", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            window.location.reload(); // Reload the page after Update
          })
          .catch((error) => {
            console.error(error);
            alert(error);
            console.log(pageValuesData);
          })

        :

        axios
          .post(
            `${BASE_URL}booking_costing/store`,
            formData,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authtoken}`,
              }
            }
          )
          .then((response) => {
            if (response.status === 200) {
              toast.success("Successfully Save Data ! ", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            window.location.reload(); // Reload the page after Save
          })
          .catch((error) => {
            console.error(error);
            alert(error);
            console.log(pageValuesData);
          });

    }
  }

  const handleChange = (e) => {
    setSelectedFiles(e.target.files);
  };



  // const handleFileChange = async (event) => {
  // event.preventDefault();
  // const imagedata = new FormData();

  // const files = event.target.files;

  // const imagesArray = [];
  // let isValid = "";

  // for (let i = 0; i < event.target.files.length; i++) {
  //   isValid = fileValidate(event.target.files[i]);
  //   imagesArray.push(event.target.files[i]);
  // }
  // setImage(imagesArray);

  // const data = new FormData();
  // for (let i = 0; i < image.length; i++) {
  //   data.append("images[]", image[i]);
  // }

  // for (let i = 0; i < event.target.files.length; i++) {
  //   imagedata.append("images[]", image[i]);
  // }

  // setJsonData(file)
  // setSelectedFiles(convertedArray); // Setting all selected files
  // setpageValuesData({ ...pageValuesData, ["images"]: file });
  // Filter only PDF files
  // const pdfFiles = convertedArray.filter(
  //   (file) => file.type === "application/pdf"
  // );

  // if (pdfFiles.length === convertedArray.length) {
  //   await setSelectedFiles(pdfFiles); // Setting only PDF files
  // } else {
  //   toast.error("Please select only PDF files.", {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  // }
  // };

  const fileValidate = (file) => {
    if (file) { // Check if file exists
      setResponseMsg({
        error: "",
      });
      return true;
    } else {
      setResponseMsg({
        error: "No file selected",
      });
      return false;
    }
  };


  const newBooking = () => {
    setNewBookingMode(true);

  };


  return (
    <div className="">
      <ToastContainer autoClose={2000} />
      <Col>
        <div className="p-1 w-100">
          <BookingInfo className="mb-2"
            pageData={pageData}
            bookingOnChange={bookingOnChange}
            handleDropdownValues={handleDropdownValues}
            pageValuesData={pageValuesData}
            hadleInputValues={hadleInputValues}
            hadleCheckboxValues={hadleCheckboxValues}
            newBookingMode={newBookingMode}
            onhandleBookingNunber={handleBookingNunber}
            validateFailedInputsList={validateFailedInputsList}
          />
          .
          <ContainerInfo
            pageData={pageData}
            pageValuesData={pageValuesData}
            containerValueHadle={containerValueHadle}
            ConSizeVal={conSizeVal}
            handleConSizeVal={handleConSizeVal}
            valueToConNUMList={con_NumList}
            handleChangeConNUMList={handleCon_NumList}
            validateFailedInputsList={validateFailedInputsList}
          />
          .
          <CustomerandSupplierinfo
            pageData={pageData}
            tabledata={tabledata}
            pageValuesData={pageValuesData}
            hadleInputValues={hadleInputValues}
            handleTableData={handleTableData}
            calculateTotalCost={calculateTotalCost}
            calculateTotalVAT={calculateTotalVAT}
            calculateTotalNBT={calculateTotalNBT}
            calculateTotalQuote={calculateTotalQuote}
            calculateTotalVATQuote={calculateTotalVATQuote}
            setpageValuesData={setpageValuesData}
            validateFailedInputsList={validateFailedInputsList}
          />

          <div className="text-center mt-1">
            <Col className="text-end">
              {/* <ReusableButtonComponent
                selectedAuthority={props.selectedAuthority}
                action="data"
                label="data check"
                onClick={checkdata}
              /> */}
              <ReusableButtonComponent
                selectedAuthority={props.selectedAuthority}
                action="save"
                label="Save"
                onClick={saveBookingInfo}
              />

              <ReusableButtonComponent
                selectedAuthority={props.selectedAuthority}
                action="new"
                label="New"
                onClick={newBooking}
              />

              <ReusableButtonComponent
                selectedAuthority={props.selectedAuthority}
                action="update"
                label="Update"
                onClick={saveBookingInfo}
              />

              {/* <input
                className="ms-3 btn btn-primary mt-1"
                type="file"
                multiple
                onChange={handleFileChange}
              /> */}


              <input className="ms-3 btn btn-primary mt-1" type="file" name="images" multiple onChange={handleChange} />
              {/* <button onClick={handleUpload}>Upload</button> */}
              {/* {responseMsg && <div>{responseMsg}</div>} */}


              <ReusableButtonComponent
                selectedAuthority={props.selectedAuthority}
                action="print"
                label="Print"
                onClick={printBooking}
              />


              {/* <Button
                    className="ms-3"
                    variant="primary"
                    onClick={handleUpload}
                  >
                    Attach Docs
                  </Button> */}



              {/* <Button className="ms-3" variant="primary" type="submit">
                Cancel
              </Button> */}
            </Col>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default BookingCosting;
