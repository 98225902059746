import React from "react";
import Select from 'react-select';
const SelectDropdown = ({ options, value, onChange, disabled, name, clName, isMulti }) => {
    return (
        <Select
            className={clName ? clName : ""}
            options={options}
            onChange={onChange}
            value={value}
            isDisabled={disabled}
            isMulti={isMulti}
            name={name}
            menuPortalTarget={document.body} // Render the menu outside of the normal parent hierarchy
            menuPosition="fixed" // Ensure the menu stays in a fixed position
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    )
}

export default SelectDropdown;