import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectDropdown from "../../SelectDropdown";
import MultiInputs from "../../CommonComponents/MultiInputs";

// Function to convert Date object to YYYY-MM-DD format
const formatDateForInput = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Function to convert YYYY-MM-DD string to MM DD YYYY format
const formatDateForDisplay = (dateStr) => {
  const [year, month, day] = dateStr.split("-");
  return `${month} ${day} ${year}`;
};

const ExportBLFirstDT = (props) => {
  const storedToken = Cookies.get("authToken");
  const storedUser = Cookies.get("authUser");
  const [authtoken, setAuthToken] = useState(storedToken || "");
  const [authUser, setAuthUser] = useState(storedUser || "");
  const [pageComponent, setPageComponent] = useState("export_billing");
  const [blNumList, setBlNumList] = useState(undefined);
  const [selectedBLNo, SetSelectedBLNo] = useState("");
  const [currentDate, setCurrentDate] = useState(formatDateForInput(new Date()));
  const [selectedBLData, setSelectedBLData] = useState(undefined);

  const handleDateChange = (event) => {
    const newDate = event.target.value; // YYYY-MM-DD
    setCurrentDate(newDate); // Save date in YYYY-MM-DD format
    props.onpageValueHadleFunction(event);
    console.log("Date changed:", newDate); // Debugging
  };

  useEffect(() => {
    fetch(`${BASE_URL}${pageComponent}/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBlNumList(data.export_billing);
      })
      .catch((error) => {
        alert(error);
      });
  }, [authtoken, pageComponent]);

  const bLNoOptions = blNumList ?
    blNumList.map((bLOptionItem) => ({
      value: bLOptionItem.billing_number_id,
      label: bLOptionItem.master_billing_number,
    })) : [];

  // Fetching BL Data when selected
  const valueToMsterBLNo = (event) => {
    console.log("Selected BL No:", event.value);
    props.dropdownValueHandle("master_billing_number_id", event.value);
    SetSelectedBLNo(event.value);
    fetch(`${BASE_URL}${pageComponent}/search/${event.value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedBLData(data.export_billing);
        props.selectBLDataSet(data.export_billing);
        console.log("Selected BL Data:", data.export_billing);
        if (data.export_billing.is_export_billing_record === 1) {
          props.setNewManifestMode(false);
        } else {
          props.setNewManifestMode(true);
        }
        // Update currentDate with the date from the fetched data
        if (data.export_billing.billing_date) {
          // Ensure proper date format
          setCurrentDate(formatDateForInput(new Date(data.export_billing.billing_date)));
        }
      })
      .catch((error) => {
        console.error("Error fetching BL data:", error);
      });
  };
  return (
    <Container fluid style={{ boxShadow: 'none' }}>
      <ToastContainer autoClose={2000} />
      <Row>
        <Col>
          <Form >
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Master BL No</Form.Label>
                  <SelectDropdown
                    options={bLNoOptions}
                    onChange={valueToMsterBLNo}
                    name={"master_billing_number_id"}
                    value={selectedBLNo !== "" ? bLNoOptions.find(option => option.value === selectedBLNo) : null}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>House BL No</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="House BL NO"
                    autoComplete="off"
                    name="house_billing_number"
                    onChange={props.onpageValueHadleFunction}
                    value={props.pageValuesData.house_billing_number || ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="billing_date"
                    value={currentDate}
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label className="">Vessel</Form.Label>
                  <br />
                  <Form.Label className="printted-label">
                    {props.pageValuesData.vessel_code !== ""
                      ? props.pageValuesData.vessel_code
                      : ""}
                  </Form.Label>
                  <Form.Label className="printted-label ms-3">
                    {props.pageValuesData.vessel_description !== ""
                      ? props.pageValuesData.vessel_description
                      : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label className="">Voyage</Form.Label>
                  <br />
                  <Form.Label className="printted-label">
                    {props.pageValuesData.voyage_code !== ""
                      ? props.pageValuesData.voyage_code
                      : ""}
                  </Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.voyage_description !== ""
                      ? props.pageValuesData.voyage_description
                      : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label className="">Port of Loading</Form.Label>
                  <br />
                  <Form.Label className="printted-label">
                    {props.pageValuesData.loading_port_code !== ""
                      ? props.pageValuesData.loading_port_code
                      : ""}{" "}
                  </Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.loading_port_description !== ""
                      ? props.pageValuesData.loading_port_description
                      : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label className="">Port of Discharge</Form.Label>
                  <br />
                  <Form.Label className="printted-label">
                    {props.pageValuesData.delivery_port_code !== ""
                      ? props.pageValuesData.delivery_port_code
                      : ""}{" "}
                  </Form.Label>
                  <Form.Label className="ms-3 printted-label">
                    {props.pageValuesData.delivery_port_description !== ""
                      ? props.pageValuesData.delivery_port_description
                      : ""}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Place of Delivery </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={props.validateFailedInputsList.includes("place_of_delivery") ? "border-red2" : ""}
                    name="place_of_delivery"
                    value={
                      props.pageValuesData.place_of_delivery
                        ? props.pageValuesData.place_of_delivery
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Place of Receipt</Form.Label>
                  <Form.Control
                    type="text"
                    name="place_of_receipt"
                    className={props.validateFailedInputsList.includes("place_of_receipt") ? "border-red2" : ""}
                    autoComplete="off"
                    value={
                      props.pageValuesData.place_of_receipt
                        ? props.pageValuesData.place_of_receipt
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    placeholder=""
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Pre-Carriage by</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={props.validateFailedInputsList.includes("pre_carrage_by") ? "border-red2" : ""}
                    name="pre_carrage_by"
                    value={
                      props.pageValuesData.pre_carrage_by
                        ? props.pageValuesData.pre_carrage_by
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-1" controlId="MasterBL">
                  <Form.Label>Final Destination</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete=""
                    name="final_destination"
                    className={props.validateFailedInputsList.includes("final_destination") ? "border-red2" : ""}
                    value={
                      props.pageValuesData.final_destination
                        ? props.pageValuesData.final_destination
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Gross Weight</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="gross_weight"
                    className={props.validateFailedInputsList.includes("gross_weight") ? "border-red2" : ""}
                    value={
                      props.pageValuesData.gross_weight
                        ? props.pageValuesData.gross_weight
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>No of Containers or Packages</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    className={props.validateFailedInputsList.includes("number_of_containers_or_packages") ? "border-red2" : ""}
                    name="number_of_containers_or_packages"
                    value={
                      props.pageValuesData.number_of_containers_or_packages
                        ? props.pageValuesData.number_of_containers_or_packages
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    autoComplete="off"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Measurement</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete=""
                    name="measurement"
                    className={props.validateFailedInputsList.includes("measurement") ? "border-red2" : ""}
                    value={
                      props.pageValuesData.measurement
                        ? props.pageValuesData.measurement
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                    placeholder=""
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>
                    Total No of Containers/Packages or Units received in the
                    Carrier(In Words)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    autoComplete="off"
                    name="total_no_of_container_in_words"
                    className={props.validateFailedInputsList.includes("total_no_of_container_in_words") ? "border-red2" : ""}
                    value={
                      props.pageValuesData.total_no_of_container_in_words
                        ? props.pageValuesData.total_no_of_container_in_words
                        : ""
                    }
                    onChange={props.onpageValueHadleFunction}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1" controlId="MasterBL">
                  <Form.Label>Shipper</Form.Label>
                  <MultiInputs nuOfInputs={6} dataObject={props.pageValuesData} inputBaseName="shipper_" onChangeFunction={props.onpageValueHadleFunction} clName={props.validateFailedInputsList.includes("shipper_1") ? "border-red2" : ""} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Consignee</Form.Label>
                  <MultiInputs nuOfInputs={6} dataObject={props.pageValuesData} inputBaseName="consignee_" onChangeFunction={props.onpageValueHadleFunction} clName={props.validateFailedInputsList.includes("consignee_1") ? "border-red2" : ""} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label>Notify Party</Form.Label>
                  <MultiInputs nuOfInputs={6} dataObject={props.pageValuesData} inputBaseName="notify_party_" onChangeFunction={props.onpageValueHadleFunction} clName={props.validateFailedInputsList.includes("notify_party_1") ? "border-red2" : ""} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" >
                  <Form.Label> For Delivery of Goods</Form.Label>
                  <MultiInputs nuOfInputs={6} dataObject={props.pageValuesData} inputBaseName="deliver_goods_" onChangeFunction={props.onpageValueHadleFunction} clName={props.validateFailedInputsList.includes("deliver_goods_1") ? "border-red2" : ""} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ExportBLFirstDT;
